import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeCheckDuplication } from '../../api/fakeAuthAndDuplication';

export const fetchCheckDuplication = createAsyncThunk(
  'creator/fetchCheckDuplication',
  async (data: { field: string, value: string }) => {
    try {
      const response = await fakeCheckDuplication.check(data);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
