import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../shared/styles';
import { GalleryCard } from '../../../shared/components/GalleryCard/GalleryCard';
import { Gallery } from '../../../shared/types';
import * as urls from '../../../../constants';

type DraftGalleriesCardsType = {
  galleries: Gallery[];
}

export const DraftGalleriesCards: React.FC<DraftGalleriesCardsType> = ({ galleries }) => {
  const { t } = useTranslation('draftGalleries');
  const history = useHistory();

  const handleClick = (id: string) => () => {
    history.push(`${urls.newGalleryUrl}/${id}`);
  };

  return (
    <>
      {galleries
        ? (
          galleries.map((item) => (
            <Grid item sm={6}>
              <GalleryCard
                title={item.galleryName}
                coverImageUrl={item.coverFile}
                buttonText={t('continueEditing')}
                handleClick={handleClick(item.id)}
                key={item.id}
              />
            </Grid>
          ))
        )
        : <SimpleBlackText>{t('noDraftGalleries')}</SimpleBlackText>}
    </>
  );
};
