import styled from '@emotion/styled';
import { DialogActions, DialogContentText, DialogTitle } from '@material-ui/core';
import * as variables from '../../../../constants/styleVariables';
import { RowWrapper } from '../../styles';

export const getAlertStyles = (confirmation: boolean) => ({
  border: `0.5px solid ${confirmation ? '#eoeoeo' : '#F78F2A'}`,
  background: `${variables.colorGreyLight}`,
  padding: '26px 16px',
  minWidth: 350,
});

export const CustomTitleDialog = styled(DialogTitle)<{confirmation: boolean}>`
  & h2 {
    font-weight: normal;
    font-family: ${variables.mainFont} !important;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    color: ${(props) => (props.confirmation ? variables.textBlack : variables.colorOrange)};
  }
`;

export const CustomDialogContentText = styled(DialogContentText)`
  font-weight: normal;
  font-family: ${variables.mainFont} !important;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textBlack};
`;
export const CustomDialogActions = styled(DialogActions)`
  justify-content: flex-start;
`;
export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;

  & svg {
    color: ${variables.textBlack};
  }
`;
export const ButtonsWrapper = styled(RowWrapper)`
  width: 85%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
