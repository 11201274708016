import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { getFullName } from '../../../utils';
import { GalleryCoverFirst } from '../../GalleryView/GalleryFirst/GalleryCoverFirst';
import { GalleryCoverFourth } from '../../GalleryView/GalleryFourth/GalleryCoverFourth';
import { GalleryCoverSecond } from '../../GalleryView/GallerySecond/GalleryCoverSecond';
import { GalleryCoverThird } from '../../GalleryView/GalleryThird/GalleryCoverThird';
import { GalleryCoverType, GalleryData } from '../types';
import { useTypedSelector } from './useTypedSelector';
import { GalleryCoverSixth } from '../../GalleryView/GallerySixth/GalleryCoverSixth';
import { GalleryCoverFifth } from '../../GalleryView/GalleryFifth/GalleryCoverFifth';

export const useGetGalleryCover = (): ReactElement => {
  const user = useTypedSelector((state) => state.signInCreator.currentUser);
  const { values } = useFormikContext<GalleryData>();
  const props: GalleryCoverType = {
    name: values.galleryName,
    date: values.date,
    file: values.coverFile.desktop.file || values.coverFile.original.file,
    userName: getFullName(user?.firstName, user?.lastName),
    message: values.message,
  };

  switch (values.galleryDesign) {
    case 1:
      return (
        <GalleryCoverFirst {...props} />
      );
    case 4:
      return (
        <GalleryCoverFourth {...props} />
      );
    case 5:
      return (
        <GalleryCoverFifth {...props} />
      );
    case 6:
      return (
        <GalleryCoverSixth {...props} />
      );
    default:
      return (
        <GalleryCoverFirst {...props} />
      );
  }
};
