import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styleVariables';

export const PaymentForm = styled.form`
  width: 50%;
  background: white;
  border-radius: 10px;
  padding: 0px 10px 20px;
`;

export const PaymentRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    width: 50% !important;
  }
`;

export const BasePaymentDiv = styled.div`
  & > div,
  & > input {
    border: 1px solid ${variables.colorGreyLight};
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    &::placeholder {
      color: ${variables.colorBleak};
    }
  }
`;

export const PaymentLabelText = styled.label`
  color: ${variables.textBlack};
  font-size: 14px;
`;

export const PayButton = styled.button<{black?: boolean}>`
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => (props.black ? 'black' : 'blue')};
  text-align: center;
  color: white;
  font-size: 16px;
  padding: 15px;
`;

export const PaymentLineWithText = styled.div`
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid ${variables.colorBleak}; 
  line-height: 0.1em;
  margin: 40px 0 30px; 
  color: ${variables.colorBleak};
  font-size: 14px;

  & span { 
    background: #fff; 
    padding: 0 10px;
  }
`;
