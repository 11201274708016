import React, { ChangeEvent, ReactNode } from 'react';
import {
  CommonInputWrapper,
  ErrorMessage,
  InputBase,
  InputWrapper,
  TextLabel,
} from './styled';

type CommonInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  type: 'password' | 'email' | 'date' | 'text' | 'file';
  placeholder?: string;
  touched?: boolean | any;
  width?: number;
  label?: string | ReactNode;
  readonly?: boolean;
  direction?: 'row' | 'column';
  disabled?: boolean;
  id?: string;
  withoutMargins?: boolean;
};

export const CommonInput: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  type,
  placeholder,
  handleBlur,
  touched,
  width,
  label,
  readonly,
  direction,
  disabled,
  id,
  withoutMargins,
}) => (
  <CommonInputWrapper width={width} withoutMargins={withoutMargins} id={id || name}>
    <InputBase
      color={`${error && touched ? 'error' : 'success'}`}
      width={`${width ? `${width}px` : '100%'}`}
      direction={direction || 'column'}
    >
      {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
      <InputWrapper>
        <input
          readOnly={readonly}
          id={name}
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
      </InputWrapper>
    </InputBase>
  </CommonInputWrapper>
);
