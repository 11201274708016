import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MediumBlackText,
  SimpleBlackText,
} from '../../../../shared/styles';
import { SettingsGalleryCard } from './SettingsGalleryCard';
import gallery7 from '../../../../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../../../../assets/image/walkThroughCards/gallery8.jpg';
import gallery9 from '../../../../../assets/image/walkThroughCards/gallery9.jpg';
import { RowCards } from './styled';

const fakeData = [
  {
    file: { url: gallery7, name: 'gallery7', type: 'image' },
    storage: 50,
    name: 'Gallery name 1',
  },
  {
    file: { url: gallery8, name: 'gallery8', type: 'image' },
    storage: 92,
    name: 'Gallery name 2',
  },
  {
    file: { url: gallery9, name: 'gallery9', type: 'image' },
    storage: 75.5,
    name: 'Gallery name 3',
  },
];

export const Storage: React.FC = () => {
  const { t } = useTranslation('settings');
  const totalStorage = 2;
  const usedStorage = 217;

  return (
    <>
      <SimpleBlackText>{t('storage')}</SimpleBlackText>
      <Grid
        container
        item
        sm={12}
        style={{ margin: '30px 0px', height: 'fit-content', minHeight: '0' }}
      >
        <Grid
          container
          item
          sm={5}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('availablePlan')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageGB">
              {{ storageGB: totalStorage }}
              GB
            </Trans>
          </MediumBlackText>
        </Grid>
        <Grid
          container
          item
          sm={5}
          direction="row"
          style={{ height: 'fit-content' }}
        >
          <MediumBlackText>{t('storageUsed')}</MediumBlackText>
          <MediumBlackText fontWeight={500} style={{ marginLeft: '10px' }}>
            <Trans t={t} i18nKey="storageMB">
              {{ storageMB: usedStorage }}
              MB
            </Trans>
          </MediumBlackText>
        </Grid>
      </Grid>
      <RowCards>
        {fakeData.map((item) => (
          <SettingsGalleryCard
            key={item.file.name}
            file={item.file}
            name={item.name}
            storage={item.storage}
          />
        ))}
      </RowCards>
    </>
  );
};
