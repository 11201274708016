import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { ProgressBar } from './ProgressBar';
import { getNewGallerySchema } from '../../../shared/validation/gallery/newGallerySchema';
import { GalleryDataFormType } from '../../../shared/types';
import { StepSwitcher } from './StepSwitcher/StepSwitcher';
import { galleryInitialValues } from '../../../../constants/initialValues';
import { ChangeStageButtons } from './ChangeStageButtons';
import { OnboardingContext } from '../../../../context/OnboardingContext';
import { fakeGallery } from '../../../../constants/walkThroughCards/fakeGalleryData';

export const NewGalleryCreator: React.FC = () => {
  const { isStudy } = useContext(OnboardingContext);
  const [step, setStep] = useState(1);
  const { id: galleryId } = useParams<{id: string}>();

  // Check data on every step and submit they just on the 5th step
  const onSubmit = (data: GalleryDataFormType, bag: any) => {
    if (data.step > 5) {
      setStep(data.step);
      console.log('submit', data);
    } else {
      bag.setSubmitting(false);
      bag.setTouched({});
      setStep(data.step);
    }
  };
  return (
    // useFormik was replaced with useFormikContext
    // useFormikContext allows getting Formik stuff from hook, not from props
    <>
      <Formik<GalleryDataFormType>
        initialValues={isStudy ? fakeGallery : galleryInitialValues(galleryId)}
        onSubmit={(data: GalleryDataFormType, bag: any) => onSubmit(data, bag)}
        validationSchema={isStudy ? null : getNewGallerySchema(step)}
      >
        <Form>
          <ProgressBar step={step} />
          <StepSwitcher step={step} />
          <ChangeStageButtons step={step} />
        </Form>
      </Formik>
    </>
  );
};
