import { Grid } from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { GalleryFileInformation, GalleryIdea, GallerySection } from '../../../../shared/types';
import { CommonSwitch } from '../../../../shared/components/CommonSwitch/CommonSwitch';
import { isArrayIncludeValue } from '../../../../../utils/commonFunctions';
import { CommonPriceInput } from '../../../../shared/components/CommonPriceInput/CommonPriceInput';

type FilesFormType = {
  selected: string[];
  values: GalleryFileInformation;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  errors: FormikErrors<GalleryFileInformation>;
  touched: FormikTouched<GalleryFileInformation>;
  section: GallerySection;
};

export const FilesForm: React.FC<FilesFormType> = ({
  selected,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  section,
}) => {
  const { t } = useTranslation('createNewGallery');

  const selectedIdeas = useMemo(
    () => {
      const ideas = section.ideas as GalleryIdea[];
      return ideas.filter((item) => isArrayIncludeValue(selected, item.id));
    },
    [selected, section.ideas],
  );

  const isAllIdeas = selected.length === selectedIdeas.length;

  return (
    <Grid
      container
      item
      sm={4}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid container item sm={10} id="filesSettingsThirdStep">
        <Grid item sm={12}>
          <CommonInput
            error={isAllIdeas ? '' : errors.description}
            value={values.description}
            name="description"
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            touched={touched.description}
            placeholder={t('thirdStep.description')}
            disabled={selected?.length === 0 || isAllIdeas}
          />
        </Grid>
        <Grid item sm={10}>
          <CommonPriceInput
            label={t('thirdStep.price')}
            value={values.price}
            name="price"
            handleChange={handleChange}
            handleBlur={handleBlur}
            direction="row"
            error={isAllIdeas ? '' : errors.price}
            touched={touched.price}
            disabled={selected?.length === 0 || isAllIdeas}
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.instantlyDownloadable}
            handleChange={handleChange}
            name="instantlyDownloadable"
            label={t('thirdStep.downloadable')}
            disabled={selected?.length === 0}
            shortLabel
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.watermark}
            handleChange={handleChange}
            name="watermark"
            label={t('thirdStep.watermark')}
            helpText={t('thirdStep.helpText')}
            positionHelpText="top"
            disabled={selected?.length === 0}
          />
        </Grid>
        <Grid item sm={12}>
          <CommonSwitch
            isChecked={!!values.allowCommenting}
            handleChange={handleChange}
            name="allowCommenting"
            label={t('thirdStep.allowCommenting')}
            disabled={selected?.length === 0}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
