import * as Yup from 'yup';
import { password } from '../../../constants/regExp';
import { errorMessages } from '../../../utils/errorsMessages';
import { passwordMinLength } from '../../../constants';
import { countryValue, doValue } from '../../../constants/initialValues/signUp';

export const ResetSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
});

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
  password: Yup.string()
    .required(errorMessages.requiredField)
    .min(passwordMinLength, errorMessages.passwordLength)
    .matches(password, errorMessages.passwordCharacters),
});

export const SignUpEmail = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .required(errorMessages.requiredField),
});

export const SignUpSchema = (
  step: number,
) => {
  if (step === 1) {
    return Yup.object().shape({
      firstName: Yup.string().required(errorMessages.requiredField),
      lastName: Yup.string().required(errorMessages.requiredField),
      businessName: Yup.string()
        .required(errorMessages.requiredField),
      password: Yup.string()
        .required(errorMessages.requiredField)
        .min(passwordMinLength, errorMessages.passwordLength)
        .matches(password, errorMessages.passwordCharacters),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        errorMessages.passwordMustMatch,
      ).required(errorMessages.requiredField),
    });
  }
  if (step === 2) {
    return Yup.object().shape({
      dob: Yup.mixed().nullable(false).required(errorMessages.requiredField),
      country: Yup.string()
        .notOneOf([countryValue], errorMessages.requiredField)
        .required(errorMessages.requiredField),
      occupation: Yup.string()
        .notOneOf([doValue], errorMessages.requiredField)
        .required(errorMessages.requiredField),
    });
  }
  return null;
};
