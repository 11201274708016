import styled from '@emotion/styled';

export const GradientCover = styled.div`
  position: absolute;
  width: 22%;
  height: 100%;
  right: 0;
  background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding-top: 55px;
  padding-bottom: 55px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-evenly;

  & svg {
    color: white;
  }
`;
