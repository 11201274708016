import { createAsyncThunk } from '@reduxjs/toolkit';
import { creator } from '../../../api/creator/creator';
import { creatorLogOut, creatorSignIn } from '../../../api/auth/creatorAuth';
import { SignIn } from '../../../containers/shared/types';

export const fetchSignInCreator = createAsyncThunk(
  'signInCreator/fetchSignInCreator',
  async (data: SignIn) => {
    const response = await creatorSignIn.signIn(data);
    return response.data;
  },
);

export const fetchLogOutCreator = createAsyncThunk(
  'signInCreator/fetchLogOutCreator',
  async () => {
    const response = await creatorLogOut.logout();
    return response.data;
  },
);

export const fetchCurrentUserCreator = createAsyncThunk(
  'creator/fetchCurrentUserCreator',
  async () => {
    const response = await creator.getCurrentCreator();
    return response.data;
  },
);
