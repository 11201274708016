import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeSupportRequests } from '../../api/support/fakeSupportRequests';

export const fetchGetSupport = createAsyncThunk(
  'support/fetchGetSupport',
  async () => {
    try {
      const response = await fakeSupportRequests.getSupport();
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
