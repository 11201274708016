import styled from '@emotion/styled';
import { Select, MenuItem } from '@material-ui/core';
import * as variables from '../../../../constants/styleVariables';

export const CustomSelect = styled(Select)`
  font-family: ${variables.mainFont} !important;
  margin: 0 0 10px 0;
  font-size: 15px;
  height: 44px;
  padding: 12px 0;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  border-radius: 163px;
  display: flex;
  align-items: center;
  border-bottom: none !important;
  cursor: pointer;
  color: ${({ value }) => (value === 'Country' ? variables.colorBleak : variables.textGrey)};
  width: 100%;

  & select {
    background: transparent !important;
    padding-left: 24px;

    & li {
      &:hover {
        background-color: black !important;
      }
    }
  }

  & input {
    border-bottom: none !important;
    width: 100%;
  }
  /* stylelint-disable indentation */
  ${({ error }) => (error
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
    border-bottom: 0.5px solid ${variables.colorError} !important;`
    : '')}
  /* stylelint-enable indentation */
  & div {
    background: transparent !important;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    line-height: 140%;
  }

  &::after,
  ::before {
    border-bottom: none !important;
  }

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
  }

  & svg {
    right: 4px !important;
    top: calc(50% - 15px); !important;
  }

  & > span {
    position: absolute;
    top: -18px;
    left: 24px;
    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }

  & option {
    border: transparent !important;
    background: transparent !important;
  }
`;
export const SelectWithErrorMessage = styled.div`
  position: relative;
  margin: 10px 0;

  & > span {
    position: absolute;
    bottom: -18px;
    left: 26px;
    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }
`;
export const CustomMenuItem = styled(MenuItem)`
  font-family: ${variables.mainFont} !important;
  color: ${variables.textGrey};
  font-size: 15px;
  line-height: 140%;
  border-radius: 8px;
  padding: 6px 10px;

  & div {
    box-shadow: -5px -5px 10px #fff, 5px 5px 10px #e8e8e8 !important;
    border-radius: 4px;
  }

  &:hover {
    background-color: ${variables.colorBlueLight} !important;
    color: ${variables.textBlack};
  }
`;

export const PlaceholderWrapper = styled(MenuItem)`
  font-family: ${variables.mainFont} !important;
  color: ${variables.colorBleak} !important;
  display: none;
`;

export const PlaceholderText = styled.span`
  font-family: ${variables.mainFont};
  color: ${variables.colorBleak};
`;
