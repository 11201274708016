import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styleVariables';

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & svg {
    margin-right: 8px;
  }
`;

export const DevicesAndBrowsersTable = styled.div`
  margin-top: 20px;
`;

export const DeleteButton = styled.button`
  height: fit-content;

  & svg {
    color: ${variables.textBlack};
  }
`;

export const CancelPanelWrapper = styled.div`
  & > div,
  & > span, 
  & > button {
    margin-top: 15px;
  }
`;

export const InvisibleButton = styled.button<{ isDisplay: boolean }>`
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${(props) => (props.isDisplay ? 'flex' : 'none')};
`;

export const DevicesAndBrowsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabSettingsWrapper = styled.div`
  & > div {
    min-height: 0px;
  }
`;
