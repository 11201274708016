import React from 'react';
import { GradientCover } from './styled';
import {
  CartIcon,
  HeartIcon,
  InstantDownload,
  ShareIcon,
  CommentIcon,
} from '../../icons/FileIcons';

type GradientCoverWithActionsType = {
  isInstantlyDownloadable: boolean;
  isAllowCommenting: boolean;
};

export const GradientCoverWithActions: React.FC<GradientCoverWithActionsType> = ({
  isInstantlyDownloadable,
  isAllowCommenting,
}) => (
  <GradientCover>
    {isInstantlyDownloadable && <InstantDownload />}
    <HeartIcon />
    <CartIcon />
    <ShareIcon />
    {isAllowCommenting && <CommentIcon />}
  </GradientCover>
);
