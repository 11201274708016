import React from 'react';
import { Grid } from '@material-ui/core';
import { GalleryCoverType } from '../../shared/types';
import {
  ImageGalleryFourthTablet,
  CreatorCellTablet,
  SquareBoxesTablet,
  DateCellTablet,
  ImageSecondCellTablet,
  ImageFirstCellTablet,
  TitleCellTablet,
  MessageCellTablet,
} from './styled';
import { useGalleryContent } from '../useGalleryContent';
import { TabletWrapper } from '../TabletWrapper';
import { useExtraFilesGalleryFourth } from '../../shared/hooks/gallery/galleryFourth/useExtraFilesGalleryFourth';

export const GalleryDemoFourthTablet: React.FC<GalleryCoverType> = ({
  file, name, date, userName, message, withCropper = false,
}) => {
  const { extraFile1, extraFile2 } = useExtraFilesGalleryFourth(withCropper, 'tablet');

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'tablet',
    galleryNumber: 'fourth',
  });

  return (
    <TabletWrapper>
      <Grid
        container
        item
        sm={12}
        justifyContent="space-between"
        direction="column"
        style={{ padding: '63px 13px' }}
      >
        <Grid item sm={12}>
          <SquareBoxesTablet>
            <TitleCellTablet>
              {data.name}
            </TitleCellTablet>
            <CreatorCellTablet>
              {data.userName}
            </CreatorCellTablet>
            <ImageFirstCellTablet>
              {extraFile1}
            </ImageFirstCellTablet>
            <MessageCellTablet>
              {data.message}
            </MessageCellTablet>
            <ImageSecondCellTablet>
              {extraFile2}
            </ImageSecondCellTablet>
            <DateCellTablet>
              {data.date}
            </DateCellTablet>
          </SquareBoxesTablet>
        </Grid>
        <Grid container item sm={12} alignItems="center">
          <ImageGalleryFourthTablet>
            {data.file}
          </ImageGalleryFourthTablet>
        </Grid>
      </Grid>
    </TabletWrapper>
  );
};
