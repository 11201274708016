import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../../../../constants/styleVariables';
import { getColorOfText } from '../../../../../utils/style/getColorOfText';

export const SectionWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 25px;
`;
export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  width: calc(100% + 30px);
`;
export const FileCardWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 16px;
  width: 195px;
  height: 171px;
  color: ${variables.textGrey};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #f0f1f2;

  & > svg {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 900;
    color: ${variables.colorGreyLight};
  }

  & button {
    visibility: hidden;
    position: absolute;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.006613991477272707) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6884321732954546) 100%);
    right: 0;
    width: 25%;
    height: 100%;

    & > svg {
      position: absolute;
      top: 8px;
      right: 10px;
      color: ${variables.colorGreyLight};
    }
  }

  &:hover {
    & button {
      z-index: 900;
      visibility: visible;
    }
  }

  & > div {
    margin: 0;
    border-radius: inherit;
  }
`;

export const ButtonCard = styled.div<{isDragProcess?: boolean}>`
  box-sizing: border-box;
  border-radius: 16px;
  width: 195px;
  color: ${variables.textGrey};
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  background: ${(props) => (props.isDragProcess ? '#EEF6FC' : variables.colorGreyLight)};
  padding: 24px 12px;
  height: 170px;

  & input {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  & img {
    width: auto;
    height: auto;
    margin-bottom: 14px;
  }

  & div {
    margin-top: 16px;
  }

  & button {
    position: absolute;
    background: transparent;
    right: 3px;
    top: 7px;
    visibility: visible;

    & > span {
      bottom: 130%;
    }
  }
`;
export const CardContainer = styled(Grid)`
  margin-bottom: 25px !important;
`;
export const CardBorder = styled.div`
  width: 195px;
  height: 169px;
  background: ${variables.colorBleak};
  position: relative;
  border-radius: 17px;
  margin: -1px;
  display: flex;
  padding: 1px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    transition: 0.5s;
    background: ${variables.colorGradientDiagonal};
  }
`;
export const PlusIcon = styled.div`
  position: relative;
  width: 16px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    ${({ theme }: { theme?: string }) => (theme === 'large'
    ? `width: 16px;
    height: 2px;`
    : `width: 13.68px;
    height: 1.58px;`)}

    background: ${variables.textGrey};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    ${({ theme }: { theme?: string }) => (theme === 'large'
    ? `height: 16px;
    width: 2px;`
    : `height: 13.68px;
    width: 1.58px;`)}

    background: ${variables.textGrey};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
  }
`;
export const ConstCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SectionTitle = styled.div<{error?: boolean}>`
  font-size: 18px;
  line-height: 27px;
  color: ${variables.textBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & input {
    background: ${variables.colorGreyLight};
    border: none;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    color: ${variables.textBlack};
    min-width: 50px;

    ${(props) => (props.error ? `border-bottom: 0.5px solid ${variables.colorError};` : '')}

    &:focus {
      cursor: text;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    & > span {
      top: 30%;
      left: 100%;
    }

    & svg {
      color: ${(props) => (props.error ? variables.colorError : variables.textBlack)};
      margin-left: 12px;
      margin-right: 12px;
      min-width: 24px;
      min-height: 24px;
    }
  }
`;
export const CloseButtonWrapper = styled.div`
  & span {
    display: none;
    box-sizing: border-box;
    background: ${variables.textGrey};
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${variables.colorGreyLight};
    border-radius: 2px;
  }

  & button {
    & svg {
      color: ${variables.textGrey};
    }
  }

  &:hover {
    & span {
      display: block;
    }
  }
`;

export const TextUploadedWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 20px;
  color: ${({ theme }: { theme: string}) => getColorOfText(theme)};
`;

export const SectionFooterWrapper = styled.div`
  padding: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
export const ViewMoreButton = styled.button`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};

  & span {
    margin-right: 10px;
  }
`;

export const ButtonAddWrapper = styled.button`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 27px;
  color: ${variables.textGrey};
  align-items: center;
  cursor: pointer;

  & div {
    margin-right: 14px;
  }
`;
export const FileCardText = styled.p`
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;
export const IdeaOpenButton = styled.div`
  position: absolute;
  height: 100%;
  width: 75%;
  left: 0;
  z-index: 1000;
`;

export const TextWithLines = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 150%;
  color: ${variables.colorBleak};
  margin-top: 15px;

  &::before,
  &::after {
    content: "";
    flex: 1 1;
    border-bottom: 0.5px solid ${variables.colorBleak};
    margin: auto;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

export const ErrorFilesWrapper = styled.div`
  position: relative; 
  margin-left: -24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-center: center;
`;
