import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';
import { FileInputBase } from '../../styles';

export const MiniFileCardWrapper = styled(FileInputBase)<{large?: boolean, hasBorders?: boolean}>`
  z-index: 1;
  box-sizing: border-box;
  background: #f0f1f2;
  height: ${(props) => (props.large ? '83px' : '71px')};
  width: ${(props) => (props.large ? '83px' : '71px')};
  min-height: 71px;
  min-width: 71px;
  ${(props) => (props.hasBorders && `border: 2px solid ${variables.colorPink};`)}
  border-radius: 4px;
  margin-bottom: ${(props) => (props.large ? '18px' : '30px')};
  overflow: hidden;
  margin-right: ${(props) => (props.large ? '27px' : '30px')};
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
  }

  & > div {
    overflow: visible;
  }
`;

export const MiniChooseFileCard = styled(FileInputBase)<{imageUrl?: string, hasFile?: boolean}>`
  ${(props) => (props.hasFile ? `background: url(${props.imageUrl}), #f0f1f2;; background-size: cover; ` : 'background: #f0f1f2;')}
  height: 70px;
  width: 70px;
  min-height: 70px;
  min-width: 70px;
  border-radius: 4px;
  margin-right: 30px;
  cursor: pointer;

  ${(props) => (props.hasFile ? `
    & > div {
      opacity: 0;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 3px;
      display: flex;
      width: 100%;
      height: 100%;
      transition: opacity 0.1s;
      cursor: pointer;
      background: #f0f1f282;
      &::before,
      &::after {
        z-index: 1;
        background: ${variables.textBlack};
      }
    }
    &:hover {    
      cursor: pointer;

      & > div {
        opacity: 1;
      }
      & input {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1000;
      }
    }
  `
    : `
    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -7px;
    }
  `)} 
`;
