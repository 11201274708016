import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';
import { GalleryCoverGrid, MobileVersionWrapper, TabletVersionWrapper } from '../styled';

export const GalleryCoverGridSecond = styled(GalleryCoverGrid)`
  margin-top: 65px !important;
`;

export const TabletVersionWrapperSecond = styled(TabletVersionWrapper)`
  & > div {
    padding: 61px 16px;
    width: 533px;
    height: 710px;
    border: 1px solid ${variables.colorBleak};
    border-radius: 20px;
  }
`;

export const MobileVersionWrapperSecond = styled(MobileVersionWrapper)`
  & > div {
    padding: 45px 22px;
  }
`;

export const TextGallerySecond = styled.div`
  font-size: 14px;
  line-height: 150%;
  text-align: start;
  max-width: 315px;

  & h3 {
    font-weight: normal;
    margin-top: 0;
    font-size: 28px;
    line-height: 42px;
  }
`;
export const ImageContainerSecond = styled.div`
  height: 581px;
  width: 871px;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;

  & span {
    width: 40px;
    position: absolute;
    font-size: 14px;
    line-height: 150%;
    transform: rotate(-90deg);
    left: -56px;
    bottom: 10px;
  }

  & > div {
    height: auto;
    width: 100%;
    overflow: hidden;

    & > img {
      height: auto;
      width: 100%;
    }
  }
`;

export const ImageContainerSecondTablet = styled(ImageContainerSecond)`
  width: 100%;
  height: 391px;
`;
export const TextGallerySecondTablet = styled(TextGallerySecond)`
  width: 100%;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  max-width: none;

  & p {
    margin: 10px 0 20px 0;
  }

  & h3 {
    font-size: 27.7604px;
    line-height: 42px;
  }
`;

export const ImageContainerSecondMobile = styled(ImageContainerSecond)`
  width: 100%;
  height: 167px;
  margin-right: 0;
`;
export const TextGallerySecondMobile = styled(TextGallerySecond)`
  width: 100%;
  font-size: 7px;
  line-height: 130%;
  text-align: center;

  & h3 {
    font-size: 14px;
    line-height: 140%;
  }
`;
