import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';

export const GridFitContent = styled(Grid)`
  height: fit-content !important;
  padding: 0 4px !important;

  & > div {
    margin: 10px 0 0 !important;
  }
`;
