import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';

export const WalkThroughStartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-round;
  align-items: center;
  padding: 60px 100px;
  border-radius: 18px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  max-width: 616px;

  & > div {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const WalkThroughButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
