import { combineReducers } from '@reduxjs/toolkit';
import checkDuplicationSlice from './checkDiplication/checkDuplicationSlice';
import gallerySlice from './gallery/gallerySlice';
import notificationsSlice from './notifications/notificationsSlice';
import statisticsSlice from './sales/statistics/statisticsSlice';
import supportSlice from './support/supportSlice';
import salesSlice from './sales/sales/salesSlice';
import signUpCreatorSlice from './userCreator/signUp/signUpCreatorSlice';
import signInCreatorSlice from './userCreator/signIn/signInCreatorSlice';
import resetPasswordCreatorSlice from './userCreator/resetPassword/resetPasswordCreatorSlice';

export const rootReducer = combineReducers({
  checkDuplication: checkDuplicationSlice,
  galleries: gallerySlice,
  notifications: notificationsSlice,
  statistics: statisticsSlice,
  support: supportSlice,
  sales: salesSlice,
  signUpCreator: signUpCreatorSlice,
  signInCreator: signInCreatorSlice,
  resetPassword: resetPasswordCreatorSlice,
});
