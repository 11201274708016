import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const SummaryTable = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;

export const HrLine = styled.hr`
  height: 0;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0.5px solid ${variables.colorBleak};
`;

export const SummaryButtonWrapper = styled.div`
  margin-top: 16px;
`;
