import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../../constants/urls';
import {
  AlignCenterNavLink,
  UserCardWrapper,
  UserInfoWrapper,
  UserName,
  UserPhoto,
} from './styled';
import noPhoto from '../../../assets/image/noPhoto.png';
import { NotificationIcon } from '../../shared/icons';
import { InsideRoundButtonWrapper, RoundButton } from '../../shared/styles';
import { useTypedSelector } from '../../shared/hooks/useTypedSelector';
import { fetchGetNotReadNotifications } from '../../../Redux/notifications/notificationsThunk';
import { getFullName, getStrIfNumberBigger99 } from '../../../utils';

export const UserCard: React.FC = () => {
  const dispatch = useDispatch();

  const numberNotReadNotifications = useTypedSelector(
    (state) => state.notifications.numberNotRead,
  );
  const currentUser = useTypedSelector((state) => state.signInCreator.currentUser);
  const numberNotReadNotifsWithPlus = getStrIfNumberBigger99(
    numberNotReadNotifications,
  );

  useEffect(() => {
    dispatch(fetchGetNotReadNotifications());
  }, []);

  return (
    <UserCardWrapper>
      <Link to={urls.accountUrl}>
        <UserPhoto>
          <img src={noPhoto} alt="user" />
        </UserPhoto>
      </Link>
      <UserInfoWrapper>
        <UserName>{getFullName(currentUser?.firstName, currentUser?.lastName)}</UserName>
        <RoundButton>
          <InsideRoundButtonWrapper>
            <AlignCenterNavLink to={urls.notificationsUrl}>
              <NotificationIcon />
            </AlignCenterNavLink>
            {numberNotReadNotifsWithPlus ? (
              <div>
                <span>{numberNotReadNotifsWithPlus}</span>
              </div>
            )
              : null}
          </InsideRoundButtonWrapper>
        </RoundButton>
      </UserInfoWrapper>
    </UserCardWrapper>
  );
};
