import { createAsyncThunk } from '@reduxjs/toolkit';
import { fakeActiveGallery } from '../../api/gallery/fakeGalleryRequests';
import { GalleryTypes } from '../../containers/shared/types';

export const fetchGetGalleries = createAsyncThunk(
  'gallery/fetchGetGalleries',
  async (typeGallery?: GalleryTypes) => {
    try {
      const response = await fakeActiveGallery.getGalleries(typeGallery);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);

export const fetchDeleteGallery = createAsyncThunk(
  'gallery/fetchDeleteGallery',
  async (id: string) => {
    try {
      const response = await fakeActiveGallery.deleteGallery(id);
      return response.data;
    } catch (err: any) {
      return err.response.data;
    }
  },
);
