import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styleVariables';

export const SettingsGalleryCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  min-height: fit-content;
`;

export const SettingsGalleryCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SettingsGalleryCardFile = styled.div`
  width: 195px;
  height: 131px;
  border-radius: 4px;
`;

export const RowCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ButtonMgT = styled.div`
  margin-top: 30px;
`;

export const TextAndIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

export const UpgradeToPremiumWrapper = styled.div`
  background: #F5F5F5;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-radius: 10px;
`;
