import React, {
  ChangeEvent, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon, TickIcon } from '../../icons';
import { LittleBlackText } from '../../styles';
import {
  CommonInputWrapper,
  TextCopied,
  TextLabel,
} from '../styled';
import {
  CommonCopyInputWrapper,
  CopyInput,
  CopyInputAndButtonWrapper,
  CustomRoundButton,
} from './styled';

type CommonInputProps = {
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | any;
  name: string;
  label?: string;
  readonly?: boolean;
  direction?: 'row' | 'column';
  disabled?: boolean;
};

export const CommonCopyInput: React.FC<CommonInputProps> = ({
  value,
  name,
  label,
  readonly,
  direction,
  disabled = true,
  handleChange,
}) => {
  const { t } = useTranslation('basicComponents');
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 5000);
  };

  const width = useMemo(() => ((value || '').length * 8), [value]);

  return (
    <CommonInputWrapper>
      <CommonCopyInputWrapper
        direction={direction || 'column'}
      >
        {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
        <CopyInputAndButtonWrapper>
          <CopyInput
            width={width}
            onChange={handleChange}
            readOnly={readonly}
            id={name}
            name={name}
            value={value}
            disabled={disabled}
          />
          <CustomRoundButton
            type="button"
            onClick={handleClick}
            disabled={!value}
          >
            <CopyIcon />
          </CustomRoundButton>
          <TextCopied isVisible={isCopied}>
            <TickIcon />
            <LittleBlackText>{t('copied')}</LittleBlackText>
          </TextCopied>
        </CopyInputAndButtonWrapper>
      </CommonCopyInputWrapper>
    </CommonInputWrapper>
  );
};
