import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ShowHideButton,
  DetailsItem,
} from './styled';
import {
  MediumBlackText,
  PinkLink,
} from '../../../../../shared/styles';
import { TickIcon } from '../../../../../shared/icons';

type PlanCardDetailsType = {
  details: string[];
};

export const PlanCardDetails: React.FC<PlanCardDetailsType> = ({
  details,
}) => {
  const { t } = useTranslation('settings');
  const [showDetails, setShowDetails] = useState(false);

  const handleShowHideDetails = () => {
    setShowDetails((prev) => !prev);
  };

  return (
    <>
      <div>
        <ShowHideButton
          style={{ padding: 0 }}
          type="button"
          onClick={handleShowHideDetails}
        >
          <PinkLink>
            {showDetails ? t('hideDetails') : t('showDetails')}
          </PinkLink>
        </ShowHideButton>
      </div>
      {showDetails && (
      <div>
        {details.map((item) => (
          <DetailsItem key={item}>
            <TickIcon isGradient={false} />
            <MediumBlackText>{item}</MediumBlackText>
          </DetailsItem>
        ))}
      </div>
      )}
    </>
  );
};
