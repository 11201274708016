import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SimpleBlackText,
  TextBlackLarge,
} from '../../../shared/styles';
import {
  HeaderGalleryCard,
} from './styled';
import { ViewAllLink } from '../../../shared/components/ViewAllLink/ViewAllLink';
import {
  GalleryCardCover,
  GalleryCardWrapper,
} from '../../../shared/components/GalleryCard/styled';
import { Gallery } from '../../../shared/types';
import { getFileInContainer } from '../../../../utils/galleryCreator/getFileInContainer';

type GalleryCardCustomType = {
  gallery: Gallery | null;
  galleriesNumber: number;
  type: 'Active' | 'Draft';
  toUrl: string;
};

export const GalleryCardCustom: React.FC<GalleryCardCustomType> = ({
  gallery,
  galleriesNumber = 0,
  type,
  toUrl,
}) => {
  const { t } = useTranslation([
    'gallery',
    'basicComponents',
  ]);

  const coverImageUrl = gallery ? gallery.coverFile : '';

  return (
    <Grid item sm={6}>
      <GalleryCardWrapper>
        <HeaderGalleryCard>
          <TextBlackLarge>
            <Trans t={t} i18nKey="gallery:galleriesWithNumber">
              {{ type }}
              {' '}
              galleries (
              {{ galleries: galleriesNumber }}
              )
            </Trans>
          </TextBlackLarge>
          <ViewAllLink
            label={t('basicComponents:viewAll')}
            to={toUrl}
          />
        </HeaderGalleryCard>
        <GalleryCardCover>
          {coverImageUrl ? (
            getFileInContainer({ name: 'cover', url: coverImageUrl, type: 'image' })
          ) : (
            <SimpleBlackText>{t('gallery:noCoverPhoto')}</SimpleBlackText>
          )}
        </GalleryCardCover>
      </GalleryCardWrapper>
    </Grid>
  );
};
