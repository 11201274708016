import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  GalleryDataFormType,
  GalleryFile,
  SectionType,
} from '../../../../shared/types';
import { ButtonCard } from './styled';
import { getURLByFile, isVideo } from '../../../../../utils/commonFunctions';

export const DragAndDropFiles: React.FC<SectionType> = ({
  section,
  indexSection,
}) => {
  const { t } = useTranslation('createNewGallery');

  // to change color, when user drags files
  const [isDragProcess, setIsDragProcess] = useState(false);

  const { setFieldValue } = useFormikContext<GalleryDataFormType>();

  const handleClick = (files: File[]) => {
    const filesWithId: GalleryFile[] = files?.map((item) => ({
      id: uuidv4(),
      file: {
        name: item.name,
        url: getURLByFile(item),
        type: item.type,
      },
      name: item.name,
      fullWidth: isVideo(item),
    }));
    let allFiles = [...section.files, ...filesWithId];

    // delete duplicate files
    allFiles = allFiles.filter(
      (item, index, self) => index
        === self.findIndex((file) => file?.file?.name === item?.file?.name),
    );
    setFieldValue(`sections[${indexSection}].files`, allFiles);
  };

  return (
    <ButtonCard isDragProcess={isDragProcess}>
      <input
        name={`sections[${indexSection}].files`}
        multiple
        type="file"
        onDragEnter={() => setIsDragProcess(true)}
        onDragLeave={() => setIsDragProcess(false)}
        onChange={(e) => handleClick(Array.from(e.target.files || []))}
      />
      <span>
        <Trans i18nKey="secondStep.addContent" t={t}>
          Drag and drop to add files
          <p>or</p>
          <u>upload from your computer</u>
        </Trans>
      </span>
    </ButtonCard>
  );
};
