import { Grid } from '@material-ui/core';
import React from 'react';
import { Navigation } from './Navigation';
import { SectionsList } from './SectionsList';

export const GalleryContent: React.FC = () => (
  <Grid container spacing={1} id="galleryContent">
    <Grid item sm={2}>
      <Navigation />
    </Grid>
    <Grid item sm={9}>
      <SectionsList />
    </Grid>
  </Grid>
);
