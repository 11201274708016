import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';

export const SettingsWrapper = styled.div`
  margin-top: 60px;
`;

export const RowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MarginTopWrapper = styled.div`
  margin-top: 60px;
`;

export const TitleMgB = styled.div`
  margin-bottom: 30px;
`;

export const TextMgB = styled.div`
  margin-bottom: 16px;
`;
