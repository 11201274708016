import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';

export const ReadOnlyPriceInput = styled.div`
  font-size: inherit;
  width: fit-content;

  & input {
    border: none;
    background: ${variables.colorGreyLight};
    color: ${variables.textBlack};
    width: auto;
    padding: 0;
    font-size: inherit;
    text-align: right;
    max-width: 150px;
  }
`;
