import React, {
  useState,
  MouseEvent,
  useContext,
  useEffect,
} from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
} from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { WalkThroughStart } from './WalkThroughStart';
import { useGetSteps } from './useGetSteps';
import * as variables from '../../../constants/styleVariables';
import { CustomTooltip } from './CustomTooltip/CustomTooltip';
import { OnboardingContext } from '../../../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../../../context/OnboardingStepIndexContext';
import * as urls from '../../../constants/urls';

export const WalkThroughCards: React.FC = () => {
  const { setIsStudy, isStudy } = useContext(OnboardingContext);
  const {
    setStepIndex, stepIndex,
  } = useContext(OnboardingStepIndexContext);
  const [runTraining, setRunTraining] = useState(isStudy);
  const history = useHistory();

  const steps = useGetSteps();

  const handleClickStart = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setRunTraining(true);
  };

  useEffect(() => {
    if (isStudy) {
      setRunTraining(true);
    }
  }, [isStudy]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {
      action, index, status, type,
    } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setIsStudy(false);
      setRunTraining(false);
      history.push(`${urls.rootUrl}`);
    }
  };

  return (
    <>
      <WalkThroughStart handleClickStart={handleClickStart} />
      <Joyride
        callback={handleJoyrideCallback}
        disableOverlayClose
        continuous
        stepIndex={stepIndex}
        tooltipComponent={CustomTooltip}
        run={runTraining}
        scrollToFirstStep={false}
        showProgress={false}
        steps={steps}
        floaterProps={{
          styles: {
            arrow: {
              marginBottom: '40px',
            },
          },
        }}
        spotlightClicks
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Done!',
          next: stepIndex === 4 ? 'Let\'s go!' : 'Next',
          open: 'Open the dialog',
          skip: 'Close',
        }}
        styles={{
          spotlight: {
            borderRadius: '10px',
          },
          options: {
            zIndex: 100000,
            arrowColor: variables.colorGreyLight,
            backgroundColor: variables.colorGreyLight,
            beaconSize: 22,
            overlayColor: 'rgba(0, 0, 0, 0.3)',
            primaryColor: '#f04',
            spotlightShadow: 'none',
            textColor: '#333',
            width: '260px',
          },
        }}
      />
    </>
  );
};
