// first, fifth and sixth are trial gallery covers

export const ImageSizes = {
  first: {
    desktop: {
      height: 556,
      width: 758,
    },
    tablet: {
      height: 424,
      width: 372,
    },
    mobile: {
      height: 246,
      width: 171,
    },
  },
  second: {
    desktop: {
      height: 581,
      width: 871,
    },
    tablet: {
      height: 392,
      width: 505,
    },
    mobile: {
      height: 167,
      width: 150,
    },
  },
  third: {
    desktop: {
      height: 600,
      width: 1096,
    },
    tablet: {
      height: 576,
      width: 531,
    },
    mobile: {
      height: 235,
      width: 150,
    },
  },
  fourth: {
    desktop: {
      height: 407,
      width: 532,
    },
    tablet: {
      height: 331,
      width: 512,
    },
    mobile: {
      height: 151,
      width: 151,
    },
  },
  fifth: {
    desktop: {
      height: 580,
      width: 870,
    },
    tablet: {
      height: 461,
      width: 501,
    },
    mobile: {
      height: 248,
      width: 176,
    },
  },
  sixth: {
    desktop: {
      height: 580,
      width: 870,
    },
    tablet: {
      height: 575,
      width: 504,
    },
    mobile: {
      height: 301,
      width: 172,
    },
  },
  extraCover: {
    desktop: {
      height: 189,
      width: 195,
    },
    tablet: {
      height: 115,
      width: 115,
    },
    mobile: {
      height: 71,
      width: 71,
    },
  },
};
