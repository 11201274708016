import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const DetailsContentWrapper = styled.div<{noOverflowY?: boolean}>`
  overflow-y: ${(props) => (props.noOverflowY ? 'none' : 'auto')};
  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 213px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;

export const GalleryDetailsTabs = styled.div`
  margin-top: 60px;
  height: 100%;
`;

export const GalleryDetailsWrapper = styled.div`
  margin-top: 40px;
`;

export const TabsWrapper = styled.div`
  width: 520px; 
  
  & div {
    width: 100%;
  }
`;
