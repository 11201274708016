import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styleVariables';

export const PreviewArrowButton = styled.button`
  color: ${variables.colorBlack};
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;

  & div {
    display: inline-block;
    margin-right: 15px;

    & svg {
      color: ${variables.colorBlack};
    }
  }
`;

export const SentLinkWrapper = styled.div`
  position: absolute;
  left: 100%;
  bottom: 15px;
  white-space: pre;
  width: fit-content;
  display: flex;
  flex-direction: row;
  font-weight: 500;

  & svg {
    margin-right: 10px;
  }
`;

export const ButtonsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 40px;

  & button:first-of-type {
    margin-right: 40px;
  }
`;

export const SharableLinkWrapper = styled.div`
  display: flex;
  flexDirection: row;
  width: fit-content;
`;

export const GalleryPreviewHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 40px;
  background: ${variables.colorGreyLight};
  box-shadow: 0px 0px 20px 5px #E7E7E7;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3000;
  box-sizing: border-box;
`;

export const HeaderTextWrapper = styled.div`
  max-width: 420px;
  margin-right: 40px;
`;
