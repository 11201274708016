import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GalleryComment,
  GalleryDownloads,
  GalleryIdea,
  GalleryLikes,
} from '../../../../../shared/types';
import { SimpleBlackText } from '../../../../../shared/styles';
import { BaseTabs } from '../../../../../shared/components/BaseTabs/BaseTabs';
import { TabPanel } from './TabPanel';
import { GalleryDetailsWrapper, TabsWrapper } from './styled';

type GalleryDetailsType = {
  comments: GalleryComment[];
  likes: GalleryLikes;
  downloads: GalleryDownloads;
  ideas: GalleryIdea[];
};

const useGetTabsGalleryDetails = () => {
  const { t } = useTranslation('gallery');
  const tabs = [
    { label: t('commentsLabel'), id: 'comments' },
    { label: t('likesLabel'), id: 'likes' },
    { label: t('downloadsLabel'), id: 'downloads' },
    { label: t('ideasLabel'), id: 'ideas' },
  ];
  return tabs;
};

export const GalleryDetails: React.FC<GalleryDetailsType> = ({
  comments,
  likes,
  downloads,
  ideas,
}) => {
  const { t } = useTranslation('gallery');
  const [value, setValue] = React.useState(0);
  const tabs = useGetTabsGalleryDetails();

  return (
    <GalleryDetailsWrapper>
      <TabsWrapper>
        <BaseTabs tabs={tabs} value={value} setValue={setValue} />
      </TabsWrapper>
      <TabPanel value={value} index={0}>
        {comments.length ? (
          comments.map((item) => <SimpleBlackText>{item.text}</SimpleBlackText>)
        ) : (
          <SimpleBlackText>{t('noComments')}</SimpleBlackText>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {likes.numberLikes ? (
          likes.likes.map((item) => <SimpleBlackText>{item}</SimpleBlackText>)
        ) : (
          <SimpleBlackText>{t('noLikes')}</SimpleBlackText>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {downloads.numberDownloads ? (
          downloads.downloads.map((item) => (
            <SimpleBlackText>{item}</SimpleBlackText>
          ))
        ) : (
          <SimpleBlackText>{t('noDownloads')}</SimpleBlackText>
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {ideas.length ? (
          ideas.map((item) => <SimpleBlackText>{item}</SimpleBlackText>)
        ) : (
          <SimpleBlackText>{t('noIdeas')}</SimpleBlackText>
        )}
      </TabPanel>

    </GalleryDetailsWrapper>
  );
};
