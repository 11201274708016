import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { GalleryData } from '../../../../shared/types';
import { CommonSwitch } from '../../../../shared/components/CommonSwitch/CommonSwitch';
import { GridFitContent } from './styled';
import { PremiumPlanCard } from '../../../../shared/components/PremiumPlanCard/PremiumPlanCard';
import { ListItemContainer } from '../../../../shared/components/CommonSwitch/ListItemContainer';

export const FourthStagePremiumContent: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { values, handleChange } = useFormikContext<GalleryData>();
  const isPremium = false;

  return (
    <Grid
      container
      item
      sm={6}
      id="premium-fourth-step"
      alignContent="flex-start"
      style={{
        paddingBottom: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        marginTop: '20px',
        width: '100%',
      }}
    >
      <GridFitContent item sm={8} style={{ marginTop: '-20px' }}>
        <ListItemContainer>
          <CommonSwitch
            isChecked={values.autoAlert}
            handleChange={handleChange}
            name="autoAlert"
            label={t('fourthStep.autoAlert')}
            helpText={t('fourthStep.helpTexts.automaticEmail')}
            positionHelpText="top"
            disabled={!isPremium}
          />
        </ListItemContainer>
      </GridFitContent>
      <GridFitContent item sm={8}>
        <ListItemContainer>
          <CommonSwitch
            isChecked={values.branding}
            handleChange={handleChange}
            name="branding"
            label={t('fourthStep.addBranding')}
            disabled={!isPremium}
          />
        </ListItemContainer>
      </GridFitContent>
      <GridFitContent item sm={12}>
        <ListItemContainer>
          <CommonSwitch
            isChecked={values.testimonialCall}
            handleChange={handleChange}
            name="testimonialCall"
            label={t('fourthStep.leaveCall')}
            shortLabel
            helpText={t('fourthStep.helpTexts.testimonialCall')}
            positionHelpText="top"
            disabled={!isPremium}
          />
        </ListItemContainer>
      </GridFitContent>
      {!isPremium && (
        <Grid item sm={12}>
          <PremiumPlanCard />
        </Grid>
      )}
    </Grid>
  );
};
