import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';

export const NavigationWrapper = styled.div`
  position: sticky;
  top: 100px;
`;

export const NavigationTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${variables.colorBlack};
  margin-bottom: 3px;
`;

export const SectionNavigationList = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  & > a,
  & > div,
  & > button {
    margin-top: 13px;
  }
`;

export const SectionListWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
`;

export const SectionTitleLarge = styled.div`
  font-size: 28px;
  line-height: 26px;
  color: ${variables.colorBlack};
`;

export const LineGrey = styled.div`
  width: 100%;
  padding-bottom: 1px;
  background: #d7d7d7;
  margin: 28px 0;
`;

export const GallerySectionHeader = styled.div`
  margin-bottom: 30px;
`;

export const DocumentsListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -30px;
`;

export const FileOrCoverWrapper = styled.div<{fullWidth: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;

  & > div > button {
    display: none;
    transition: 0.3s;
  }

  &:hover {
    & > div > button {
      display: flex;
      transition: 0.3s;
    }

    & > div > svg {
      display: none;
    }
  }

  & video {
    width: 100%;
    height: auto;
  }
`;
