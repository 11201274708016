import React, { ChangeEvent } from 'react';
import { isVideo } from '../../../utils';
import { CustomFileType } from '../types';
import {
  AddFileLabel,
  CommonInputWrapper,
  ErrorMessage,
  TextLabel,
} from './styled';
import { VideoContainer } from './VideoContainer/VideoContainer';

type CommonInputProps = {
  error?: string | any;
  value: CustomFileType | null;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean | any;
  label?: string;
  accept?: string;
  id?: string;
};

export const CommonFileUpload: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  handleBlur,
  touched,
  label,
  accept = '*',
  id,
}) => (
  <CommonInputWrapper id={id}>
    <TextLabel htmlFor={name}>{label}</TextLabel>
    <AddFileLabel
      imageUrl={value?.url || ''}
      hasFile={!!value?.url}
      hasError={!!(error && touched)}
    >
      {isVideo(value) && value ? <VideoContainer videoUrl={value.url} /> : null}
      <input
        id={name}
        name={name}
        type="file"
        onChange={handleChange}
        onBlur={handleBlur}
        accept={accept}
      />
    </AddFileLabel>
    {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
  </CommonInputWrapper>
);
