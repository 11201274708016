import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindowContainer } from '../../../../../shared/components/ModalWindow/ModalWindowContainer';
import {
  ChangePhotoWindowContainer,
  ChangePhotoWindowWrapper,
  ProfilePhotoUpload,
} from './styled';
import { PrimaryWhiteButton } from '../../../../../shared/styles';
import { useSaveCroppedImage } from '../../../../../shared/hooks/useSaveCroppedImage';
import { CustomFileType, SetFieldValue } from '../../../../../shared/types';
import { CloseModalWindowButton } from '../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import { BaseCropper } from '../../../../../shared/components/Croppers/BaseCropper/BaseCropper';

type ChangePhotoWindowType = {
  image: CustomFileType;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setFieldValue: SetFieldValue;
};

export const ChangePhotoWindow: React.FC<ChangePhotoWindowType> = ({
  image,
  isOpen,
  setIsOpen,
  setFieldValue,
}) => {
  const { t } = useTranslation('basicComponents');
  const saveCroppedImage = useSaveCroppedImage(setFieldValue);

  const handleClose = () => {
    saveCroppedImage('croppedPhoto', image, 'profile');
    setIsOpen(false);
  };

  return (
    <ModalWindowContainer isOpen={isOpen} handleClose={() => setIsOpen(false)}>
      <ChangePhotoWindowContainer>
        <ChangePhotoWindowWrapper>
          <ProfilePhotoUpload>
            <BaseCropper
              videoOrImage={{ image: image.url }}
              width={280}
              height={280}
              size="profile"
              cropShape="round"
            />
          </ProfilePhotoUpload>
          <PrimaryWhiteButton type="button" onClick={handleClose}>{t('buttons.save')}</PrimaryWhiteButton>
        </ChangePhotoWindowWrapper>
      </ChangePhotoWindowContainer>
      <CloseModalWindowButton handleClick={() => setIsOpen(false)} color="black" />
    </ModalWindowContainer>
  );
};
