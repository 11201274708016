import { Grid } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { GalleryData } from '../../../../shared/types';
import { FormGalleryWrapper, GridWrapper } from '../styled';
import { CustomDatePicker } from '../../../../shared/components/CustomDatePicker/CustomDatePicker';
import { CommonFileUpload } from '../../../../shared/components/CommonFileUpload';
import { CommonTextarea } from '../../../../shared/components/CommonTextarea';
import { PreviewSwiper } from '../PreviewSwiper/PreviewSwiper';
import { getFullName } from '../../../../../utils/getFullName';
import { CommonCopyInput } from '../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector';
import { dilivrableGalleryURL } from '../../../../../constants';
import { getURLByFile } from '../../../../../utils';
import { deleteSizesFromLocalStorage } from '../../../../../utils/galleryCreator/deleteSizesFromLocalStorage';

export const NewGalleryCreatorFirstStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const {
    values, errors, handleChange, handleBlur, touched, setFieldValue,
  } = useFormikContext<GalleryData>();

  const user = useTypedSelector((state) => state.signInCreator.currentUser);

  useEffect(
    () => setFieldValue(
      'galleryUrl',
      `${user?.businessName}.${dilivrableGalleryURL}${values.galleryName.toLowerCase().replace(/\s/g, '')}`,
    ),
    [values.galleryName],
  );

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const newCoverImage = event.currentTarget.files || [];
      setFieldValue('coverFile.original.file', {
        name: newCoverImage[0].name,
        url: getURLByFile(newCoverImage[0]),
        type: newCoverImage[0].type,
      });
      setFieldValue('coverFile.desktop.file', null);
      setFieldValue('coverFile.tablet.file', null);
      setFieldValue('coverFile.mobile.file', null);
      deleteSizesFromLocalStorage(['desktop', 'tablet', 'mobile']);
    }
  };
  return (
    <FormGalleryWrapper>
      <GridWrapper container spacing={4} direction="row" justifyContent="space-between">
        <Grid container item sm={4}>
          <div id="galleryNameDateFirstStep" style={{ height: 'fit-content', marginTop: '10px', paddingBottom: '7px' }}>
            <Grid item sm={12} style={{ marginTop: '-10px' }}>
              <CommonInput
                label={t('firstStep.galleryName')}
                error={errors.galleryName}
                value={values.galleryName}
                name="galleryName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                touched={touched.galleryName}
              />
            </Grid>
            <Grid item sm={9}>
              <CustomDatePicker
                name="date"
                data={values.date}
                setFieldValue={setFieldValue}
                touched={touched.date}
                label={t('firstStep.projectDate')}
                error={errors.date}
              />
            </Grid>
          </div>
        </Grid>
        <Grid container item sm={6}>
          <Grid item sm={9}>
            <div id="coverFileFirstStep" style={{ width: '100%', paddingBottom: '10px' }}>
              <CommonFileUpload
                name="coverFile"
                handleChange={handleUploadFile}
                touched={touched.coverFile?.original?.file}
                label={t('firstStep.choosePhotoVideo')}
                value={values.coverFile.original.file}
                error={errors.coverFile?.original?.file}
                accept="image/*, video/*"
              />
            </div>
          </Grid>
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={4} direction="row">
        <Grid item sm={6}>
          <CommonTextarea
            name="message"
            value={values.message}
            handleChange={handleChange}
            touched={touched.message}
            label={t('firstStep.message')}
            error={errors.message}
            placeholder="Message"
          />
        </Grid>
        <Grid item sm={6}>
          <CommonCopyInput
            label={t('firstStep.galleryUrl')}
            value={values.galleryUrl}
            name="galleryUrl"
            readonly
          />
        </Grid>
      </GridWrapper>
      <PreviewSwiper
        file={
            values?.coverFile?.desktop?.file
              ? values?.coverFile?.desktop?.file
              : values?.coverFile?.original?.file
          }
        setFieldValue={setFieldValue}
        value={values.galleryDesign}
        date={values.date}
        name={values.galleryName}
        userName={getFullName(user?.firstName, user?.lastName)}
        message={values.message}
      />
    </FormGalleryWrapper>
  );
};
