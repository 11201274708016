/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Gallery } from '../../../../shared/types';
import { ModalWindowContainer } from '../../../../shared/components/ModalWindow/ModalWindowContainer';
import { SalesCard } from './SalesCard';
import { GalleryInfo } from './GalleryInfo';
import { GalleryDetails } from './GalleryDetails/GalleryDetails';
import { DashboardHeader } from './DashboardHeader/DashboardHeader';
import { WhiteButtonWithoutShadow } from '../../../../shared/styles';

type DashboardType = {
  gallery: Gallery;
};

export const Dashboard: React.FC<DashboardType> = ({
  gallery,
}) => {
  const { t } = useTranslation('activeGalleries');
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);

  const handleCloseDashboard = () => {
    setIsOpenDashboard(false);
  };

  const handleOpenDashboard = () => {
    setIsOpenDashboard(true);
  };

  return (
    <>
      <WhiteButtonWithoutShadow type="button" onClick={handleOpenDashboard}>
        {t('enterDashboard')}
      </WhiteButtonWithoutShadow>
      <ModalWindowContainer
        size="large"
        isOpen={isOpenDashboard}
        handleClose={handleCloseDashboard}
      >
        <DashboardHeader
          title={gallery.galleryName}
          idGallery={gallery.id}
          handleCloseDashboard={handleCloseDashboard}
        />
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item sm={4}>
            <SalesCard
              sales={gallery.sales}
              salesPerWeek={gallery.salesPerWeek}
            />
          </Grid>
          <Grid item sm={8}>
            <GalleryInfo
              galleryUrl={gallery.galleryUrl}
              likes={gallery.likes.numberLikes}
              shares={gallery.shares}
              views={gallery.views}
              downloads={gallery.downloads.numberDownloads}
            />
          </Grid>
        </Grid>
        <GalleryDetails
          comments={gallery.comments}
          likes={gallery.likes}
          downloads={gallery.downloads}
          ideas={gallery.ideas}
        />
      </ModalWindowContainer>
    </>
  );
};
