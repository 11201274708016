import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { GalleryCoverSixth } from '../../../../../GalleryView/GallerySixth/GalleryCoverSixth';
import { GalleryDemoSixthMobile } from '../../../../../GalleryView/GallerySixth/GalleryDemoSixthMobile';
import { GalleryDemoSixthTablet } from '../../../../../GalleryView/GallerySixth/GalleryDemoSixthTablet';
import { useGalleryContent } from '../../../../../GalleryView/useGalleryContent';
import { GalleryCoverType } from '../../../../../shared/types';
import { ModalWindow } from '../ModalWindow';
import { PreviewCard, PreviewWrapper } from '../styled';
import { SelectedType } from '../types';
import {
  SixthInfoCardCoverPreview,
  SixthTitlePreview,
  SixthMediumTextPreview,
  ImageContainerSixthPreview,
} from './styled';

export const PreviewSixth: React.FC<GalleryCoverType & SelectedType> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const refCardPreview = useRef<HTMLDivElement>(null);

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCard theme={selected ? 'selected' : ''}>
        <Grid
          style={{ marginTop: '-10px', marginBottom: '-10px' }}
          container
          justifyContent="center"
        >
          <Grid container item sm={12} justifyContent="center">
            <ImageContainerSixthPreview>
              {data.file}
              <SixthInfoCardCoverPreview
                currentHeight={refCardPreview?.current?.offsetHeight || 0}
                ref={refCardPreview}
              >
                <SixthTitlePreview>{data.name}</SixthTitlePreview>
                <SixthMediumTextPreview>{data.message}</SixthMediumTextPreview>
              </SixthInfoCardCoverPreview>
            </ImageContainerSixthPreview>
          </Grid>
          <Grid item sm={6}>
            <SixthMediumTextPreview>{data.date}</SixthMediumTextPreview>
            <SixthMediumTextPreview>{data.userName}</SixthMediumTextPreview>
          </Grid>
        </Grid>
      </PreviewCard>
      <ModalWindow>
        <>
          <GalleryCoverSixth
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
          />
          <Grid container item sm={10} justifyContent="space-between">
            <GalleryDemoSixthTablet
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
            <GalleryDemoSixthMobile
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
          </Grid>
        </>
      </ModalWindow>
    </PreviewWrapper>
  );
};
