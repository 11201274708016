import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const PaymentSetupWindowContainer = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  padding: 30px 50px 40px;
  width: 870px;
  height: 465px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PaymentSetupFormWrapper = styled.div`
  height: 100%;

  & > form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;
