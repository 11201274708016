import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumBlackText } from '../../shared/styles';
import { GallerySection } from '../../shared/types';
import {
  DocumentsListWrapper,
  GallerySectionHeader,
  LineGrey,
  SectionListWrapper,
  SectionTitleLarge,
} from './styled';
import { ImagesAndVideosList } from './ImagesAndVideosList/ImagesAndVideosList';
import { BasicDocumentCard } from '../../shared/components/BasicDocumentCard';
import { isDocument, isImage, isVideo } from '../../../utils';

type SectionItemType = {
  section: GallerySection;
};

export const SectionItem: React.FC<SectionItemType> = ({ section }) => {
  const { t } = useTranslation('gallery');

  const arrImages = useMemo(
    () => section.files
      ?.filter((item) => isImage(item?.file) || isVideo(item?.file)),
    [section.files],
  );

  const arrDocuments = useMemo(
    () => section.files
      ?.filter((item) => isDocument(item?.file))
      .map((item) => item.id),
    [section.files],
  );

  const arrImagesAndIdeas = [...arrImages, ...section.ideas];

  return (
    <SectionListWrapper id={section.id}>
      <GallerySectionHeader>
        <SectionTitleLarge>{section.title}</SectionTitleLarge>
        <LineGrey />
        {section.numberFreeFiles && (
          <MediumBlackText>
            0
            {' '}
            /
            {' '}
            {section.numberFreeFiles}
            {' '}
            {t('freeFiles')}
          </MediumBlackText>
        )}
      </GallerySectionHeader>
      <ImagesAndVideosList files={arrImagesAndIdeas} section={section} />
      <DocumentsListWrapper>
        {arrDocuments?.map((item) => (
          <BasicDocumentCard
            file={item}
            key={item}
          />
        ))}
      </DocumentsListWrapper>
    </SectionListWrapper>
  );
};
