/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, SetStateAction } from 'react';

type ContextType = {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
}

export const OnboardingStepIndexContext = React.createContext<ContextType>({
  stepIndex: 0,
  setStepIndex: () => {},
});
