import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSaveCroppedImage } from '../../../../shared/hooks/useSaveCroppedImage';
import { GalleryData } from '../../../../shared/types';
import { ModalWindowContainer } from '../../../../shared/components/ModalWindow/ModalWindowContainer';
import { GalleryGridContainer } from '../../../../shared/components/GalleryGridContainer';
import { BaseSecondaryButton } from '../../../../shared/styles/Buttons';
import { ClosePreviewButtonWrapper } from './styled';

type ModalWindowType = {
  children: ReactElement;
};

export const ModalWindow: React.FC<ModalWindowType> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<GalleryData>();
  const { t } = useTranslation('createNewGallery');
  const saveCroppedImage = useSaveCroppedImage(setFieldValue);

  const image = values.coverFile?.original?.file;
  const extraCover1 = values.extraCover1?.original?.file || null;
  const extraCover2 = values.extraCover2?.original?.file || null;

  const handleClose = async () => {
    if (extraCover1) {
      saveCroppedImage('extraCover1.desktop', extraCover1, 'extraCover1desktop');
      saveCroppedImage('extraCover1.tablet', extraCover1, 'extraCover1tablet');
      saveCroppedImage('extraCover1.mobile', extraCover1, 'extraCover1mobile');
    }
    if (extraCover2) {
      saveCroppedImage('extraCover2.desktop', extraCover2, 'extraCover2desktop');
      saveCroppedImage('extraCover2.tablet', extraCover2, 'extraCover2tablet');
      saveCroppedImage('extraCover2.mobile', extraCover2, 'extraCover2mobile');
    }
    saveCroppedImage('coverFile.desktop', image, 'desktop');
    saveCroppedImage('coverFile.tablet', image, 'tablet');
    saveCroppedImage('coverFile.mobile', image, 'mobile');
    setTimeout(() => setIsOpen(false), 400);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        disabled={!values.coverFile.original.file}
      >
        <u>{t('preview')}</u>
      </button>
      <ModalWindowContainer
        size="full"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <ClosePreviewButtonWrapper>
          <BaseSecondaryButton type="button" onClick={handleClose}>
            {t('closePreview')}
          </BaseSecondaryButton>
        </ClosePreviewButtonWrapper>
        <GalleryGridContainer>
          {children}
        </GalleryGridContainer>
      </ModalWindowContainer>
    </>
  );
};
