import styled from '@emotion/styled';
import { StylesConfig } from 'react-select';
import { Option } from '../../types';
import * as variables from '../../../../constants';
import { errorMessages } from '../../../../utils/errorsMessages';

export const InputChipsWrapper = styled.div`
& svg {
  color: ${variables.textBlack} !important;
  width: 12px;
  height: 12px;
}

margin-top: -10px;
`;

type IsMulti = true;
export const getInputChipsStyles = (error: string, touched: boolean) => {
  const selectStyle: StylesConfig<Option, IsMulti> = {
    control: () => ({
      width: 307,
      minWidth: 307,
      height: 120,
      background: variables.colorGreyLight,
      fontSize: 15,
      boxShadow: 'inset -5px -5px 10px #FFFFFF, inset 2px 2px 5px #E7E7E7',
      borderRadius: 16,
      padding: '12px 24px',
      fontFamily: variables.mainFont,
      boxSize: 'border-box',
      border: `1px solid ${error === errorMessages.requiredField && touched ? variables.colorError : 'transparent'}`,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    placeholder: () => ({
      color: variables.colorBleak,
      gridArea: '1/1/2/3',
      boxSizing: 'border-box',
      fontSize: 15,
    }),
    input: (provided) => ({
      ...provided,
      color: variables.textGrey,
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      border: `1px solid ${error ? variables.colorError : variables.colorBleak}`,
      boxSizing: 'border-box',
      borderRadius: 4,
    }),
    multiValueLabel: () => ({
      background: variables.colorGreyLight,
      padding: '6px 8px 6px 12px',
      border: 'none',
      fontSize: 14,
      lineHeight: '150%',
      color: variables.textBlack,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      background: variables.colorGreyLight,
      color: `${variables.textBlack} !important`,
      cursor: 'pointer',
      paddingLeft: 4,
      paddingRight: 8,
      ':hover': {
        backgroundColor: variables.colorGreyLight,
      },
    }),
  };
  return selectStyle;
};
