import { ResultCheckDuplication } from '../containers/shared/types';

export const getNewCheckingResult = (
  arr: ResultCheckDuplication[],
  obj: ResultCheckDuplication,
): ResultCheckDuplication[] => {
  if (arr.some((item) => item.field === obj.field)) {
    return arr.map((item) => (item.field === obj.field ? obj : item)) as ResultCheckDuplication[];
  }
  arr.push(obj);
  return arr;
};
