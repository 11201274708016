import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { GalleryCoverFourth } from '../../../../GalleryView/GalleryFourth/GalleryCoverFourth';
import { GalleryDemoFourthMobile } from '../../../../GalleryView/GalleryFourth/GalleryDemoFourthMobile';
import { GalleryDemoFourthTablet } from '../../../../GalleryView/GalleryFourth/GalleryDemoFourthTablet';
import { useGalleryContent } from '../../../../GalleryView/useGalleryContent';
import { GalleryCoverType, GalleryData } from '../../../../shared/types';
import { ModalWindow } from './ModalWindow';
import {
  CreatorCell,
  DateCell,
  ImageContainerFourth,
  ImageFirstCell,
  ImageSecondCell,
  PreviewCardFourth,
  PreviewWrapper,
  SquareBoxes,
  TextContainerFourth,
} from './styled';
import { SelectedType } from './types';
import { getFileInContainer } from '../../../../../utils/galleryCreator/getFileInContainer';

export const PreviewFourth: React.FC<GalleryCoverType & SelectedType> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });

  const { values } = useFormikContext<GalleryData>();

  const extra1File = values.extraCover1?.desktop.file || values.extraCover1?.original.file;
  const extra2File = values.extraCover2?.desktop.file || values.extraCover2?.original.file;

  return (
    <PreviewWrapper>
      <PreviewCardFourth theme={selected ? 'selected' : ''}>
        <TextContainerFourth>
          {data.name}
          {data.message}
        </TextContainerFourth>
        <ImageContainerFourth>
          {data.file}
        </ImageContainerFourth>
        <SquareBoxes>
          <CreatorCell>
            {data.userName}
          </CreatorCell>
          <ImageFirstCell>{getFileInContainer(extra1File || null)}</ImageFirstCell>
          <ImageSecondCell>{getFileInContainer(extra2File || null)}</ImageSecondCell>
          <DateCell>{data.date}</DateCell>
        </SquareBoxes>
      </PreviewCardFourth>
      <ModalWindow>
        <>
          <GalleryCoverFourth
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
          />
          <Grid container item sm={10} justifyContent="space-between">
            <GalleryDemoFourthTablet
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
            <GalleryDemoFourthMobile
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
            />
          </Grid>
        </>
      </ModalWindow>
    </PreviewWrapper>
  );
};
