import React, {
  Dispatch, ReactElement, SetStateAction, useState,
} from 'react';
import { CustomAlert } from '../components/CustomAlert/CustomAlert';

type ReturnType = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  dialogWindow: ReactElement;
};
// Create hook for confirmation and alerting
export const useDialog = <ItemType, >(
  item: ItemType,
  handleAction: CallableFunction,
  message: string,
  title: string,
  buttonText?: string,
): ReturnType => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (data: any) => {
    handleAction(data);
    setIsOpen(false);
  };
  return {
    setIsOpen,
    dialogWindow: (
      <CustomAlert
        setIsOpen={setIsOpen}
        handleClick={handleClick}
        item={item}
        isOpenWindow={isOpen}
        message={message}
        title={title}
        buttonText={buttonText}
      />
    ),
  };
};
