import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { GalleryDataFormType } from '../../../shared/types';
import { ArrowButtons } from '../../../shared/components/ArrowButtons/ArrowButtons';
import { ButtonsWrapper } from './styled';
import { ModalWindowGalleryPreview } from './NewGalleryCreatorFifthStep/ModalWindowGalleryPreview';
import { SaveAndClose } from './SaveAndClose/SaveAndClose';

type ChangeStageButtonsType = {
  step: number;
};

export const ChangeStageButtons: React.FC<ChangeStageButtonsType> = ({
  step,
}) => {
  const [isOpenGalleryPreview, setIsOpenGalleryPreview] = useState(false);

  const { values, setFieldValue } = useFormikContext<GalleryDataFormType>();

  const handleLeftClick = () => {
    if (values.step === 5 && step === values.step) {
      setIsOpenGalleryPreview(true);
    }
    if (values.step > 1 && step === values.step) {
      const prevStage = step - 1;
      setFieldValue('step', prevStage);
    }
  };
  const handleRightClick = () => {
    if (values.step === 4 && step === values.step) {
      setIsOpenGalleryPreview(true);
    }
    if (values.step < 5 && step === values.step) {
      const nextStage = step + 1;
      setFieldValue('step', nextStage);
    }
  };

  return (
    <>
      <ButtonsWrapper>
        <ArrowButtons
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
          buttonType="submit"
          large
          toRightNone={values.step === 5}
        />
        <SaveAndClose />
      </ButtonsWrapper>
      <ModalWindowGalleryPreview
        setIsOpen={setIsOpenGalleryPreview}
        isOpen={isOpenGalleryPreview}
      />
    </>
  );
};
