import React, { Dispatch, SetStateAction } from 'react';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { IdeaHeader, IdeaHeaderTitle, MiniTitleText } from './styled';
import { GalleryDataFormType, GalleryIdea } from '../../../../../shared/types';
import { IdeaForm } from './IdeaForm';
import { ModalWindowContainer } from '../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { CloseIcon } from '../../../../../shared/icons/CommonIcons';
import { HelpTip } from '../../../../../shared/components/HelpTip/HelpTip';
import { PinkLink } from '../../../../../shared/styles';
import { PrimaryWhiteButton } from '../../../../../shared/styles/Buttons';

type IdeaModalWindowType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  idea: GalleryIdea;
  indexSection: number;
  indexIdea: number;
  removeCreatedIdea?: (id: string) => void;
};

export const IdeaModalWindow: React.FC<IdeaModalWindowType> = ({
  setIsOpen,
  idea,
  indexIdea,
  indexSection,
  isOpen,
  removeCreatedIdea,
}) => {
  const { t } = useTranslation(['createNewGallery', 'basicComponents']);
  const { handleSubmit, isValid } = useFormikContext<GalleryDataFormType>();

  const handleClose = () => {
    setIsOpen((prev) => !prev);
    if (removeCreatedIdea) {
      removeCreatedIdea(idea.id);
    }
  };

  const handleSaveAndClose = () => {
    handleSubmit();
    if (isValid) {
      setIsOpen(false);
    }
  };

  const helpIdeaText = (
    <Trans t={t} i18nKey="createNewGallery:secondStep.ideaHelpText">
      See community
      {' '}
      <PinkLink href="/">best examples</PinkLink>
    </Trans>
  );

  return (
    <ModalWindowContainer
      size="medium"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <IdeaHeader>
        <IdeaHeaderTitle>
          <MiniTitleText>
            {t('createNewGallery:secondStep.ideas')}
          </MiniTitleText>
          <HelpTip text={helpIdeaText} />
        </IdeaHeaderTitle>
        <button
          type="button"
          onClick={handleClose}
          style={{ marginRight: '35px' }}
        >
          <CloseIcon />
        </button>
      </IdeaHeader>

      <IdeaForm
        key={idea?.id}
        idea={idea}
        ideaField={`sections[${indexSection}].ideas[${indexIdea}]`}
        indexSection={indexSection}
        indexIdea={indexIdea}
      />
      <PrimaryWhiteButton type="submit" onClick={handleSaveAndClose}>
        {t('basicComponents:buttons.saveClose')}
      </PrimaryWhiteButton>
    </ModalWindowContainer>
  );
};
