import { ThemeProvider } from '@emotion/react';
import React, { ChangeEvent } from 'react';
import { HelpTip } from '../HelpTip/HelpTip';
import {
  CommonInputWrapper,
  ErrorMessage,
  HelpWrapper,
  InputBase,
  TextLabel,
} from '../styled';
import { CustomSwitch } from './CustomStyledSwitch';

type CommonInputProps = {
  error?: string | any;
  isChecked: boolean;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  touched?: boolean | any;
  shortLabel?: boolean;
  label?: string;
  helpText?: string;
  positionHelpText?: 'top' | 'left';
  disabled?: boolean;
  spaceBetween?: boolean;
  withoutMargins?: boolean;
  littleText?: boolean;
  blueButton?: boolean;
  id?: string;
};

export const CommonSwitch: React.FC<CommonInputProps> = ({
  error,
  name,
  touched,
  label,
  isChecked,
  shortLabel,
  helpText,
  handleChange,
  positionHelpText = 'left',
  disabled,
  spaceBetween,
  withoutMargins,
  littleText,
  blueButton,
  id,
}) => {
  const theme = {
    width: '100%',
    flexDirection: 'row',
  };

  return (
    <CommonInputWrapper withoutMargins={withoutMargins} id={id || name}>
      <ThemeProvider theme={theme}>
        <InputBase
          color={`${error && touched ? 'error' : 'success'}`}
          width="auto"
          direction="row"
          spaceBetween={spaceBetween}
          littleText={littleText}
        >
          {label && (
            <TextLabel short={shortLabel} htmlFor={name} disabled={disabled}>
              {label}
              {helpText && (
                <HelpWrapper>
                  <HelpTip text={helpText} position={positionHelpText} />
                </HelpWrapper>
              )}
            </TextLabel>
          )}
          <div>
            <CustomSwitch
              id={name}
              checked={isChecked}
              onChange={handleChange}
              name={name}
              disabled={disabled}
              bluebutton={!!blueButton}
            />
          </div>
          {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
        </InputBase>
      </ThemeProvider>
    </CommonInputWrapper>
  );
};
