import React, {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  GalleryData,
  GalleryFileInformation,
  GalleryIdea,
  GallerySection,
} from '../../../../../shared/types';
import {
  ActionButton,
  SectionActionsWrapper,
  SectionContentWrapper,
} from '../styled';
import { CommonCheckbox } from '../../../../../shared/components/CommonCheckbox';
import { ArrowIcon } from '../../../../../shared/icons';
import { isArrayIncludeValue } from '../../../../../../utils/commonFunctions';
import { ApplyAndChosenButtons } from './ApplyAndChosenButtons';

type SectionActionsType = {
  section: GallerySection;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
  isFormHidden: boolean;
  values: GalleryFileInformation;
  handleSubmit: () => void;
  isValid: boolean;
  resetForm: () => void;
};

export const SectionActions: React.FC<SectionActionsType> = ({
  section,
  selected,
  setSelected,
  setIsHidden,
  isFormHidden,
  values,
  handleSubmit,
  isValid,
  resetForm,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { values: globalValues } = useFormikContext<GalleryData>();
  const [settingsApplied, setSettingsApplied] = useState(false);

  const currentSection = useMemo(
    () => globalValues?.sections?.find((item) => item.id === section.id),
    [globalValues?.sections],
  );

  const ideas = section.ideas as GalleryIdea[];
  const numberFiles = section?.files?.length + ideas?.length;
  const numberChosenFiles = selected?.length;
  const buttonsVisible = settingsApplied || numberChosenFiles > 0;

  const handleSelectAll = () => {
    if (selected.length === section?.files?.length + ideas?.length) {
      setSelected([]);
    } else {
      const newSet = new Set([
        ...selected,
        ...section?.files?.map((item) => item.id),
        ...ideas?.map((item) => item.id),
      ]);
      const newArr = Array.from(newSet);
      setSelected(newArr);
    }
  };
  const handleApplySettings = (e: any) => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      setSettingsApplied(true);
      setTimeout(() => {
        setSelected([]);
        currentSection?.files?.forEach((item) => {
          if (isArrayIncludeValue(selected, item.id)) {
            Object.assign(item, values);
          }
        });
        setSettingsApplied(false);
      }, 1000);
    }
    ideas.forEach((item) => {
      if (isArrayIncludeValue(selected, item.id)) {
        Object.assign(item, {
          instantlyDownloadable: values.instantlyDownloadable,
          watermark: values.watermark,
          allowCommenting: values.allowCommenting,
        });
      }
    });
  };

  const handleUncheckedChosen = () => {
    setSelected([]);
    resetForm();
  };

  return (
    <SectionContentWrapper
      isFormOpened={isFormHidden}
      item
      sm={buttonsVisible ? 7 : 12}
    >
      <SectionActionsWrapper>
        <CommonCheckbox
          isChecked={selected.length === numberFiles}
          handleChange={handleSelectAll}
          simple
          name=""
          text={`${t('thirdStep.all')}${
            selected.length ? ` (${selected.length})` : ''
          }`}
        />
        {buttonsVisible && (
          <ApplyAndChosenButtons
            handleApplySettings={handleApplySettings}
            settingsApplied={settingsApplied}
            numberChosenFiles={numberChosenFiles}
            isFormHidden={isFormHidden}
            handleUncheckedChosen={handleUncheckedChosen}
          />
        )}
        <ActionButton
          type="button"
          onClick={() => setIsHidden((prev) => !prev)}
        >
          <span>
            {!isFormHidden
              ? t('thirdStep.collapseSettings')
              : t('thirdStep.expandSettings')}
          </span>
          <ArrowIcon direction={isFormHidden ? 'right' : 'left'} />
        </ActionButton>
      </SectionActionsWrapper>
    </SectionContentWrapper>
  );
};
