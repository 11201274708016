import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SimpleBlackText, SimplePinkText, TextBlackLarge } from '../../../../shared/styles';
import { SalesCardWrapper, SalesWrapper, TextBlackLargeBold } from './styled';

type SalesCardType = {
  sales: number;
  salesPerWeek: number;
};

export const SalesCard: React.FC<SalesCardType> = ({ sales, salesPerWeek }) => {
  const { t } = useTranslation('sales');

  return (
    <SalesCardWrapper>
      <SalesWrapper>
        <div>{t('sales')}</div>
        <TextBlackLargeBold>
          <Trans t={t} i18nKey="salesSum">
            {{ sales }}
          </Trans>
        </TextBlackLargeBold>
      </SalesWrapper>
      <SimpleBlackText>
        <Trans t={t} i18nKey="perWeek">
          <SimplePinkText>
            +
            {' '}
            {{ salesPerWeek }}
          </SimplePinkText>
          {' '}
          <br />
          {' '}
          this week
        </Trans>
      </SimpleBlackText>
    </SalesCardWrapper>
  );
};
