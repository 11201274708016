import {
  ErrorFieldAndMessage,
  ErrorTypes,
  SignUpErrors,
} from '../../containers/shared/types';

export const createObjectForErrors = (
  errorType: ErrorTypes,
  arrOfErrors: ErrorFieldAndMessage[],
): SignUpErrors => ({
  errorType,
  errors: arrOfErrors,
});
