import { Grid } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ViewsLikesDownloadsShares } from '../../shared/components/ViewsLikesDownloadsShares/ViewsLikesDownloadsShares';
import {
  GreyLightWrapper,
  TextBlackLarge,
  CloseButton,
  TitlePinkBold,
  SimpleBlackText,
} from '../../shared/styles';
import { Statistics } from '../../shared/types';
import {
  GridContainerPopupCustom,
  PopupInfoContainerLarge,
  PopupInfoContainer,
  CloseButtonWithoutMgPg,
  GreyLightWrapperFullHeight,
  TextBlackLargeMgBt,
} from './styled';

type PopupContentType = {
  statistics: Statistics | null;
  handleClose: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const PopupContent: React.FC<PopupContentType> = ({
  statistics,
  handleClose,
}) => {
  const { t } = useTranslation([
    'notifications',
    'basicComponents',
    'sales',
    'gallery',
  ]);

  const sales = statistics?.sales;
  const numberIdeas = statistics?.ideas;

  return (
    <GridContainerPopupCustom container spacing={2}>
      <Grid item sm={12}>
        <GreyLightWrapper>
          <PopupInfoContainerLarge>
            <TextBlackLarge>
              {t('notifications:popup.galleriesActivity')}
            </TextBlackLarge>
            <Grid container>
              <Grid item sm={7}>
                <ViewsLikesDownloadsShares
                  likes={statistics?.likes}
                  shares={statistics?.shares}
                  downloads={statistics?.downloads}
                  views={statistics?.views}
                />
              </Grid>
              <Grid container item sm={4} alignItems="flex-end">
                <CloseButton
                  color="pink"
                  type="button"
                  onClick={handleClose}
                  name="activeGallery"
                >
                  {t('basicComponents:buttons.learnMore')}
                </CloseButton>
              </Grid>
            </Grid>
          </PopupInfoContainerLarge>
        </GreyLightWrapper>
      </Grid>
      <Grid item sm={5}>
        <GreyLightWrapper>
          <PopupInfoContainer>
            <div>
              <TextBlackLarge>{t('sales:sales')}</TextBlackLarge>
              <TitlePinkBold>
                <p>
                  <Trans t={t} i18nKey="sales:salesPlus">
                    + $
                    {{ sales }}
                  </Trans>
                </p>
              </TitlePinkBold>
            </div>
            <CloseButtonWithoutMgPg
              color="pink"
              type="button"
              onClick={handleClose}
              name="sales"
            >
              {t('basicComponents:buttons.learnMore')}
            </CloseButtonWithoutMgPg>
          </PopupInfoContainer>
        </GreyLightWrapper>
      </Grid>
      <Grid item sm={7}>
        <GreyLightWrapperFullHeight>
          <PopupInfoContainer>
            <div>
              <TextBlackLargeMgBt>{t('gallery:ideasLabel')}</TextBlackLargeMgBt>
              <SimpleBlackText>
                <Trans t={t} i18nKey="gallery:ideasPurchased">
                  {{ numberIdeas }}
                  {' '}
                  ideas purchased
                </Trans>
              </SimpleBlackText>
            </div>
            <CloseButtonWithoutMgPg
              color="pink"
              type="button"
              onClick={handleClose}
              name="activeGallery"
            >
              {t('basicComponents:buttons.learnMore')}
            </CloseButtonWithoutMgPg>
          </PopupInfoContainer>
        </GreyLightWrapperFullHeight>
      </Grid>
    </GridContainerPopupCustom>
  );
};
