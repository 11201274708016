import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { GalleryCoverType } from '../../shared/types';
import { MobileWrapper } from '../MobileWrapper';
import { useGalleryContent } from '../useGalleryContent';
import {
  SixthMediumTextMobile,
  SixthTitleMobile,
  ImageContainerSixthMobile,
  SixthInfoCardCoverMobile,
} from './styled';

export const GalleryDemoSixthMobile: React.FC<GalleryCoverType> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const refCardMobile = useRef<HTMLDivElement>(null);
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'sixth',
  });
  return (
    <MobileWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingLeft: '12px', paddingRight: '12px', height: '100%' }}
      >
        <ImageContainerSixthMobile>
          {data.file}
          <SixthInfoCardCoverMobile
            ref={refCardMobile}
            currentHeight={refCardMobile?.current?.offsetHeight || 0}
          >
            <div>
              <SixthTitleMobile>{data.name}</SixthTitleMobile>
              <SixthMediumTextMobile>{data.message}</SixthMediumTextMobile>
            </div>
            <div>
              <SixthMediumTextMobile>{data.userName}</SixthMediumTextMobile>
              <SixthMediumTextMobile>{data.date}</SixthMediumTextMobile>
            </div>
          </SixthInfoCardCoverMobile>
        </ImageContainerSixthMobile>
      </Grid>
    </MobileWrapper>
  );
};
