import { Grid } from '@material-ui/core';
import React, {
  Dispatch,
  FormEvent, FormEventHandler, SetStateAction, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  collectedByData,
  storeCurrencyData,
} from '../../../../../../constants/selectorMenuItems';
import { CloseModalWindowButton } from '../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import { CommonInput } from '../../../../../shared/components/CommonInput';
import { CommonSelect } from '../../../../../shared/components/CommonSelect/CommonSelect';
import { ModalWindowContainer } from '../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { useGetSelectionItems } from '../../../../../shared/hooks/useGetSelectionItems';
import { PrimaryWhiteButton, TextBlackLarge } from '../../../../../shared/styles';
import { PaymentSettings, FormikCustomProps } from '../../../../../shared/types';
import { PaymentSetupWindowText } from './PaymentSetupWindowText';
import { PaymentSetupFormWrapper, PaymentSetupWindowContainer } from './styled';

type PaymentSetupWindowType = {
  values: PaymentSettings;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
} & FormikCustomProps<PaymentSettings>;

export const PaymentSetupWindow: React.FC<PaymentSetupWindowType> = ({
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  handleChange,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const getSelectionItems = useGetSelectionItems();

  const handleSaveChanges = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const menuItemsStoreCurrency = useMemo(
    () => getSelectionItems(storeCurrencyData),
    [],
  );
  const menuItemsTimezones = useMemo(
    () => getSelectionItems(collectedByData),
    [],
  );

  return (
    <>
      <Grid item sm={10}>
        <PrimaryWhiteButton
          type="button"
          onClick={() => setIsOpen(true)}
        >
          {t('setup')}
        </PrimaryWhiteButton>
      </Grid>
      <ModalWindowContainer
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      >
        <PaymentSetupWindowContainer>
          <TextBlackLarge><p>{t('paymentSetupTitle')}</p></TextBlackLarge>
          <PaymentSetupFormWrapper>
            <form onSubmit={handleSaveChanges}>
              <Grid container justifyContent="space-between">
                <Grid container item sm={5} style={{ height: 'fit-content' }}>
                  <Grid item sm={12}>
                    <CommonSelect
                      error={errors.storeCurrency}
                      value={values.storeCurrency}
                      name="storeCurrency"
                      handleChange={handleChange}
                      touched={touched.storeCurrency}
                      label={t('storeCurrency')}
                      menuItems={menuItemsStoreCurrency}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <CommonSelect
                      error={errors.collectedBy}
                      value={values.collectedBy}
                      name="collectedBy"
                      handleChange={handleChange}
                      touched={touched.collectedBy}
                      label={t('collectedBy')}
                      menuItems={menuItemsTimezones}
                    />
                  </Grid>
                </Grid>
                <Grid container item sm={5}>
                  <Grid item sm={11}>
                    <CommonInput
                      label={t('paypalEmail')}
                      value={values.paypalEmail}
                      name="paypalEmail"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      direction="column"
                      placeholder={t('basicComponents:emailPlaceholder')}
                      error={errors?.paypalEmail}
                      touched={touched?.paypalEmail}
                      type="email"
                    />
                  </Grid>
                  <PaymentSetupWindowText />
                </Grid>
              </Grid>
              <div>
                <PrimaryWhiteButton type="submit">
                  {t('basicComponents:buttons.saveChanges')}
                </PrimaryWhiteButton>
              </div>
            </form>
          </PaymentSetupFormWrapper>
        </PaymentSetupWindowContainer>
        <CloseModalWindowButton handleClick={() => setIsOpen(false)} color="grey" />
      </ModalWindowContainer>
    </>
  );
};
