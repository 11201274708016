import React from 'react';
import { ArrowRotation } from '../styles';
import { Direction } from '../types';
import * as variables from '../../../constants/styleVariables';

export const TickIcon: React.FC<{ isGradient?: boolean }> = ({
  isGradient = true,
}) => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0888 14.402C6.2248 14.5918 6.40409 14.7465 6.61181 14.8532C6.81953 14.9599 7.04968 15.0156 7.2832 15.0156C7.51672 15.0156 7.74688 14.9599 7.9546 14.8532C8.16231 14.7465 8.34161 14.5918 8.47761 14.402L18.2085 0.91005C18.384 0.665162 18.2085 0.323242 17.9081 0.323242H15.7411C15.2698 0.323242 14.8216 0.549648 14.5444 0.937773L7.28551 11.0105L3.99569 6.44545C3.71846 6.06194 3.27489 5.83092 2.79898 5.83092H0.631947C0.331612 5.83092 0.156032 6.17284 0.331612 6.41772L6.0888 14.402Z"
      fill={isGradient ? 'url(#paint0_linear)' : 'currentColor'}
    />
    {isGradient && (
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18.2783"
          y1="7.66941"
          x2="0.261718"
          y2="7.66941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6934F" />
          <stop offset="0.498191" stopColor="#E186B0" />
          <stop offset="1" stopColor="#78C0EA" />
        </linearGradient>
      </defs>
    )}
  </svg>
);

export const QuestionIcon: React.FC<{ isPinkBackground: boolean }> = ({
  isPinkBackground,
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={isPinkBackground ? variables.colorPink : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="9.5"
      stroke={isPinkBackground ? variables.colorPink : '#D5D5D5'}
    />
    <path
      d="M9.91352 5.528C10.7055 5.528 11.3455 5.752 11.8335 6.2C12.3215 6.648 12.5655 7.248 12.5655 8C12.5655 8.84 12.3055 9.464 11.7855 9.872C11.2655 10.272 10.5615 10.472 9.67352 10.472L9.63752 11.612H8.68952L8.64152 9.716H8.98952C9.78152 9.716 10.4015 9.596 10.8495 9.356C11.2975 9.116 11.5215 8.664 11.5215 8C11.5215 7.52 11.3775 7.14 11.0895 6.86C10.8015 6.58 10.4135 6.44 9.92552 6.44C9.42952 6.44 9.03752 6.576 8.74952 6.848C8.46952 7.112 8.32952 7.476 8.32952 7.94H7.29752C7.29752 7.46 7.40552 7.04 7.62152 6.68C7.83752 6.312 8.14152 6.028 8.53352 5.828C8.93352 5.628 9.39352 5.528 9.91352 5.528ZM9.15752 14.072C8.94952 14.072 8.77352 14 8.62952 13.856C8.48552 13.712 8.41352 13.536 8.41352 13.328C8.41352 13.12 8.48552 12.944 8.62952 12.8C8.77352 12.656 8.94952 12.584 9.15752 12.584C9.35752 12.584 9.52552 12.656 9.66152 12.8C9.80552 12.944 9.87752 13.12 9.87752 13.328C9.87752 13.536 9.80552 13.712 9.66152 13.856C9.52552 14 9.35752 14.072 9.15752 14.072Z"
      fill={isPinkBackground ? variables.colorGreyLight : '#D5D5D5'}
    />
  </svg>
);

export const CalendarIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1875 3.59375H13.9062V2.34375C13.9062 2.25781 13.8359 2.1875 13.75 2.1875H12.6562C12.5703 2.1875 12.5 2.25781 12.5 2.34375V3.59375H7.5V2.34375C7.5 2.25781 7.42969 2.1875 7.34375 2.1875H6.25C6.16406 2.1875 6.09375 2.25781 6.09375 2.34375V3.59375H2.8125C2.4668 3.59375 2.1875 3.87305 2.1875 4.21875V17.1875C2.1875 17.5332 2.4668 17.8125 2.8125 17.8125H17.1875C17.5332 17.8125 17.8125 17.5332 17.8125 17.1875V4.21875C17.8125 3.87305 17.5332 3.59375 17.1875 3.59375ZM16.4062 16.4062H3.59375V8.98438H16.4062V16.4062ZM3.59375 7.65625V5H6.09375V5.9375C6.09375 6.02344 6.16406 6.09375 6.25 6.09375H7.34375C7.42969 6.09375 7.5 6.02344 7.5 5.9375V5H12.5V5.9375C12.5 6.02344 12.5703 6.09375 12.6562 6.09375H13.75C13.8359 6.09375 13.9062 6.02344 13.9062 5.9375V5H16.4062V7.65625H3.59375Z"
      fill="currentColor"
    />
  </svg>
);

export const LittleTickIcon: React.FC = () => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53545 0.797657L4.59952 7.64141C4.53053 7.7377 4.43958 7.81615 4.33422 7.87028C4.22885 7.92441 4.11211 7.95264 3.99366 7.95264C3.8752 7.95264 3.75846 7.92441 3.65309 7.87028C3.54773 7.81615 3.45679 7.7377 3.3878 7.64141L0.465141 3.59141C0.376078 3.46719 0.465141 3.29375 0.617484 3.29375H1.7167C1.95577 3.29375 2.18311 3.40859 2.32373 3.60547L3.99248 5.92109L7.67686 0.811718C7.81749 0.617187 8.04248 0.5 8.28389 0.5H9.38311C9.53545 0.5 9.62452 0.673438 9.53545 0.797657Z"
      fill="#F8F8F8"
    />
  </svg>
);

export const EditIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03984 17.625C6.08672 17.625 6.13359 17.6203 6.18047 17.6133L10.1227 16.9219C10.1695 16.9125 10.2141 16.8914 10.2469 16.8563L20.182 6.92109C20.2038 6.89941 20.221 6.87366 20.2328 6.8453C20.2445 6.81695 20.2506 6.78656 20.2506 6.75586C20.2506 6.72516 20.2445 6.69477 20.2328 6.66642C20.221 6.63806 20.2038 6.61231 20.182 6.59063L16.2867 2.69297C16.2422 2.64844 16.1836 2.625 16.1203 2.625C16.057 2.625 15.9984 2.64844 15.9539 2.69297L6.01875 12.6281C5.98359 12.6633 5.9625 12.7055 5.95312 12.7523L5.26172 16.6945C5.23892 16.8201 5.24707 16.9493 5.28545 17.071C5.32384 17.1927 5.39132 17.3032 5.48203 17.393C5.63672 17.543 5.83125 17.625 6.03984 17.625ZM7.61953 13.5375L16.1203 5.03906L17.8383 6.75703L9.3375 15.2555L7.25391 15.6234L7.61953 13.5375ZM20.625 19.5938H3.375C2.96016 19.5938 2.625 19.9289 2.625 20.3438V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H21.1875C21.2906 21.375 21.375 21.2906 21.375 21.1875V20.3438C21.375 19.9289 21.0398 19.5938 20.625 19.5938Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowIcon: React.FC<{ direction: Direction }> = ({
  direction,
}) => (
  <ArrowRotation direction={direction}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3427 11.2148L12.5638 20.1797C12.4935 20.2608 12.4066 20.3259 12.3089 20.3704C12.2113 20.415 12.1052 20.4381 11.9978 20.4381C11.8904 20.4381 11.7843 20.415 11.6867 20.3704C11.589 20.3259 11.5021 20.2608 11.4318 20.1797L3.65522 11.2148C3.63172 11.1878 3.61648 11.1546 3.61132 11.1192C3.60616 11.0837 3.6113 11.0476 3.62612 11.015C3.64094 10.9824 3.66481 10.9547 3.69491 10.9353C3.725 10.9159 3.76003 10.9055 3.79584 10.9055H5.69428C5.80209 10.9055 5.90522 10.9523 5.97788 11.0344L11.1083 16.9476V3.74999C11.1083 3.64687 11.1927 3.56249 11.2958 3.56249H12.7021C12.8052 3.56249 12.8896 3.64687 12.8896 3.74999V16.9476L18.0201 11.0344C18.0904 10.9523 18.1935 10.9055 18.3037 10.9055H20.2021C20.3615 10.9055 20.4482 11.093 20.3427 11.2148Z"
        fill="currentColor"
      />
    </svg>
  </ArrowRotation>
);

export const ArrowSimpleIcon: React.FC<{
  direction: Direction;
  color?: string;
}> = ({ direction, color }) => (
  <ArrowRotation direction={direction}>
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.5L7 6.5L13 0.5" stroke={color || '#828D95'} />
    </svg>
  </ArrowRotation>
);

export const DeleteIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4375 4.3125H8.25C8.35313 4.3125 8.4375 4.22812 8.4375 4.125V4.3125H15.5625V4.125C15.5625 4.22812 15.6469 4.3125 15.75 4.3125H15.5625V6H17.25V4.125C17.25 3.29766 16.5773 2.625 15.75 2.625H8.25C7.42266 2.625 6.75 3.29766 6.75 4.125V6H8.4375V4.3125ZM20.25 6H3.75C3.33516 6 3 6.33516 3 6.75V7.5C3 7.60313 3.08437 7.6875 3.1875 7.6875H4.60312L5.18203 19.9453C5.21953 20.7445 5.88047 21.375 6.67969 21.375H17.3203C18.1219 21.375 18.7805 20.7469 18.818 19.9453L19.3969 7.6875H20.8125C20.9156 7.6875 21 7.60313 21 7.5V6.75C21 6.33516 20.6648 6 20.25 6ZM17.1398 19.6875H6.86016L6.29297 7.6875H17.707L17.1398 19.6875Z"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0119 10L16.1388 3.88867C16.2248 3.78711 16.1525 3.63281 16.0197 3.63281H14.4611C14.3693 3.63281 14.2814 3.67383 14.2209 3.74414L9.99234 8.78516L5.76383 3.74414C5.70523 3.67383 5.61734 3.63281 5.52359 3.63281H3.965C3.83219 3.63281 3.75992 3.78711 3.84586 3.88867L8.97281 10L3.84586 16.1113C3.82661 16.134 3.81426 16.1617 3.81028 16.1911C3.80629 16.2206 3.81084 16.2505 3.82339 16.2775C3.83593 16.3044 3.85594 16.3272 3.88104 16.3431C3.90615 16.359 3.93528 16.3674 3.965 16.3672H5.52359C5.61539 16.3672 5.70328 16.3262 5.76383 16.2559L9.99234 11.2148L14.2209 16.2559C14.2794 16.3262 14.3673 16.3672 14.4611 16.3672H16.0197C16.1525 16.3672 16.2248 16.2129 16.1388 16.1113L11.0119 10Z"
      fill="currentColor"
    />
  </svg>
);

export const CopyIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 1.5H6.9375C6.83437 1.5 6.75 1.58437 6.75 1.6875V3C6.75 3.10312 6.83437 3.1875 6.9375 3.1875H18.5625V19.3125C18.5625 19.4156 18.6469 19.5 18.75 19.5H20.0625C20.1656 19.5 20.25 19.4156 20.25 19.3125V2.25C20.25 1.83516 19.9148 1.5 19.5 1.5ZM16.5 4.5H4.5C4.08516 4.5 3.75 4.83516 3.75 5.25V17.6883C3.75 17.8875 3.82969 18.0773 3.97031 18.218L8.03203 22.2797C8.08359 22.3312 8.14219 22.3734 8.20547 22.4086V22.4531H8.30391C8.38594 22.4836 8.47266 22.5 8.56172 22.5H16.5C16.9148 22.5 17.25 22.1648 17.25 21.75V5.25C17.25 4.83516 16.9148 4.5 16.5 4.5ZM8.20312 20.0672L6.18516 18.0469H8.20312V20.0672ZM15.5625 20.8125H9.70312V17.4844C9.70312 16.9664 9.28359 16.5469 8.76562 16.5469H5.4375V6.1875H15.5625V20.8125Z"
      fill="#051B2C"
    />
  </svg>
);

export const EyeIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3372 9.7875C18.6021 7.88603 17.326 6.24164 15.6665 5.05755C14.007 3.87347 12.0369 3.20161 9.99973 3.125C7.96256 3.20161 5.99248 3.87347 4.33299 5.05755C2.67349 6.24164 1.39733 7.88603 0.662234 9.7875C0.612589 9.92482 0.612589 10.0752 0.662234 10.2125C1.39733 12.114 2.67349 13.7584 4.33299 14.9424C5.99248 16.1265 7.96256 16.7984 9.99973 16.875C12.0369 16.7984 14.007 16.1265 15.6665 14.9424C17.326 13.7584 18.6021 12.114 19.3372 10.2125C19.3869 10.0752 19.3869 9.92482 19.3372 9.7875ZM9.99973 15.625C6.68723 15.625 3.18723 13.1687 1.91848 10C3.18723 6.83125 6.68723 4.375 9.99973 4.375C13.3122 4.375 16.8122 6.83125 18.081 10C16.8122 13.1687 13.3122 15.625 9.99973 15.625Z"
      fill="currentColor"
    />
    <path
      d="M10 6.25C9.25832 6.25 8.5333 6.46993 7.91661 6.88199C7.29993 7.29404 6.81928 7.87971 6.53545 8.56494C6.25162 9.25016 6.17736 10.0042 6.32206 10.7316C6.46675 11.459 6.8239 12.1272 7.34835 12.6517C7.8728 13.1761 8.54098 13.5333 9.26841 13.6779C9.99584 13.8226 10.7498 13.7484 11.4351 13.4646C12.1203 13.1807 12.706 12.7001 13.118 12.0834C13.5301 11.4667 13.75 10.7417 13.75 10C13.75 9.00544 13.3549 8.05161 12.6517 7.34835C11.9484 6.64509 10.9946 6.25 10 6.25ZM10 12.5C9.50555 12.5 9.0222 12.3534 8.61108 12.0787C8.19995 11.804 7.87952 11.4135 7.6903 10.9567C7.50108 10.4999 7.45157 9.99723 7.54804 9.51228C7.6445 9.02732 7.8826 8.58186 8.23223 8.23223C8.58187 7.8826 9.02732 7.6445 9.51228 7.54804C9.99723 7.45157 10.4999 7.50108 10.9567 7.6903C11.4135 7.87952 11.804 8.19995 12.0787 8.61108C12.3534 9.0222 12.5 9.50555 12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5Z"
      fill="currentColor"
    />
  </svg>
);

export const DownloadIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.878 13.0238C9.8923 13.0432 9.91056 13.0589 9.9314 13.0697C9.95225 13.0805 9.97514 13.0861 9.99832 13.0861C10.0215 13.0861 10.0444 13.0805 10.0652 13.0697C10.0861 13.0589 10.1044 13.0432 10.1186 13.0238L12.2577 10.1484C12.336 10.0429 12.2653 9.88664 12.1374 9.88664H10.7222V3.01976C10.7222 2.93047 10.6534 2.85742 10.5694 2.85742H9.42346C9.33943 2.85742 9.27067 2.93047 9.27067 3.01976V9.88461H7.85929C7.73133 9.88461 7.66066 10.0409 7.73897 10.1464L9.878 13.0238ZM16.9884 12.3136H15.8425C15.7584 12.3136 15.6897 12.3866 15.6897 12.4759V15.6009H4.30696V12.4759C4.30696 12.3866 4.2382 12.3136 4.15417 12.3136H3.00826C2.92422 12.3136 2.85547 12.3866 2.85547 12.4759V16.4938C2.85547 16.853 3.12858 17.1431 3.46662 17.1431H16.53C16.8681 17.1431 17.1412 16.853 17.1412 16.4938V12.4759C17.1412 12.3866 17.0724 12.3136 16.9884 12.3136Z"
      fill="currentColor"
    />
  </svg>
);
