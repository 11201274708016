import { Plan } from '../containers/shared/types';

export const plans: Plan[] = [
  {
    planType: 'Free',
    current: true,
    priceMonthly: 0,
    priceAnnually: 0,
    description: 'Everything you need to try out Delivrable and earn more from your client projects.',
    details: [
      '3 gallery designs',
      '2GB storage',
      '10% commission on your sales',
      '$0 for 14 days',
    ],
  },
  {
    planType: 'Premium',
    current: false,
    priceMonthly: 29,
    priceAnnually: 290,
    description: 'The full Delivrable experience - 10x more storage, extremely low commissions, and premium features.',
    details: [
      '5 gallery designs',
      '20GB storage',
      '1% commission on your sales',
      'Testimonial call-to-action at gallery footer',
      '2 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
    ],
  },
  {
    planType: 'Premium +',
    current: false,
    priceMonthly: 39,
    priceAnnually: 390,
    description: 'Everything in Premium + an extra 20GB of storage and no commissions.',
    details: [
      '7 gallery designs',
      '40GB storage',
      'Testimonial call-to-action at gallery footer',
      'Up to 5 user seats for an extra team member',
      'Remove Delivrable branding',
      'Auto-alert emails to clients',
      'Premium + support',
    ],
  },
];
