import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import * as variables from '../../../constants/styleVariables';

export const UserCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const UserPhoto = styled.div`
  margin-right: 30px;

  & > img {
    background: white;
    width: 83px;
    height: 83px;
    border-radius: 50%;
  }
`;
export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 18px;
`;

export const SidebarWrapper = styled.div`
  background: ${variables.colorGreyLight};
  width: 93.04%;
  height: 100%;
  box-shadow: 5px 5px 11px #f1f0f0;
`;

export const SidebarMenuWrapper = styled.div`
  margin: 50px 0 43px;
`;

export const SidebarMenuItem = styled.div<{isActive?: boolean}>`
  & a.active,
  & button.active {
    color: ${variables.colorPink};
    padding: 0;

    & div {
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    }

    & svg {
      color: ${variables.colorPink};
    }
  }

  & button {
    ${(props) => (props.isActive ? `
    color: ${variables.colorPink};
    padding: 0;

    & div {
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    }

    & svg {
      color: ${variables.colorPink};
    }
    ` : '')}
    
  }

  & a,
  & button {
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & span {
      font-size: 16px;
      line-height: 24px;
    }

    &:hover {
      color: ${variables.colorPink};

      & svg {
        color: ${variables.colorPink};
      }
    }

    &:active {
      color: ${variables.colorPink};

      & div {
        box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
      }

      & svg {
        color: ${variables.colorPink};
      }
    }
  }
`;
export const SidebarLinkIcon = styled.div`
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${variables.colorGreyLight};
  box-shadow: -5px -5px 10px #fff, 2px 2px 4px #e8e8e8;
  border-radius: 4px;
  margin-right: 12px;
`;
export const LogoutButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 12px;
    color: ${variables.textBlack};
  }

  &:hover {
    color: ${variables.colorPink};

    & svg {
      color: ${variables.colorPink};
    }
  }
`;

export const AlignCenterNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLink = styled.button<{isActive: boolean}>`

`;
