import React from 'react';
import { useFormikContext } from 'formik';

type ReturnType = (fieldName: string) => void

export const useResetField = <T, >(): ReturnType => {
  const {
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext<T>();
  const resetField = (fieldName: string) => {
    setFieldValue(fieldName, null);
    setFieldError(fieldName, '');
    setFieldTouched(fieldName, false);
  };
  return resetField;
};
