import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../../constants/styleVariables';
import { ImageSizes } from '../../../constants/galleryPreviewsAndCovers/previewSizes';

export const FifthTitle = styled.div`
  font-size: 28px;
  line-height: 42px;
  color: ${variables.textBlack};
  width: 100%;

  & h3 {
    text-align: center;
    width: 100%;
    font-weight: 400;
    margin: 0;
  }
`;

export const FifthMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessage = styled(FifthMediumText)`
  text-align: center;

  & p {
    margin: 0;
  }
`;

export const FifthImageContainer = styled.div`
  width: 100%;
  height: ${ImageSizes.fifth.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;
  display: flex;


`;

export const ImageContainerFifthTablet = styled(FifthImageContainer)`
  width: ${ImageSizes.fifth.tablet.width}px;
  height: ${ImageSizes.fifth.tablet.height}px;
`;

export const FifthTitleTablet = styled(FifthTitle)`
  font-size: 20px;
  line-height: 30px;
  width: 100%;
`;

export const FifthMediumTextTablet = styled(FifthMediumText)`
  font-size: 10px;
  line-height: 15px;

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessageTablet = styled(FifthMediumTextMessage)`
  font-size: 10px;
  line-height: 15px;
  height: fit-content;
`;

export const ImageContainerFifthMobile = styled(FifthImageContainer)`
  width: ${ImageSizes.fifth.mobile.width}px;
  height: ${ImageSizes.fifth.mobile.height}px;
`;

export const FifthTitleMobile = styled(FifthTitle)`
  font-size: 15.4251px;
  line-height: 23px;
  text-align: center;

  & h3 {
    text-align: center;
  }
`;

export const FifthMediumTextMobile = styled(FifthMediumText)`
  font-size: 8px;
  line-height: 12px;

  & p {
    margin: 0;
  }
`;

export const FifthMediumTextMessageMobile = styled(FifthMediumTextMessage)`
  font-size: 8px;
  line-height: 12px;
  height: fit-content;
`;

export const GridWithBorderR = styled(Grid)`
  border-right: 1px solid ${variables.textGrey} !important;
  height: fit-content;
`;

export const GridWithBorderL = styled(Grid)`
  border-left: 1px solid ${variables.textGrey} !important;
  height: fit-content;
`;
