import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import {
  GalleryIdea,
  GallerySection,
} from '../../../../shared/types';
import { SectionFilesWrapper } from './styled';
import { MiniFileCard } from '../../../../shared/components/GalleryCreator/MiniFileCard';
import { isItemWithAppliedSettings } from './isItemWithAppliedSettings';

type FilesSectionType = {
  section: GallerySection;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  isFormHidden: boolean;
};

export const FilesSection: React.FC<FilesSectionType> = ({
  section,
  selected,
  setSelected,
  isFormHidden,
}) => {
  const ideas = section.ideas as GalleryIdea[];

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target.id;
    const selectedIndex = selected.indexOf(element);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, element);
      setSelected(newSelected);
    }
    if (selectedIndex >= 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      setSelected(newSelected);
    }
  };
  return (
    <Grid item sm={isFormHidden ? 12 : 2}>
      <SectionFilesWrapper limitedWidth={!isFormHidden} id="checkboxesFilesThirdStep">
        <div>
          {section?.files?.map((item) => (
            <MiniFileCard
              large
              file={item?.file}
              handleCheck={handleSelect}
              isChecked={selected.includes(item.id)}
              id={item.id}
              key={item.id}
              isSettingsApplied={isItemWithAppliedSettings(item)}
            />
          ))}
          {ideas.map((item) => (
            <MiniFileCard
              large
              file={item.cover}
              handleCheck={handleSelect}
              isChecked={selected.includes(item.id)}
              id={item.id}
              key={item.id}
              isSettingsApplied={isItemWithAppliedSettings(item)}
            />
          ))}
        </div>
      </SectionFilesWrapper>
    </Grid>
  );
};
