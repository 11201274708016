import React, { ReactNode } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useStyles } from '../../hooks/style/modalWindow/useStyles';
import { getContainerBySize } from './getContainerBySize';
import { ModalWindowSize } from '../../types';

type ModalWindowContainerType = {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  size?: ModalWindowSize;
};

export const ModalWindowContainer: React.FC<ModalWindowContainerType> = ({
  children,
  isOpen,
  handleClose,
  size,
}) => {
  const classes = useStyles();
  const container = getContainerBySize({ isOpen, children }, size);

  return (
    <>
      <Modal
        id="modalWindow"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        {container}
      </Modal>
    </>
  );
};
