import { Gallery, GalleryTypes } from '../../containers/shared/types';
import noPhoto from '../../assets/image/noPhoto.png';

const galleries: Gallery[] = [
  {
    id: '1d1d11-safe21',
    galleryName: 'Gallery name 1',
    galleryUrl: 'businessName.delivrable.com/Galleryname1',
    likes: { numberLikes: 0, likes: [] },
    views: 20,
    shares: 10,
    sales: 350,
    salesPerWeek: 95,
    comments: [{ text: 'hello' }],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: noPhoto,
    wasWatched: true,
    type: 'active',
  },
  {
    id: 'wewq1qe-wq564q',
    galleryName: 'Gallery name 2',
    galleryUrl: 'businessName.delivrable.com/Galleryname2',
    likes: { numberLikes: 0, likes: [] },
    views: 2,
    shares: 1,
    sales: 35,
    salesPerWeek: 9,
    comments: [{ text: 'hello 2' }],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: noPhoto,
    wasWatched: false,
    type: 'active',
  },
  {
    id: 'ewqe55-e56qw4',
    galleryName: 'Gallery name 3',
    galleryUrl: 'businessName.delivrable.com/Galleryname3',
    likes: { numberLikes: 0, likes: [] },
    views: 0,
    shares: 0,
    sales: 0,
    salesPerWeek: 0,
    comments: [],
    downloads: { numberDownloads: 0, downloads: [] },
    ideas: [],
    coverFile: '',
    wasWatched: false,
    type: 'draft',
  },
];

export const fakeActiveGallery = {
  getGalleries: (typeGallery?: GalleryTypes) => Promise.resolve({
    data: typeGallery ? galleries.filter((item) => item.type === typeGallery) : galleries,
  }),
  deleteGallery: (id: string) => Promise.resolve({
    data: galleries.filter((item) => item.id !== id && item.type === 'active'),
  }),
};
