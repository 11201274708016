import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { paymentCurrencyData } from '../../../../../constants/selectorMenuItems';
import { CommonSelect } from '../../../../shared/components/CommonSelect/CommonSelect';
import { useGetSelectionItems } from '../../../../shared/hooks/useGetSelectionItems';
import { SimpleBlackText } from '../../../../shared/styles';
import { SettingsWrapper } from '../styled';

export const PaymentCurrency: React.FC = () => {
  const { t } = useTranslation('settings');
  const getSelectionItems = useGetSelectionItems();

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik<{paymentCurrency: string}>({
    // validationSchema: clientSchema,
    initialValues: { paymentCurrency: 'Australian / New Zeland dollar' },
    onSubmit: (data) => onSubmit(data),
  });

  const menuItemsPaymentCurrency = useMemo(() => getSelectionItems(paymentCurrencyData), []);

  return (
    <SettingsWrapper>
      <SimpleBlackText>{t('paymentCurrency')}</SimpleBlackText>
      <Grid container>
        <Grid item sm={5}>
          <CommonSelect
            error={errors.paymentCurrency}
            value={values.paymentCurrency}
            name="paymentCurrency"
            placeholder="Australian / New Zeland dollar"
            handleChange={handleChange}
            touched={touched.paymentCurrency}
            menuItems={menuItemsPaymentCurrency}
          />
        </Grid>
      </Grid>
    </SettingsWrapper>

  );
};
