import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
  TabContentWrapper,
  TabWrapper,
  TitleText,
} from '../../../shared/styles';
import { fetchGetGalleries } from '../../../../Redux/gallery/galleryThunk';
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector';
import { ActiveGalleriesCards } from './ActiveGalleriesCards';

export const ActiveGalleries: React.FC = () => {
  const { t } = useTranslation('activeGalleries');
  const dispatch = useDispatch();
  const galleries = useTypedSelector((state) => state.galleries.galleries) || [];
  const isLoading = useTypedSelector((state) => state.galleries.loading);

  useEffect(() => {
    dispatch(fetchGetGalleries('active'));
  }, []);

  return (
    <TabWrapper>
      <TitleText>{t('title')}</TitleText>
      <TabContentWrapper>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <Grid container spacing={4}>
            <ActiveGalleriesCards galleries={galleries} />
          </Grid>
        )}
      </TabContentWrapper>
    </TabWrapper>
  );
};
