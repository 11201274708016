import React, { ReactNode } from 'react';
import { QuestionIcon } from '../../icons';
import { HelpTipButton } from './styled';

type HelpTextType = {
  text: string | ReactNode;
  position?: 'top' | 'left';
  isPinkBackground?: boolean;
};

export const HelpTip: React.FC<HelpTextType> = ({
  text,
  position,
  isPinkBackground = false,
}) => (
  <HelpTipButton position={position || 'top'} type="button">
    <span>{text}</span>
    <QuestionIcon isPinkBackground={isPinkBackground} />
  </HelpTipButton>
);
