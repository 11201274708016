import styled from '@emotion/styled';

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin: 0;
  }

  & > img {
    margin-right: 16px;
  }
`;
