import { useTranslation } from 'react-i18next';

export const useGetTabsSales = () => {
  const { t } = useTranslation('basicComponents');
  const tabs = [
    { label: t('day'), id: 'day' },
    { label: t('week'), id: 'week' },
    { label: t('month'), id: 'month' },
  ];
  return tabs;
};
