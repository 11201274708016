import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSecondaryButton, MediumBlackText, SimpleBlackText } from '../../../../shared/styles';
import { BillingHistory } from '../../../../shared/types';
import { MarginTopWrapper, TitleMgB } from '../styled';
import { RecentBillingHistoryRow } from './RecentBillingHistoryRow';
import { ButtonMgT } from './styled';

export const fakeBillingHistory: BillingHistory[] = [
  {
    id: '19376493031-0001',
    date: '12/07/2021',
    document: '19376493031-0001',
    sum: '200.00',
    status: 'Paid',
  },
  {
    id: '3547687908-0002',
    date: '10/23/2021',
    document: '3547687908-0002',
    sum: '365.00',
    status: 'Paid',
  },
  {
    id: '2456768o76-0003',
    date: '09/16/2021',
    document: '2456768o76-0003',
    sum: '89.00',
    status: 'Paid',
  },
];

export const RecentBillingHistory: React.FC = () => {
  const { t } = useTranslation('settings');

  return (
    <MarginTopWrapper>
      <TitleMgB><SimpleBlackText>{t('recentBillingHistory')}</SimpleBlackText></TitleMgB>
      <Grid container spacing={2}>
        <Grid container item sm={12}>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('date')}</MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>
              {t('document')}
            </MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('sum')}</MediumBlackText>
          </Grid>
          <Grid item sm={3}>
            <MediumBlackText fontWeight={500}>{t('status')}</MediumBlackText>
          </Grid>
        </Grid>
        {fakeBillingHistory.map((item) => (
          <RecentBillingHistoryRow key={item.id} row={item} />
        ))}
      </Grid>
      <ButtonMgT>
        <BaseSecondaryButton type="button" onClick={() => console.log('show more')}>
          {t('showMore')}
        </BaseSecondaryButton>
      </ButtonMgT>
    </MarginTopWrapper>
  );
};
