import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initData, paymentSetupInitialValues } from '../../../../../../constants/initialValues/creator';
import { SuccessNotification } from '../../../../../shared/components/SuccessNotification/SuccessNotification';
import {
  MediumBlackText,
  SimpleBlackText,
} from '../../../../../shared/styles';
import { PaymentSettings } from '../../../../../shared/types';
import { paymentSetup } from '../../../../../shared/validation/settingsSchemas';
import {
  RowsWrapper, SettingsWrapper, TextMgB, TitleMgB,
} from '../../styled';
import { PaymentSetupWindow } from './PaymentSetupWindow';

export const Payments: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isOpenPaymentSetupWindow, setIsOpenPaymentSetupWindow] = useState(false);
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);

  const taxHandledBy = 'Delivrable';

  const onSubmit = (data: any) => {
    console.log('submit');
    setIsOpenPaymentSetupWindow(false);
    setIsNotificationOpened(true);
  };

  const {
    values, handleChange, handleBlur, handleSubmit, errors, touched,
  } = useFormik<PaymentSettings>({
    validationSchema: paymentSetup,
    initialValues: paymentSetupInitialValues(initData),
    onSubmit: (data) => onSubmit(data),
  });

  return (
    <>
      <SettingsWrapper>
        <TitleMgB>
          <SimpleBlackText>{t('payments')}</SimpleBlackText>
        </TitleMgB>
      </SettingsWrapper>
      <RowsWrapper>
        <Grid container item sm={7} style={{ height: 'fit-content' }}>
          <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>
                {t('currency')}
                {' '}
                {values.storeCurrency}
              </MediumBlackText>
            </TextMgB>
          </Grid>
          <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>
                {t('paymentCollectedBy')}
                {' '}
                {values.collectedBy}
              </MediumBlackText>
            </TextMgB>
          </Grid>
          <Grid item sm={10}>
            <TextMgB>
              <MediumBlackText>
                {t('taxHandledBy')}
                {' '}
                {taxHandledBy}
              </MediumBlackText>
            </TextMgB>
          </Grid>
          <PaymentSetupWindow
            values={values}
            handleSubmit={handleSubmit}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            isOpen={isOpenPaymentSetupWindow}
            setIsOpen={setIsOpenPaymentSetupWindow}
          />
        </Grid>
      </RowsWrapper>
      <SuccessNotification
        isOpen={isNotificationOpened}
        text={t('basicComponents:changesSaved')}
        setIsOpen={setIsNotificationOpened}
        longTime
      />
    </>
  );
};
