import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../constants/urls';
import {
  ButtonSubmitSignUp, LittleText, TextNote, TitleText,
} from './styled';
import { CommonInput } from '../shared/components/CommonInput';
import { SignUpEmail } from '../shared/validation/signInUpSchemas';
import {
  CustomGridWithoutPadding,
  FormBase,
  SignInUpCardColorful,
} from '../shared/styles';
import { UserStateContext } from '../../context/UserStateContext';
import { fetchSignUpInitialCreator } from '../../Redux/userCreator/signUp/signUpCreatorThunk';
import { useTypedSelector } from '../shared/hooks/useTypedSelector';

export const SignUpEmailConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['signUp', 'basicComponents']);
  const { isSignIn } = useContext(UserStateContext);

  const signUpError = useTypedSelector((state) => state.signUpCreator.error);
  const wasSent = useTypedSelector((state) => state.signUpCreator.wasSent);
  const isLoading = useTypedSelector((state) => state.signUpCreator.loading);

  const onSubmit = (data: { email: string }) => {
    dispatch(fetchSignUpInitialCreator(data.email));
    window.localStorage.setItem('email', data.email);
  };

  const {
    values,
    handleSubmit,
    errors,
    handleChange,
    touched,
    handleBlur,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SignUpEmail,
    onSubmit: (data) => onSubmit(data),
  });

  useEffect(() => {
    const emailError = signUpError?.errors?.find((item) => item.field === 'email');
    if (emailError) {
      setFieldError('email', emailError.message);
    }
  }, [signUpError]);

  if (isSignIn) {
    return <Redirect to={urls.rootUrl} />;
  }

  if (wasSent) {
    history.push(urls.signUpConfirmWasSentUrl);
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCardColorful>
          <TitleText>{t('callForSignUp')}</TitleText>
          <LittleText>
            Already have an account?
            <Link to={urls.signInUrl}>{t('basicComponents:signIn')}</Link>
          </LittleText>
          <FormBase theme="row">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={1}>
                <CustomGridWithoutPadding item sm={11}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                    handleBlur={handleBlur}
                  />
                </CustomGridWithoutPadding>
              </Grid>
              <div>
                <ButtonSubmitSignUp type="submit" disabled={isLoading}>
                  {t('basicComponents:signUp')}
                </ButtonSubmitSignUp>
              </div>
            </form>
          </FormBase>
        </SignInUpCardColorful>
      </Grid>
      <Grid container justifyContent="center">
        <TextNote>{t('noCreditCard')}</TextNote>
      </Grid>
    </Grid>
  );
};
