import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DownloadIcon, EyeIcon } from '../../icons';
import { HeartIcon, ShareIcon } from '../../icons/FileIcons';
import { FeedBackWrapper } from './styled';

type ViewsLikesDownloadsSharesType = {
  likes?: number;
  shares?: number;
  downloads?: number;
  views?: number;
};

export const ViewsLikesDownloadsShares: React.FC<ViewsLikesDownloadsSharesType> = ({
  likes = 0,
  downloads = 0,
  views = 0,
  shares = 0,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <FeedBackWrapper>
      <div>
        <div>
          <EyeIcon />
          {' '}
          <Trans t={t} i18nKey="views">
            {{ views }}
            {' '}
            views
          </Trans>
        </div>
        <div>
          <HeartIcon />
          {' '}
          <Trans t={t} i18nKey="likes">
            {{ likes }}
            {' '}
            likes
          </Trans>
        </div>
      </div>
      <div>
        <div>
          <DownloadIcon />
          {' '}
          <Trans t={t} i18nKey="downloads">
            {{ downloads }}
            {' '}
            downloads
          </Trans>
        </div>
        <div>
          <ShareIcon />
          {' '}
          <Trans t={t} i18nKey="shares">
            {{ shares }}
            {' '}
            shares
          </Trans>
        </div>
      </div>
    </FeedBackWrapper>

  );
};
