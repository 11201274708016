import React from 'react';
import { HeaderContainer } from '../NewGalleryCreatorSecondStep/styled';
import { TitleText } from '../../../../shared/styles';
import { useStepContentAndTitle } from './useStepContentAndTitle';

type StepSwitcherType = {
  step: number;
};

export const StepSwitcher: React.FC<StepSwitcherType> = ({ step }) => {
  const { stepContent, text } = useStepContentAndTitle(step);

  return (
    <>
      <HeaderContainer>
        <TitleText>{text}</TitleText>
      </HeaderContainer>
      {stepContent}
    </>
  );
};
