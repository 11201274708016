import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '../../icons';
import {
  RoundItem,
  SimpleBlackText,
  TextBlackLarge,
  WhiteButtonWithoutShadow,
} from '../../styles';
import {
  ButtonWrapperWithOpacity,
  EyePositionWrapper,
  GalleryCardCover,
  GalleryCardWrapper,
} from './styled';

type GalleryCardType = {
  title: string;
  coverImageUrl?: string | null;
  buttonText?: string;
  handleClick?: () => void;
  wasWatchedByClient?: boolean;
  children?: ReactNode;
};

export const GalleryCard: React.FC<GalleryCardType> = ({
  title,
  coverImageUrl,
  buttonText,
  handleClick,
  wasWatchedByClient,
  children,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <GalleryCardWrapper>
      <TextBlackLarge>{title}</TextBlackLarge>
      <GalleryCardCover>
        {wasWatchedByClient && (
          <EyePositionWrapper>
            <RoundItem>
              <EyeIcon />
            </RoundItem>
          </EyePositionWrapper>
        )}
        <ButtonWrapperWithOpacity>
          {children || (
          <WhiteButtonWithoutShadow type="button" onClick={handleClick}>
            {buttonText}
          </WhiteButtonWithoutShadow>
          )}
        </ButtonWrapperWithOpacity>
        {coverImageUrl ? (
          <img src={coverImageUrl} alt={coverImageUrl} />
        ) : (
          <SimpleBlackText>{t('noCoverPhoto')}</SimpleBlackText>
        )}
      </GalleryCardCover>
    </GalleryCardWrapper>
  );
};
