import React from 'react';
import { MainLogo } from '../../shared/icons/MainLogo';
import { Header } from '../../styled';
import { SidebarWrapper } from './styled';
import { SidebarMenu } from './SidebarMenu';
import { UserCard } from './UserCard';

export const Sidebar: React.FC = () => (
  <SidebarWrapper>
    <Header>
      <MainLogo large={false} />
    </Header>
    <UserCard />
    <SidebarMenu />
  </SidebarWrapper>
);
