import { Grid } from '@material-ui/core';
import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, useFormikContext } from 'formik';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { FormGalleryWrapper, GridTopMargin, GridWrapper } from '../styled';
import { CommonCopyInput } from '../../../../shared/components/CommonCopyInput/CommonCopyInput';
import {
  LittleBlackText,
  SimpleBlackText,
  BlackTextWhiteButton,
} from '../../../../shared/styles';
import { TickIcon } from '../../../../shared/icons';
import { SentLinkWrapper, SharableLinkWrapper } from './styled';
import { Client, GalleryData } from '../../../../shared/types';
import { clientSchema } from '../../../../shared/validation/gallery/clientSchema';
import { clientInitialValues } from '../../../../../constants/initialValues/client';

export const NewGalleryCreatorFifthStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const [wasLinkSent, setWasLinkSent] = useState(false);
  const refWidth = useRef<HTMLInputElement>(null);

  const { values: globalValues } = useFormikContext<GalleryData>();

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = useFormik<Client>({
    validationSchema: clientSchema,
    initialValues: clientInitialValues,
    onSubmit: () => {
      resetForm();
      setWasLinkSent(true);
      setTimeout(() => setWasLinkSent(false), 5000);
    },
  });

  const handleSendLink = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <>
      <FormGalleryWrapper>
        <SharableLinkWrapper id="sharable-link-fifth-step">
          <Grid container item sm={9} spacing={1} alignItems="center" style={{ width: refWidth.current?.offsetWidth }}>
            <GridTopMargin item sm={12}>
              <SimpleBlackText>{t('fifthStep.sharableLink')}</SimpleBlackText>
            </GridTopMargin>
          </Grid>
          <div>
            <CommonCopyInput
              value={globalValues.galleryUrl}
              name="galleryUrl"
              readonly
            />
          </div>
        </SharableLinkWrapper>
      </FormGalleryWrapper>
      <FormGalleryWrapper>
        <GridWrapper container spacing={1}>
          <Grid container item sm={3} spacing={1} alignItems="flex-start" ref={refWidth}>
            <GridTopMargin item sm={12}>
              <SimpleBlackText>{t('fifthStep.sendClient')}</SimpleBlackText>
            </GridTopMargin>
          </Grid>
          <Grid container item sm={8} direction="column" wrap="nowrap">
            <Grid item sm={6}>
              <CommonInput
                error={errors.name}
                value={values.name}
                touched={touched.name}
                name="name"
                placeholder={t('fifthStep.name')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
              />
            </Grid>
            <Grid item sm={6}>
              <CommonInput
                error={errors.email}
                value={values.email}
                name="email"
                placeholder={t('fifthStep.emailAddress')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched.email}
                type="text"
              />
            </Grid>
            <Grid item sm={4}>
              <div style={{ position: 'relative' }}>
                <BlackTextWhiteButton type="submit" onClick={handleSendLink}>
                  {t('fifthStep.buttons.sendLink')}
                </BlackTextWhiteButton>
                {wasLinkSent && (
                  <SentLinkWrapper>
                    <TickIcon />
                    <LittleBlackText>{t('fifthStep.sentLink')}</LittleBlackText>
                  </SentLinkWrapper>
                )}
              </div>
            </Grid>
          </Grid>
        </GridWrapper>
      </FormGalleryWrapper>
    </>
  );
};
