import styled from '@emotion/styled';

export const BackgroundWithShapesWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;

  & svg {
    width: 100%;
    height: 100%;
  }
`;
