import React from 'react';
import { ButtonMoveToTopWrapper } from './styled';
import arrowToTop from '../../../../assets/image/arrowToTop.png';
import { RoundButton } from '../../styles';

export const ButtonMoveToTop: React.FC = () => {
  const handleClick = () => {
    document?.querySelector('#scrolled-container')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ButtonMoveToTopWrapper>
      <RoundButton
        large
        type="button"
        onClick={handleClick}
      >
        <img src={arrowToTop} alt="top" />
      </RoundButton>
    </ButtonMoveToTopWrapper>
  );
};
