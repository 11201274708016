import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { InitialCroppedArea } from '../../../types';

type CropperFix = React.Component;

const Cropped = Cropper as any as {
  new (): CropperFix;
};
const style = {
  mediaStyle: {
    maxWidth: 'none',
  },
};

type BaseCropperType = {
  size: string;
  videoOrImage: any;
  width: number;
  height: number;
  initialCroppedAreaPixels?: InitialCroppedArea;
  cropShape?: 'round'
};

export const BaseCropper: React.FC<BaseCropperType> = ({
  size,
  videoOrImage,
  width,
  height,
  initialCroppedAreaPixels,
  cropShape,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaData) => {
    console.log(size);
    window.localStorage.setItem(size, JSON.stringify(croppedAreaData));
  }, []);

  const handleChange = ({ x, y }: { x: number; y: number }) => {
    if (crop.x !== x || crop.y !== y) {
      setCrop({ x, y });
    }
  };

  const props = {
    crop,
    zoom,
    onZoomChange: setZoom,
    onCropChange: handleChange,
    cropShape: cropShape || 'rect',
    style,
    objectFit: 'vertical-cover',
    cropSize: {
      width,
      height,
    },
    onCropComplete,
    mediaProps: {
      autoPlay: false,
    },
    initialCroppedAreaPixels: initialCroppedAreaPixels || undefined,
  };

  return <Cropped {...props} {...videoOrImage} />;
};
