import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';

export const FeedBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 16px;
  line-height: 140%;

  & > div {
    display: flex;
    flex-direction: column;
    white-space: pre;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
    }
  }

  & svg {
    color: ${variables.colorPink};
    margin-right: 8px;
  }
`;
