import { Grid } from '@material-ui/core';
import React from 'react';
import { PreviewFirstHeaderWrapper } from '../../CreatorAccount/Tabs/NewGalleryCreator/PreviewGalleries/PreviewFirst/styled';
import { GalleryCoverType } from '../../shared/types';
import { MobileWrapper } from '../MobileWrapper';
import { useGalleryContent } from '../useGalleryContent';
import {
  FirstMediumTextMobile,
  FirstTitleMobile,
  ImageContainerFirstMobile,
  MobileWrapperFirst,
} from './styled';

export const GalleryDemoFirstMobile: React.FC<GalleryCoverType> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'first',
  });
  return (
    <MobileWrapper>
      <MobileWrapperFirst>
        <Grid
          container
          spacing={2}
        >
          <Grid item sm={12}>
            <PreviewFirstHeaderWrapper>
              <FirstTitleMobile>{data.name}</FirstTitleMobile>
            </PreviewFirstHeaderWrapper>
          </Grid>
          <Grid item sm={12}>
            <ImageContainerFirstMobile>
              {data.file}
              {/* {data.date} */}
            </ImageContainerFirstMobile>
          </Grid>
          <Grid item sm={12}>
            <FirstMediumTextMobile>{data.message}</FirstMediumTextMobile>
          </Grid>
          <Grid item sm={12}>
            <FirstMediumTextMobile>{data.userName}</FirstMediumTextMobile>
          </Grid>
        </Grid>
      </MobileWrapperFirst>
    </MobileWrapper>
  );
};
