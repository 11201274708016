import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as urls from '../../../../../constants';
import { BaseSecondaryButton } from '../../../../shared/styles';
import { SuccessNotification } from '../../../../shared/components/SuccessNotification/SuccessNotification';

type SaveAndCloseType = {
  disabled?: boolean;
}

export const SaveAndClose: React.FC<SaveAndCloseType> = ({ disabled }) => {
  const { t } = useTranslation('basicComponents');
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const history = useHistory();

  const handleSaveAndClick = () => {
    setIsNotificationOpened(true);
    setTimeout(() => {
      history.push(`${urls.rootUrl}`);
    }, 530);
  };

  return (
    <>
      <BaseSecondaryButton type="button" onClick={handleSaveAndClick} disabled={disabled}>
        {t('buttons.saveClose')}
      </BaseSecondaryButton>
      <SuccessNotification
        isOpen={isNotificationOpened}
        text={t('changesSaved')}
        setIsOpen={setIsNotificationOpened}
      />
    </>
  );
};
