import * as variables from '../../constants/styleVariables';

export const getProgressBarStyle = (step: number, active?: boolean) => {
  switch (step) {
    case 1:
      return {
        width: '0%',
        background: 'transparent',
        backgroundDot: variables.colorBlue,
        left: '1.7%',
      };
    case 2:
      return {
        width: '24%',
        background: 'linear-gradient(270deg, #ACA4CE 0%, #78C0EA 94.78%)',
        backgroundDot: active ? '#ACA4CE' : variables.colorGreyLight,
        left: '24%',
      };
    case 3:
      return {
        width: '48%',
        background:
            'linear-gradient(270deg, #F180B2 0%, #ACA4CE 48.65%, #78C0EA 100%)',
        backgroundDot: active ? variables.colorPink : variables.colorGreyLight,
        left: '48%',
      };
    case 4:
      return {
        width: '73%',
        background:
            'linear-gradient(270deg, #E48D7D 0%, #F180B2 32.77%, #ACA4CE 65.58%, #78C0EA 100%)',
        backgroundDot: active ? '#E48D7D' : variables.colorGreyLight,
        left: '73%',
      };
    case 5:
      return {
        width: '99%',
        background:
            'linear-gradient(270deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%)',
        backgroundDot: active ? '#E6934F' : variables.colorGreyLight,
        left: '98.7%',
      };
    default:
      return {
        width: '0%',
        background: 'transparent',
        backgroundDot: variables.colorGreyLight,
        left: '3%',
      };
  }
};
