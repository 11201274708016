import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const PreviewFirstContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PreviewFirstRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PreviewFirstHeaderWrapper = styled(PreviewFirstRowWrapper)`
  align-items: flex-end;
  margin-bottom: 3px;
`;

export const PreviewFirstTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textBlack};

  & h3 {
    margin: 0;
  }
`;

export const PreviewFirstMediumText = styled.div`
  font-size: 4px;
  line-height: 6px;
  color: ${variables.textBlack};
  text-align: start;
`;

export const PreviewFirstImageContainer = styled.div`
  width: 201px;
  height: 147px;
  min-width: 201px;
  box-sizing: border-box;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;
  margin-left: 8px;
  overflow: hidden;

  & span {
    width: 40px;
    position: absolute;
    font-weight: normal;
    transform: rotate(-90deg);
    font-size: 4.83061px;
    line-height: 5px;
    left: -26px;
    bottom: 13px;
  }
`;
