import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as urls from '../../constants/urls';
import { CommonInput } from '../shared/components/CommonInput';
import {
  FormBase,
  GreyLink,
  MediumBlackTextAndLink,
  PrimaryWhiteButtonBlueText,
  SignInUpCard,
  TitleSimple,
  CustomGrid,
  ErrorWrapper,
} from '../shared/styles';
import { SignInSchema } from '../shared/validation/signInUpSchemas';
import { SignIn } from '../shared/types';
import { UserStateContext } from '../../context/UserStateContext';
import { fetchSignInCreator } from '../../Redux/userCreator/signIn/signInCreatorThunk';
import { signInInitialValues } from '../../constants/initialValues/signIn';
import { useTypedSelector } from '../shared/hooks/useTypedSelector';
import { ErrorMessage } from '../shared/components/styled';

export const SignInCard: React.FC = () => {
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const { isSignIn } = useContext(UserStateContext);

  const dispatch = useDispatch();
  const signInError = useTypedSelector((state) => state.signInCreator.error);
  const isLoading = useTypedSelector((state) => state.signInCreator.loading);

  const onSubmit = (data: SignIn) => {
    console.log(data);
    dispatch(fetchSignInCreator(data));
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: signInInitialValues,
    validationSchema: SignInSchema,
    onSubmit: (data: SignIn) => onSubmit(data),
  });

  if (isSignIn) {
    return <Redirect to={urls.rootUrl} />;
  }

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCard theme="signin">
          <TitleSimple>{t('basicComponents:signIn')}</TitleSimple>
          <MediumBlackTextAndLink>
            {t('dontHaveAccount')}
            <Link to={urls.signUpInitialUrl}>
              {t('basicComponents:signUp')}
            </Link>
          </MediumBlackTextAndLink>
          <FormBase theme="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={4} justifyContent="center">
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <CommonInput
                    error={errors.password}
                    value={values.password}
                    name="password"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="password"
                    placeholder="Password"
                    touched={touched.password}
                  />
                </CustomGrid>
                <CustomGrid item sm={9}>
                  <ErrorWrapper>
                    {signInError && <ErrorMessage>{signInError}</ErrorMessage>}
                  </ErrorWrapper>
                </CustomGrid>
              </Grid>
              <PrimaryWhiteButtonBlueText type="submit" theme="307" disabled={isLoading}>
                {t('basicComponents:signIn')}
              </PrimaryWhiteButtonBlueText>
            </form>
          </FormBase>
          <GreyLink>
            <Link to={urls.resetUrl}>{t('forgotPassword')}</Link>
          </GreyLink>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
