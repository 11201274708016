import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  BaseSecondaryButton,
  SimpleBlackText,
} from '../../../../../shared/styles';
import noPhoto from '../../../../../../assets/image/noPhoto.png';
import { ProfilePictureWrapper } from './styled';
import { ChangePhotoWindow } from './ChangePhotoWindow';
import { getURLByFile } from '../../../../../../utils';
import { CustomFileType } from '../../../../../shared/types';

export const ProfilePicture: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isModalWindowOpen, setIsModalWindowOpen] = useState(false);

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik<{
    originalPhoto: { file: CustomFileType };
    croppedPhoto: { file: CustomFileType | null };
  }>({
    // validationSchema: clientSchema,
    initialValues: {
      originalPhoto: { file: { name: 'profile', url: noPhoto, type: 'image' } },
      croppedPhoto: { file: null },
    },
    onSubmit: (data) => onSubmit(data),
  });

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const newImage = event.currentTarget.files || [];
      setFieldValue('croppedPhoto', {
        file: null,
      });
      setFieldValue('originalPhoto', {
        file: {
          name: 'profile',
          url: getURLByFile(newImage[0]),
          type: 'image',
        },
      });
    }
  };

  return (
    <div>
      <SimpleBlackText>{t('profilePicture')}</SimpleBlackText>
      <div>
        <ProfilePictureWrapper
          imageUrl={values?.croppedPhoto?.file?.url || values?.originalPhoto?.file?.url}
          hasFile={!!values?.originalPhoto?.file?.url}
          hasError={!!(errors.originalPhoto && touched.originalPhoto)}
        >
          <input
            name="originalPhoto"
            type="file"
            onChange={handleUploadFile}
            accept="image"
          />
          <div />
        </ProfilePictureWrapper>
      </div>
      <BaseSecondaryButton
        type="button"
        onClick={() => setIsModalWindowOpen(true)}
        disabled={!!(!values.originalPhoto || (errors.originalPhoto && touched.originalPhoto))}
      >
        {t('changeProfilePicture')}
      </BaseSecondaryButton>
      <ChangePhotoWindow
        setFieldValue={setFieldValue}
        isOpen={isModalWindowOpen}
        setIsOpen={setIsModalWindowOpen}
        image={values?.originalPhoto.file}
      />
    </div>
  );
};
