import { useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import { OnboardingContext } from '../../../../../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../../../../../context/OnboardingStepIndexContext';
import { GalleryData } from '../../../../shared/types';
import { FormGalleryWrapper } from '../styled';
import { SectionThirdStep } from './SectionThirdStep';

export const NewGalleryCreatorThirdStep: React.FC = () => {
  const { isStudy } = useContext(OnboardingContext);
  const { setStepIndex } = useContext(OnboardingStepIndexContext);

  useEffect(() => {
    if (isStudy) {
      setStepIndex((prev: number) => prev + 1);
    }
  }, []);

  const { values } = useFormikContext<GalleryData>();

  return (
    <FormGalleryWrapper>
      {values?.sections?.map((item) => (
        <SectionThirdStep key={item.id} section={item} />
      ))}
    </FormGalleryWrapper>
  );
};
