import React, { ChangeEvent, useMemo } from 'react';
import { MiniFileCardWrapper } from './styled';
import { CommonCheckbox } from '../CommonCheckbox';
import { CustomPositionCheckbox } from '../styled';
import { getFileInContainer } from '../../../../utils/galleryCreator/getFileInContainer';
import { CustomFileType } from '../../types';

type MiniFileCardType = {
  file: CustomFileType | null;
  handleCheck: (e: ChangeEvent<HTMLInputElement>, file: CustomFileType) => void;
  isChecked: boolean;
  large?: boolean;
  id?: string;
  isSettingsApplied?: boolean;
};

export const MiniFileCard: React.FC<MiniFileCardType> = ({
  file,
  handleCheck,
  isChecked,
  large,
  id,
  isSettingsApplied,
}) => {
  const content = useMemo(
    () => getFileInContainer(file),
    [file],
  );

  return (
    <MiniFileCardWrapper hasBorders={isSettingsApplied} large={large}>
      {file && (
        <>
          <CustomPositionCheckbox>
            <CommonCheckbox
              id={id}
              isChecked={isChecked}
              handleChange={(e) => handleCheck(e, file || file)}
              simple
              name=""
            />
          </CustomPositionCheckbox>
          {content}
        </>
      )}
    </MiniFileCardWrapper>
  );
};
