/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export const baseURL = 'https://delivrable.com/api/';

export const instance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json' },
  baseURL,
});

instance.interceptors.response.use((config) => config, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._retry) {
    originalRequest._retry = true;
    try {
      const response = await axios.get(`${baseURL}auth/refresh`, { withCredentials: true });
      return instance.request(originalRequest);
    } catch (e) {
      console.log('Unauthorized');
    }
  }
  throw error;
});
