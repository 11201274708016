import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../shared/hooks/useDialog';
import { DeleteIcon } from '../../../../shared/icons';
import { FileCardWrapper, CardContainer } from './styled';
import { getFileInContainer } from '../../../../../utils/galleryCreator/getFileInContainer';
import { CustomFileType } from '../../../../shared/types';

type FileCardType = {
  file: CustomFileType | null;
  handleDeleteFile: (name: string) => void;
  id: string;
};

export const FileCard: React.FC<FileCardType> = ({
  file,
  handleDeleteFile,
  id,
}) => {
  const { t } = useTranslation('createNewGallery');
  const imageOrVideo = useMemo(() => getFileInContainer(file), [file]);

  const { setIsOpen, dialogWindow } = useDialog(
    id,
    handleDeleteFile,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  return (
    <>
      <CardContainer item sm={3}>
        <FileCardWrapper>
          {imageOrVideo}
          <button type="button" onClick={() => setIsOpen(true)}>
            <DeleteIcon />
          </button>
        </FileCardWrapper>
      </CardContainer>
      {dialogWindow}
    </>
  );
};
