import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FifthMediumTextMessageTablet,
  FifthMediumTextTablet,
  FifthTitleTablet,
  GridWithBorderL,
  GridWithBorderR,
  ImageContainerFifthTablet,
} from './styled';
import { GalleryCoverType } from '../../shared/types';
import { TabletWrapper } from '../TabletWrapper';
import { useGalleryContent } from '../useGalleryContent';

export const GalleryDemoFifthTablet: React.FC<GalleryCoverType> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'tablet',
    galleryNumber: 'fifth',
  });
  return (
    <TabletWrapper>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ padding: '0 8px 0', height: '100%' }}
      >
        <Grid spacing={2} container item sm={12} justifyContent="center" alignItems="flex-start">
          <Grid container item sm={12} justifyContent="space-between" alignItems="center">
            <GridWithBorderR item sm={3} container alignItems="center">
              <FifthMediumTextTablet>{data.date}</FifthMediumTextTablet>
            </GridWithBorderR>
            <Grid item sm={6} container alignItems="center">
              <FifthTitleTablet>{data.name}</FifthTitleTablet>
            </Grid>
            <GridWithBorderL container item sm={3} justifyContent="flex-end" alignItems="center">
              <FifthMediumTextTablet>{data.userName}</FifthMediumTextTablet>
            </GridWithBorderL>
          </Grid>
          <Grid container item sm={12} justifyContent="center" alignItems="flex-start">
            <ImageContainerFifthTablet>
              {data.file}
            </ImageContainerFifthTablet>
          </Grid>
          <Grid item sm={8}>
            <FifthMediumTextMessageTablet>{data.message}</FifthMediumTextMessageTablet>
          </Grid>
        </Grid>
      </Grid>
    </TabletWrapper>
  );
};
