import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TabContentWrapper,
  TabWrapper,
} from '../../../shared/styles';
import {
  GridMgTop,
} from './styled';
import { ViewAllLink } from '../../../shared/components/ViewAllLink/ViewAllLink';
import * as url from '../../../../constants/urls';
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector';
import { fetchGetGalleries } from '../../../../Redux/gallery/galleryThunk';
import { GalleryCardCustom } from './GalleryCardCustom';
import { SalesPart } from './SalesPart';
import { OnboardingContext } from '../../../../context/OnboardingContext';
import { fakeGalleries } from '../../../../constants/walkThroughCards/fakeGalleryData';
import { Gallery } from '../../../shared/types';

export const GalleriesList: React.FC = () => {
  const { isStudy } = useContext(OnboardingContext);
  const dispatch = useDispatch();
  const realGalleries = useTypedSelector((state) => state.galleries.galleries);
  const galleries = isStudy ? fakeGalleries : realGalleries;

  useEffect(() => {
    dispatch(fetchGetGalleries());
  }, []);

  const activeGalleries = useMemo(
    () => galleries?.filter((item) => item.type === 'active'),
    [galleries],
  );
  const draftGalleries = useMemo(
    () => galleries?.filter((item) => item.type === 'draft'),
    [galleries],
  );

  return (
    <GridMgTop container spacing={4}>
      <GalleryCardCustom
        gallery={activeGalleries?.length ? activeGalleries[0] : null}
        galleriesNumber={activeGalleries?.length || 0}
        type="Active"
        toUrl={url.activeGalleriesUrl}
      />
      <GalleryCardCustom
        gallery={draftGalleries?.length ? draftGalleries[0] : null}
        galleriesNumber={draftGalleries?.length || 0}
        type="Draft"
        toUrl={url.draftGalleriesUrl}
      />
    </GridMgTop>
  );
};
