import { v4 as uuidv4 } from 'uuid';
import { GalleryDataFormType, GalleryFileInformation } from '../../containers/shared/types';

export const galleryInitialValues = (id: string): GalleryDataFormType => ({
  step: 1,
  id,
  galleryName: '',
  coverFile: {
    original: { file: null },
    mobile: { file: null },
    tablet: { file: null },
    desktop: { file: null },
  },
  date: null,
  message: '',
  galleryUrl: '',
  galleryDesign: null,
  sections: [
    {
      id: uuidv4(),
      title: 'Section 1',
      files: [],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    },
  ],
  hasRegistrationEmail: false,
  registrationEmail: [],
  password: '',
  sharing: false,
  assist: false,
  hasExpiryDate: false,
  expiryDate: null,
  autoAlert: false,
  branding: false,
  testimonialCall: false,
});

export const galleryFileInformation: GalleryFileInformation = {
  description: '',
  price: '',
  instantlyDownloadable: false,
  watermark: false,
  allowCommenting: false,
  fullWidth: false,
};
