import React, { ReactElement } from 'react';
import { StarIcon, DiamondIcon } from '../../../../shared/icons';
import { TextBlackLarge, TextPinkLarge, GradientColorText } from '../../../../shared/styles';
import { PlanTypes } from '../../../../shared/types';
import { TextWithIconWrapper } from './styled';

export const getPlanTitle = (planType: PlanTypes): ReactElement => {
  switch (planType) {
    case 'Free':
      return <TextBlackLarge fontWeight={500}>{planType}</TextBlackLarge>;
    case 'Premium':
      return (
        <TextWithIconWrapper>
          <StarIcon />
          <TextPinkLarge fontWeight={500}>
            {planType}
          </TextPinkLarge>
        </TextWithIconWrapper>
      );
    case 'Premium +':
      return (
        <TextWithIconWrapper>
          <DiamondIcon />
          <GradientColorText fontWeight={500}>
            {planType}
          </GradientColorText>
        </TextWithIconWrapper>
      );
    default:
      return <TextBlackLarge fontWeight={500}>{planType}</TextBlackLarge>;
  }
};
