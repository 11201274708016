import React, { ChangeEvent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { GalleryData, GallerySection } from '../../../../shared/types';
import { CommonCheckbox } from '../../../../shared/components/CommonCheckbox';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { getSectionErrorsAndTouched } from '../../../../../utils/galleryCreator/getErrorsAndTouched';
import { FormLimitFreeFilesGrid, LabelCheckboxWrapper } from './styled';
import { useResetField } from '../../../../shared/hooks/useResetField';

type FormLimitFreeFilesType = {
  section: GallerySection;
};

export const FormLimitFreeFiles: React.FC<FormLimitFreeFilesType> = ({
  section,
}) => {
  const { t } = useTranslation('createNewGallery');
  const resetField = useResetField();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = useFormikContext<GalleryData>();

  const sectionIndex = useMemo(
    () => values.sections.findIndex((item) => item.id === section.id),
    [values.sections],
  );

  const sectionErrorsAndTouched = useMemo(
    () => getSectionErrorsAndTouched(errors, touched, sectionIndex),
    [errors, touched, sectionIndex],
  );

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (section.numberFreeFiles) {
      resetField(`sections[${sectionIndex}].numberFreeFiles`);
    }
  };

  return (
    <FormLimitFreeFilesGrid container direction="row" alignItems="center" id="limitFreeFilesThirdStep">
      <CommonCheckbox
        isChecked={section.hasFreeFiles}
        name={`sections[${sectionIndex}].hasFreeFiles`}
        handleChange={handleCheck}
      />
      <LabelCheckboxWrapper>
        <Trans t={t} i18nKey="thirdStep.checkbox">
          <div>Make all files from this section free until</div>
          {' '}
          <CommonInput
            value={section.numberFreeFiles || ''}
            name={`sections[${sectionIndex}].numberFreeFiles`}
            handleChange={handleChange}
            handleBlur={handleBlur}
            type="text"
            direction="row"
            width={80}
            error={sectionErrorsAndTouched.error?.numberFreeFiles}
            touched={sectionErrorsAndTouched.wasTouched?.numberFreeFiles}
            disabled={!section.hasFreeFiles}
          />
          {' '}
          <div>files have been added to download cart</div>
        </Trans>
      </LabelCheckboxWrapper>
    </FormLimitFreeFilesGrid>
  );
};
