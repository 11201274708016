import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { SimpleBlackText } from '../../../shared/styles';
import { GalleryCard } from '../../../shared/components/GalleryCard/GalleryCard';
import { Dashboard } from './Dashboard/Dashboard';
import { Gallery } from '../../../shared/types';

type ActiveGalleriesCardsType = {
  galleries: Gallery[];
}

export const ActiveGalleriesCards: React.FC<ActiveGalleriesCardsType> = ({ galleries }) => {
  const { t } = useTranslation('activeGalleries');

  return (
    <>
      {galleries
        ? (
          galleries.map((item) => (
            <Grid
              key={item.id}
              item
              sm={6}
            >
              <GalleryCard
                title={item.galleryName}
                coverImageUrl={item.coverFile}
              // buttonText={t('enterDashboard')}
              // handleClick={openDashboard}
                wasWatchedByClient={item.wasWatched}
              >
                <Dashboard
                  gallery={item}
                />
              </GalleryCard>
            </Grid>
          ))
        )
        : <SimpleBlackText>{t('noActiveGalleries')}</SimpleBlackText>}
    </>
  );
};
