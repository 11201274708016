import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BillSwitchWrapper,
} from './styled';
import { PlanTypes } from '../../../../../shared/types';
import {
  MediumBlackText,
} from '../../../../../shared/styles';
import { CommonSwitch } from '../../../../../shared/components/CommonSwitch/CommonSwitch';

type PlanCardSwitchType = {
  handleSwitch: () => void;
  isAnnually: boolean;
  planType: PlanTypes;
};

export const PlanCardSwitch: React.FC<PlanCardSwitchType> = ({
  handleSwitch,
  isAnnually,
  planType,
}) => {
  const { t } = useTranslation('settings');

  if (planType === 'Free') return null;

  return (
    <BillSwitchWrapper>
      <MediumBlackText style={{ whiteSpace: 'pre' }}>
        {t('billMonthly')}
      </MediumBlackText>
      <div style={{ width: 'fit-content' }}>
        <CommonSwitch
          isChecked={isAnnually}
          handleChange={handleSwitch}
          name={planType}
          withoutMargins
          littleText
          blueButton
        />
      </div>
      <MediumBlackText style={{ whiteSpace: 'pre' }}>
        {t('billAnnually')}
      </MediumBlackText>
    </BillSwitchWrapper>
  );
};
