import { Grid } from '@material-ui/core';
import React from 'react';
import { GalleryCoverType } from '../../shared/types';
import { MobileWrapper } from '../MobileWrapper';
import { useGalleryContent } from '../useGalleryContent';
import {
  FifthMediumTextMessageMobile,
  FifthMediumTextMobile,
  FifthTitleMobile,
  GridWithBorderR,
  ImageContainerFifthMobile,
} from './styled';

export const GalleryDemoFifthMobile: React.FC<GalleryCoverType> = ({
  file,
  name,
  date,
  userName,
  message,
  withCropper,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
    withCropper,
    size: 'mobile',
    galleryNumber: 'fifth',
  });
  return (
    <MobileWrapper>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ padding: '0 0 0', height: '100%' }}
      >
        <Grid spacing={2} container item sm={12} justifyContent="center" alignItems="flex-start">
          <Grid item sm={12}>
            <FifthTitleMobile>{data.name}</FifthTitleMobile>
          </Grid>
          <Grid item sm={12}>
            <ImageContainerFifthMobile>
              {data.file}
            </ImageContainerFifthMobile>
          </Grid>
          <Grid item sm={12}>
            <FifthMediumTextMessageMobile>{data.message}</FifthMediumTextMessageMobile>
          </Grid>
          <Grid container item sm={12}>
            <GridWithBorderR item sm={5} style={{ border: '1' }} container alignItems="center">
              <FifthMediumTextMobile>{data.date}</FifthMediumTextMobile>
            </GridWithBorderR>
            <Grid container item sm={7} justifyContent="flex-end" alignItems="center">
              <FifthMediumTextMobile>{data.userName}</FifthMediumTextMobile>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobileWrapper>
  );
};
