import React from 'react';
import { LittleBlackText } from '../../../../shared/styles';
import { getFileInContainer } from '../../../../../utils/galleryCreator/getFileInContainer';
import { CustomFileType } from '../../../../shared/types';
import {
  SettingsGalleryCardWrapper,
  SettingsGalleryCardHeader,
  SettingsGalleryCardFile,
} from './styled';

type SettingsGalleryCardType = {
  file: CustomFileType;
  name: string;
  storage: number;
};

export const SettingsGalleryCard: React.FC<SettingsGalleryCardType> = ({
  file,
  name,
  storage,
}) => (
  <SettingsGalleryCardWrapper>
    <SettingsGalleryCardHeader>
      <div>
        <LittleBlackText>{name}</LittleBlackText>
      </div>
      <div>
        <LittleBlackText fontWeight={500}>
          {storage}
          MB
        </LittleBlackText>
      </div>
    </SettingsGalleryCardHeader>
    <SettingsGalleryCardFile>
      {getFileInContainer(file)}
    </SettingsGalleryCardFile>
  </SettingsGalleryCardWrapper>
);
