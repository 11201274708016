import React from 'react';

export const ShapeFirst: React.FC = () => (
  <svg width="645" height="607" viewBox="0 0 645 607" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1620_16110)">
      <path fillRule="evenodd" clipRule="evenodd" d="M630.356 276.285C647.61 351.594 664.83 439.47 619.647 502.14C574.228 565.137 483.708 567.818 407.925 584.799C336.238 600.862 264.142 617.164 193.31 597.672C117.767 576.884 27.7642 546.447 0.982544 472.815C-25.9921 398.653 59.5546 330.152 67.4467 251.631C74.5807 180.653 0.787781 100.883 44.0654 44.1745C87.1289 -12.2533 178.8 19.788 249.469 13.1206C305.241 7.85858 361.041 -10.6614 413.372 9.33141C465.505 29.2483 497.17 77.8949 532.37 121.201C571.906 169.843 616.357 215.186 630.356 276.285Z" fill="#FCFCFC" fillOpacity="0.09" />
    </g>
    <defs>
      <filter id="filter0_b_1620_16110" x="-12.2578" y="-7.74121" width="668.734" height="622.312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="4" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1620_16110" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1620_16110" result="shape" />
      </filter>
    </defs>
  </svg>
);
export const ShapeSecond: React.FC = () => (
  <svg width="645" height="639" viewBox="0 0 645 639" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M656.28 560.326C588.241 636.262 477.412 636.597 380.629 660.285C298.294 680.437 218.078 699.726 135.724 687.734C44.0928 674.392 -63.7404 665.604 -114.267 588.222C-164.51 511.274 -125.531 405.466 -109.876 311.593C-97.0109 234.451 -65.4245 166.293 -31.9938 94.8928C9.3271 6.641 23.4967 -107.377 108.053 -153.852C193.838 -201.003 300.09 -173.373 385.98 -134.842C464.168 -99.7657 501.061 -16.2481 557.983 47.9931C621.235 119.378 719.178 166.161 737.553 261.936C757.054 363.575 727.126 481.257 656.28 560.326Z" fill="#FCFCFC" fillOpacity="0.07" />
  </svg>
);
