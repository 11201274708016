import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TickIcon } from '../shared/icons';
import {
  SignInUpCardCenter,
  TextWithIcon,
  TitleSimple,
  PrimaryWhiteButtonBlueText,
  ErrorWrapper,
} from '../shared/styles';
import { TextInstructions } from './styled';
import { useTypedSelector } from '../shared/hooks/useTypedSelector';
import { fetchSignUpInitialResendCreator } from '../../Redux/userCreator/signUp/signUpCreatorThunk';
import { ErrorMessage } from '../shared/components/styled';

export const EmailWasConfirmedCard: React.FC = () => {
  const { t } = useTranslation(['signUp', 'basicComponents']);
  const dispatch = useDispatch();
  const isLoading = useTypedSelector((state) => state.signUpCreator.loading);
  const signUpErrors = useTypedSelector((state) => state.signUpCreator.error);

  const handleResendEmail = () => {
    const email = window.localStorage.getItem('email');
    if (email) {
      dispatch(fetchSignUpInitialResendCreator(email));
    }
  };

  const emailError = useMemo(() => {
    if (signUpErrors?.errorType === 'other' && signUpErrors.errors.length > 0) {
      return signUpErrors.errors[0].message;
    }
    return null;
  }, [signUpErrors]);

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCardCenter theme="thanks">
          <TitleSimple>{t('basicComponents:signUp')}</TitleSimple>
          <TextWithIcon>
            <TickIcon />
            <span>{t('thanks')}</span>
          </TextWithIcon>
          <TextInstructions>
            {t('sentEmailConfirmation')}
          </TextInstructions>
          <ErrorWrapper>
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          </ErrorWrapper>
          <PrimaryWhiteButtonBlueText disabled={isLoading} type="button" onClick={handleResendEmail}>
            {t('signUp:resendEmail')}
          </PrimaryWhiteButtonBlueText>
        </SignInUpCardCenter>
      </Grid>
    </Grid>
  );
};
