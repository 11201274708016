import { FormikErrors, FormikTouched, getIn } from 'formik';
import {
  GalleryData,
  GalleryIdea,
  GallerySection,
} from '../../containers/shared/types';

interface ReturnErrorsAndTouched <T>{
  error: FormikErrors<T> | null;
  wasTouched: FormikTouched<T> | null;
}

export const getSectionErrorsAndTouched = (
  errors: FormikErrors<GalleryData>,
  touched: FormikTouched<GalleryData>,
  indexSection: number,
): ReturnErrorsAndTouched<GallerySection> => {
  const sectionErrors = getIn(errors, 'sections');
  const sectionTouched = getIn(touched, 'sections');
  return {
    error: sectionErrors ? sectionErrors[indexSection] : null,
    wasTouched: sectionTouched ? sectionTouched[indexSection] : null,
  };
};

export const getIdeasErrorsAndTouched = (
  errors: FormikErrors<GalleryData>,
  touched: FormikTouched<GalleryData>,
  indexSection: number,
  indexIdea: number,
): ReturnErrorsAndTouched<GalleryIdea> => {
  const sectionErrorsAndTouched = getSectionErrorsAndTouched(
    errors,
    touched,
    indexSection,
  );
  const errorsIdeas = sectionErrorsAndTouched.error?.ideas
    ? (sectionErrorsAndTouched.error?.ideas[indexIdea] as FormikErrors<GalleryIdea>)
    : null;
  const touchedIdeas = sectionErrorsAndTouched.wasTouched?.ideas
    ? (sectionErrorsAndTouched.wasTouched?.ideas[indexIdea] as FormikTouched<GalleryIdea>)
    : null;
  return {
    error: errorsIdeas,
    wasTouched: touchedIdeas,
  };
};
