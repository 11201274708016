import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TabContentWrapper,
  TabWrapper,
} from '../../../shared/styles';
import {
  GridMgTop,
} from './styled';
import { ViewAllLink } from '../../../shared/components/ViewAllLink/ViewAllLink';
import * as url from '../../../../constants/urls';
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector';
import { fetchGetGalleries } from '../../../../Redux/gallery/galleryThunk';
import { GalleryCardCustom } from './GalleryCardCustom';
import { SalesPart } from './SalesPart';
import { OnboardingContext } from '../../../../context/OnboardingContext';
import { fakeGalleries } from '../../../../constants/walkThroughCards/fakeGalleryData';
import { Gallery } from '../../../shared/types';
import { GalleriesList } from './GalleriesList';

export const MainTab: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  // const dispatch = useDispatch();
  // const galleries = useTypedSelector((state) => state.galleries.galleries);

  // useEffect(() => {
  //   dispatch(fetchGetGalleries());
  // }, []);

  // const activeGalleries = useMemo(
  //   () => galleries?.filter((item) => item.type === 'active'),
  //   [galleries],
  // );
  // const draftGalleries = useMemo(
  //   () => galleries?.filter((item) => item.type === 'draft'),
  //   [galleries],
  // );

  return (
    <TabWrapper>
      <TabContentWrapper>
        <SalesPart />
        <ViewAllLink
          label={t('basicComponents:viewStatistics')}
          to={url.salesUrl}
        />
      </TabContentWrapper>
      <GalleriesList />
    </TabWrapper>
  );
};
