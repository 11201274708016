import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleText } from '../../../../shared/styles';
import { ChartWrapper, SalesMonthContainer } from '../styled';
import { Chart } from './Chart';

type SalesChartType = {
  chartHeight?: number;
  children: ReactNode;
  dataForChart: any;
};

export const SalesChart: React.FC<SalesChartType> = ({
  chartHeight,
  children,
  dataForChart,
}) => {
  const { t } = useTranslation('sales');

  return (
    <ChartWrapper>
      <SalesMonthContainer>
        <TitleText fontWeight={600}>{t('yourSales')}</TitleText>
        {children}
      </SalesMonthContainer>
      <Chart height={chartHeight} data={dataForChart} />
    </ChartWrapper>
  );
};
