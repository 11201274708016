import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WidthWrapper } from '../styled';
import { CommonInput } from '../../shared/components/CommonInput';
import { FormikCustomProps, SetFieldValue, SignUpInProcess } from '../../shared/types';
import { CommonSelect } from '../../shared/components/CommonSelect/CommonSelect';
import { CommonCheckbox } from '../../shared/components/CommonCheckbox';
import {
  BleakText,
  ButtonWithoutShadow,
  CustomGrid,
  FlexStartWrapper,
  PrimaryWhiteButton,
} from '../../shared/styles';
import { useTypedSelector } from '../../shared/hooks/useTypedSelector';
import { useGetWhatDoYouDoMenuItems } from './useGetWhatDoYouDoMenuItems';
import { CustomDatePicker } from '../../shared/components/CustomDatePicker/CustomDatePicker';
import { countryValue, doValue } from '../../../constants/initialValues/signUp';
import { createCountriesList } from '../../../constants/selectorMenuItems';
import { useGetSelectionItems } from '../../shared/hooks/useGetSelectionItems';

type SignUpSecondStepProps = {
  onBack: () => void;
  setFieldValue: SetFieldValue;
} & FormikCustomProps<SignUpInProcess>;

export const SignUpSecondStep: React.FC<SignUpSecondStepProps> = ({
  onBack,
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
}) => {
  const { t } = useTranslation(['signUp, basicComponents']);
  const getSelectionItems = useGetSelectionItems();

  const isLoading = useTypedSelector((state) => state.signUpCreator.loading);

  const menuItemsCounties = useMemo(
    () => getSelectionItems(createCountriesList()),
    [],
  );
  const menuItemsWhatDoYouDo = useGetWhatDoYouDoMenuItems();

  return (
    <>
      <CustomGrid item sm={9}>
        <CustomDatePicker
          error={errors.dob}
          data={values.dob}
          name="dob"
          limits={false}
          placeholder={t('signUp:dobPlaceholder')}
          touched={touched.dob}
          setFieldValue={setFieldValue}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonSelect
          error={errors.country}
          value={values.country}
          name="country"
          placeholder={countryValue}
          handleChange={handleChange}
          touched={touched.country}
          menuItems={menuItemsCounties}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonSelect
          error={errors.occupation}
          value={values.occupation}
          name="occupation"
          placeholder={doValue}
          handleChange={handleChange}
          touched={touched.occupation}
          menuItems={menuItemsWhatDoYouDo}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.promoCode}
          value={values.promoCode}
          name="promoCode"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="text"
          placeholder="Promo code"
          touched={touched.promoCode}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <WidthWrapper>
          <CommonCheckbox
            isChecked={values.subscribed}
            name="subscribed"
            handleChange={handleChange}
            text={t('signUp:checkbox')}
          />
        </WidthWrapper>
      </CustomGrid>
      <FlexStartWrapper>
        <ButtonWithoutShadow onClick={onBack} disabled={isLoading}>
          {t('basicComponents:buttons.back')}
        </ButtonWithoutShadow>
        <PrimaryWhiteButton type="submit" disabled={isLoading}>
          {t('signUp:completeRegistration')}
        </PrimaryWhiteButton>
      </FlexStartWrapper>
      <BleakText>
        <span>{t('signUp:privacyPolicy')}</span>
      </BleakText>
    </>
  );
};
