import React, { CSSProperties, ReactElement } from 'react';
import { ListItemWrapper } from './styled';
import imageStar from '../../../../assets/image/star.png';

export const ListItemContainer: React.FC<{
  children: ReactElement;
  styles?: CSSProperties;
}> = ({ children, styles }) => (
  <ListItemWrapper style={styles}>
    <img src={imageStar} alt="" />
    {children}
  </ListItemWrapper>
);
