import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const ConfirmCurrentPasswordWindowContainer = styled.div`
  padding: 42px 40px 1px;
  min-width: 380px;
  background: ${variables.colorGreyLight};
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
`;
