import signUpEn from './en/signUp.json';
import signInEn from './en/signIn.json';
import basicComponentsEn from './en/basicComponents.json';
import sidebarEn from './en/sidebar.json';
import createNewGalleryEn from './en/createNewGallery.json';
import activeGalleriesEN from './en/activeGalleries.json';
import draftGalleriesEN from './en/draftGalleries.json';
import galleryEN from './en/gallery.json';
import salesEn from './en/sales.json';
import notificationsEn from './en/notifications.json';
import faqEn from './en/faq.json';
import walkThroughCardsEn from './en/walkThroughCards.json';
import settingsEn from './en/settings.json';

export const resources = {
  en: {
    basicComponents: basicComponentsEn,
    signIn: signInEn,
    signUp: signUpEn,
    sidebar: sidebarEn,
    createNewGallery: createNewGalleryEn,
    activeGalleries: activeGalleriesEN,
    draftGalleries: draftGalleriesEN,
    gallery: galleryEN,
    sales: salesEn,
    notifications: notificationsEn,
    faq: faqEn,
    walkThroughCards: walkThroughCardsEn,
    settings: settingsEn,
  },
};

export const languages = Object.keys(resources);
