import { months } from '../../../../../constants';

// temporary data for charts

export const dataWeek = [
  {
    name: 'Monday',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Tuesday',
    uv: 3000,
    amt: 2210,
  },
  {
    name: 'Wednesday',
    uv: 2000,
    amt: 2290,
  },
  {
    name: 'Thursday',
    uv: 2780,
    amt: 2000,
  },
  {
    name: 'Friday',
    uv: 1890,
    amt: 2181,
  },
  {
    name: 'Saturday',
    uv: 2390,
    amt: 2500,
  },
  {
    name: 'Sunday',
    uv: 3490,
    amt: 2100,
  },
];

const randomInteger = (min: number, max: number) => {
  const rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
};

export const getDataMonth = () => {
  const arr = months.map((item) => ({
    name: item,
    uv: randomInteger(2000, 4000),
    amt: randomInteger(2000, 4000),
  }));
  return arr;
};

export const getDataDay = () => {
  const arr = [];
  for (let i = 1; i <= 30; i++) {
    arr.push({
      name: i,
      uv: randomInteger(2000, 4000),
      amt: randomInteger(2000, 4000),
    });
  }
  return arr;
};
