import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonCopyInput } from '../../../../shared/components/CommonCopyInput/CommonCopyInput';
import { ViewsLikesDownloadsShares } from '../../../../shared/components/ViewsLikesDownloadsShares/ViewsLikesDownloadsShares';
import { SimpleBlackText } from '../../../../shared/styles';
import { GalleryInfoWrapper } from './styled';

type GalleryInfoType = {
  galleryUrl: string;
  likes: number;
  shares: number;
  downloads: number;
  views: number;
};

export const GalleryInfo: React.FC<GalleryInfoType> = ({
  galleryUrl,
  likes,
  downloads,
  views,
  shares,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <Grid container item sm={10}>
      <SimpleBlackText>{t('galleryLink')}</SimpleBlackText>
      <GalleryInfoWrapper>
        <CommonCopyInput value={galleryUrl} name="galleryUrl" />
      </GalleryInfoWrapper>
      <Grid item sm={7}>
        <ViewsLikesDownloadsShares
          likes={likes}
          shares={shares}
          downloads={downloads}
          views={views}
        />
      </Grid>
    </Grid>
  );
};
