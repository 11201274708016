import React, { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonWithoutShadow, PrimaryWhiteButton, PrimaryWhiteButtonBlueText } from '../../styles/Buttons';
import {
  ButtonsWrapper,
  CloseButton,
  CustomDialogActions,
  CustomDialogContentText,
  CustomTitleDialog,
  getAlertStyles,
} from './styled';
import { CloseIcon } from '../../icons';

type CustomAlert = {
  isOpenWindow: boolean;
  message: string;
  title: string;
  error?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  item?: any;
  handleClick: any;
  buttonText?: string;
};

export const CustomAlert: React.FC<CustomAlert> = ({
  isOpenWindow,
  setIsOpen,
  title,
  message,
  error = false,
  item,
  handleClick,
  buttonText,
}) => {
  const { t } = useTranslation('basicComponents');
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Dialog
        open={isOpenWindow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: getAlertStyles(!error) }}
      >
        <CloseButton type="button" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <CustomTitleDialog confirmation id="alert-dialog-title">
          {title}
        </CustomTitleDialog>
        <DialogContent>
          <CustomDialogContentText id="alert-dialog-description">
            {message}
          </CustomDialogContentText>
        </DialogContent>
        <CustomDialogActions>
          {error ? (
            <PrimaryWhiteButtonBlueText onClick={handleClose} color="primary" autoFocus>
              {t('buttons.okay')}
            </PrimaryWhiteButtonBlueText>
          ) : (
            <ButtonsWrapper>
              {buttonText
                ? (
                  <PrimaryWhiteButton type="button" onClick={() => handleClick(item)}>
                    {buttonText}
                  </PrimaryWhiteButton>
                )
                : (
                  <PrimaryWhiteButtonBlueText type="button" onClick={() => handleClick(item)}>
                    {t('buttons.sure')}
                  </PrimaryWhiteButtonBlueText>
                )}
              {/* <PrimaryWhiteButtonBlueText type="button" onClick={() => handleClick(item)}>
                {t('buttons.sure')}
              </PrimaryWhiteButtonBlueText> */}
              <ButtonWithoutShadow onClick={handleClose}>
                {t('buttons.cancel')}
              </ButtonWithoutShadow>
            </ButtonsWrapper>
          )}
        </CustomDialogActions>
      </Dialog>
    </div>
  );
};
