import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Placement } from 'react-joyride';
import * as urls from '../../../../constants/urls';
import { CloseIcon } from '../../../shared/icons';
import { MediumBlackText } from '../../../shared/styles';
import {
  TooltipBody,
  TooltipContent,
  TooltipFooter,
  FormattedMessage,
  TooltipCloseButton,
  TooltipNextButton,
  TooltipBackButton,
} from './styled';

const newId = uuidv4();

const getPosition = (placement: Placement): string => {
  if (placement.includes('top')) return 'top';
  if (placement.includes('bottom')) return 'bottom';
  if (placement.includes('right')) return 'right';
  return 'left';
};

export const CustomTooltip: React.FC<any> = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
}) => {
  const history = useHistory();

  const handleClickNext = (e: MouseEvent<HTMLElement>) => {
    if (index === 4) {
      history.push(`${urls.newGalleryUrl}/${newId}`);
    }
    if (step.target.includes('supportSidebar')) {
      history.push(`${urls.supportUrl}`);
    }
    primaryProps.onClick(e);
  };

  const topPosition = step.styles.tooltip.top;
  const leftPosition = step.styles.tooltip.left;
  const tooltipPosition = getPosition(step.placement);

  return (
    <TooltipBody
      {...tooltipProps}
      tooltipPosition={tooltipPosition}
      style={{ top: topPosition, left: leftPosition }}
    >
      <TooltipContent>
        <MediumBlackText>{step.content}</MediumBlackText>
      </TooltipContent>
      {!step.hideFooter && (
        <TooltipFooter isFirstCard={index === 0}>
          {index > 0 && (
            <TooltipBackButton {...backProps}>
              {backProps.title}
              <FormattedMessage id="back" />
            </TooltipBackButton>
          )}
          {continuous && (
            <TooltipNextButton {...primaryProps} onClick={handleClickNext}>
              {primaryProps.title}
              <FormattedMessage id="next" />
            </TooltipNextButton>
          )}
          <TooltipCloseButton {...skipProps} id="skip">
            <CloseIcon />
            <FormattedMessage id="skip" />
          </TooltipCloseButton>
        </TooltipFooter>
      )}
    </TooltipBody>
  );
};
