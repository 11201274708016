import styled from '@emotion/styled';

export const ButtonMoveToTopWrapper = styled.div`
  position: sticky;
  bottom: 130px;
  left: 95%;
  z-index: 1000;
  width: fit-content;
  margin: 0;

  & button {
    margin: 0;
  }
`;
