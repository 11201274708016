import React from 'react';
import { ArrowSimpleIcon } from '../../icons';
import { RoundButton } from '../../styles';
import { ArrowWrapper } from '../styled';
import { ButtonsDirectionWrapper } from './styled';

type ArrowButtonsType = {
  handleLeftClick: () => void;
  handleRightClick: () => void;
  buttonType?: 'submit' | 'button';
  large?: boolean;
  toRightNone?: boolean;
};

export const ArrowButtons: React.FC<ArrowButtonsType> = ({
  handleLeftClick,
  handleRightClick,
  buttonType = 'button',
  large,
  toRightNone,
}) => (
  <ButtonsDirectionWrapper large={large}>
    <RoundButton
      large={!!large}
      type={buttonType}
      onClick={handleLeftClick}
    >
      <ArrowWrapper large={large} direction="left">
        <ArrowSimpleIcon direction="left" />
      </ArrowWrapper>
    </RoundButton>
    {!toRightNone
    && (
    <RoundButton
      large={!!large}
      type={buttonType}
      onClick={handleRightClick}
    >
      <ArrowWrapper large={large} direction="right">
        <ArrowSimpleIcon direction="right" />
      </ArrowWrapper>
    </RoundButton>
    )}
  </ButtonsDirectionWrapper>
);
