import { useFormikContext } from 'formik';
import React from 'react';
import { getIndex } from '../../../../utils/galleryCreator';
import { ExpandIcon, ShrinkIcon } from '../../../shared/icons';
import { InstantDownload } from '../../../shared/icons/FileIcons';
import {
  GalleryIdea, GalleryFile, FilesOrIdeas, GalleryData,
} from '../../../shared/types';
import { ExpandAndShrinkWrapper, PositionedElementWrapper } from './styled';

type ElementsOverImageType = {
  indexSection: number;
  id: string;
  fileType: FilesOrIdeas;
  instantlyDownloadable: boolean;
  fullWidth: boolean;
};

export const ElementsOverImage: React.FC<ElementsOverImageType> = ({
  instantlyDownloadable,
  indexSection,
  id,
  fileType,
  fullWidth,
}) => {
  const { values, setFieldValue } = useFormikContext<GalleryData>();

  const handleExpandShrink = (itemId: string, type: FilesOrIdeas) => () => {
    const indexFile = getIndex<GalleryFile | GalleryIdea>(
      values.sections[indexSection][type],
      itemId,
    );
    const isFullWidth = !values.sections[indexSection][type][indexFile].fullWidth;

    setFieldValue(
      `sections[${indexSection}].${type}[${indexFile}].fullWidth`,
      isFullWidth,
    );
  };
  return (
    <PositionedElementWrapper>
      {instantlyDownloadable && <InstantDownload />}
      <ExpandAndShrinkWrapper
        type="button"
        onClick={handleExpandShrink(
          id,
          fileType,
        )}
      >
        {fullWidth ? <ShrinkIcon /> : <ExpandIcon />}
      </ExpandAndShrinkWrapper>
    </PositionedElementWrapper>
  );
};
