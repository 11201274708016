import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ColorfulButton } from '../../styles';
import { ColorfulCardContentWrapper, TitleStartWhiteText } from './styled';

export const PremiumPlanCard: React.FC = () => {
  const { t } = useTranslation('basicComponents');
  return (
    <ColorfulCardContentWrapper>
      <TitleStartWhiteText>
        <Trans t={t} i18nKey="switchPlan">
          Switch to
          {' '}
          <br />
          {' '}
          a Premium plan
        </Trans>
      </TitleStartWhiteText>
      <ColorfulButton>{t('buttons.changePlan')}</ColorfulButton>
    </ColorfulCardContentWrapper>
  );
};
