import styled from '@emotion/styled';

export const ImageWrapper = styled.div<{widthStretch: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  background: #F0F1F2;
`;

export const ImageElement = styled.img<{isWidthBigger: boolean}>`
  object-fit: cover;
  ${(props) => {
    if (props.isWidthBigger) {
      return `height: 100%;
          width: auto;`;
    } return `height: auto;
        width: 100%;`;
  }};
`;
