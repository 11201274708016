import { NotificationsData, Pagination } from '../../containers/shared/types';
import noPhoto from '../../assets/image/noPhoto.png';

const notifications: NotificationsData = {
  totalNotifications: 9,
  numberNotRead: 2,
  notifications: [
    {
      id: 'asdlllsa-das',
      from: 'Delivrable Team',
      message:
        'Now new feature available! Make sure to not miss the chance and watch a short tutorial  on our YouTube channel.',
      title: 'New feature just came out!',
      isRead: false,
      date: `${new Date(2021, 10, 1, 22, 12)}`,
    },
    {
      id: 'aqweqsdsa-qwas',
      from: 'emailaddress@gmail.com',
      message:
        'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
      title: 'New comment',
      isRead: false,
      date: `${new Date(2021, 10, 2, 11, 58)}`,
      item: noPhoto,
    },
    {
      id: 'asdsawe-d22s',
      from: 'emailaddress@gmail.com',
      message:
        'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
      title: 'New comment',
      isRead: true,
      date: `${new Date(2021, 9, 3, 16, 12)}`,
      item: noPhoto,
    },
    {
      id: 'asdqsa-d2w2s',
      from: 'Delivrable Team',
      message:
        'Now new feature available! Make sure to not miss the chance and watch a short tutorial  on our YouTube channel.',
      title: 'New feature just came out!',
      isRead: true,
      date: `${new Date(2021, 9, 4, 11, 12)}`,
    },
    {
      id: 'asdsdsa-d2www2s',
      from: 'Delivrable Team',
      message:
        'Now new feature available! Make sure to not miss the chance and watch a short tutorial  on our YouTube channel.',
      title: 'New feature just came out!',
      isRead: true,
      date: `${new Date(2021, 9, 5, 11, 12)}`,
    },
    {
      id: 'asd1sa-drras',
      from: 'Delivrable Team',
      message:
        'Now new feature available! Make sure to not miss the chance and watch a short tutorial  on our YouTube channel.',
      title: 'New feature just came out!',
      isRead: true,
      date: `${new Date(2021, 10, 6, 22, 12)}`,
    },
    {
      id: 'asds1a-qwas',
      from: 'emailaddress@gmail.com',
      message:
        'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
      title: 'New comment',
      isRead: true,
      date: `${new Date(2021, 10, 7, 11, 58)}`,
      item: noPhoto,
    },
    {
      id: 'asdsa-d22syy1',
      from: 'emailaddress@gmail.com',
      message:
        'Really love this pic! Wanna download it, but would like you to retouch it so that my face look smoother. Thanks in advance ',
      title: 'New comment',
      isRead: true,
      date: `${new Date(2021, 9, 8, 16, 12)}`,
      item: noPhoto,
    },
    {
      id: 'as1dsa-d2whtyhy2s',
      from: 'Delivrable Team',
      message:
        'Now new feature available! Make sure to not miss the chance and watch a short tutorial  on our YouTube channel.',
      title: 'New feature just came out!',
      isRead: true,
      date: `${new Date(2021, 9, 9, 11, 12)}`,
    },
  ],
};

export const fakeNotificationsRequests = {
  getNotifications: (
    pagination: Pagination,
  ): Promise<{ data: NotificationsData }> => Promise.resolve({
    data: {
      notifications: notifications.notifications.slice(
        (pagination.pageSize || 5) * (pagination.page - 1),
        (pagination.pageSize || 5) * pagination.page,
      ),
      totalNotifications: notifications.totalNotifications,
      numberNotRead: notifications.numberNotRead,
    },
  }),
  setNotificationsRead: (notReadIds: string[]) => {
    notifications.notifications.forEach((item) => {
      if (notReadIds.includes(item.id)) {
        item = { ...item, isRead: true };
      }
    });
    notifications.numberNotRead = 0;
    return Promise.resolve({
      data: notReadIds,
    });
  },
  getNotReadNotifications: () => Promise.resolve({
    data: notifications.numberNotRead,
  }),
};
