import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';

export const PlusCssIcon = styled.div<{color?: 'black' | 'pink'}>`
position: relative;
width: 16px;
height: 16px;

&:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background: ${(props) => (props.color === 'pink' ? variables.colorPink : variables.textBlack)};
  top: 7px;
  left: 0;
}
&:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background: ${(props) => (props.color === 'pink' ? variables.colorPink : variables.textBlack)};
  top: 0;
  left: 7px;
}
`;
