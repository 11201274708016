// add '0' to the month if it is necessary
const getCorrectMonth = (month: number): string => {
  if (String(month + 1).length < 2) {
    return `0${month + 1}`;
  }
  return `${month + 1}`;
};

// add '0' to the day if it is necessary
const getCorrectDayOrMinutes = (day: number): string => {
  if (String(day).length < 2) {
    return `0${day}`;
  }
  return `${day}`;
};

// transform date from Date format to "mm/dd/yyyy"
export const getFormatDate = (date: Date): string => `${getCorrectMonth(
  date.getMonth(),
)}/${getCorrectDayOrMinutes(date.getDate())}/${date.getFullYear()}`;

// transform date from Date format to "yyyy-mm-dd"
export const getFormatDateForDob = (date: Date): string => `${date.getFullYear()}-${getCorrectMonth(
  date.getMonth(),
)}-${getCorrectDayOrMinutes(date.getDate())}`;

export const getTimeWithAMAndPM = (date: Date): string => {
  const hours = +date.getHours();
  const minutes = +date.getMinutes();

  if (hours === 0) {
    return `12.${getCorrectDayOrMinutes(minutes)} p.m.`;
  }
  if (hours <= 12 && hours !== 0) {
    return `${hours}.${getCorrectDayOrMinutes(minutes)} a.m.`;
  }
  return `${hours - 12}.${getCorrectDayOrMinutes(minutes)} p.m.`;
};
