import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../../../../constants/styleVariables';
import { BaseSecondaryButton } from '../../../../shared/styles';

export const SectionFilesWrapper = styled.div<{limitedWidth?: boolean}>`
  margin-top: 10px;
  overflow-y: auto;
  ${(props) => props.limitedWidth && 'max-width: 118px;'}

  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    max-height: 386px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${variables.textGrey};
    border-radius: 163px;
  }

  &::-webkit-scrollbar-track {
    background: #dbdbdb;
    border-radius: 163px;
  }
`;
export const SectionContentWrapper = styled(Grid)<{isFormOpened: boolean}>`
  margin-top: 35px !important;
  ${(props) => (props.isFormOpened ? 'margin-right: 40px !important' : '')};
`;
export const CheckboxText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${variables.textGrey};
`;

export const SectionActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 41px;
  position: relative;
`;
export const ActionButton = styled.button`
  color: ${variables.textGrey};
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 0px;

  & > svg {
    margin-left: 10px;
  }

  & span {
    position: absolute;
    display: none;
    box-sizing: border-box;
    background: ${variables.textGrey};
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${variables.colorGreyLight};
    border-radius: 2px;
    z-index: 100;
    min-width: 125px;
    right: 100%;
  }

  &:hover {
    & span {
      display: block;
    }
  }
`;
export const PinkActionButton = styled(BaseSecondaryButton)`
  color: ${variables.colorPink};
  white-space: pre;

  & svg {
    height: 11px;
    width: 14px;
    margin-right: 10px;
  }
`;

// ${(props) => (!props.disabled && props.isValid
//   && `&:active {
//     & svg {
//       display: flex;
//       visibility: visible;
//       transition-property: visibility;
//       transition-duration: 1.5s;
//     }
//   }`
//   )}

// export const CustomSecondaryButton = styled(BaseSecondaryButton)<{isValid?: boolean}>

export const LabelCheckboxWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  justify-content: space-evenly;
  width: 85%;
`;
export const FormLimitFreeFilesGrid = styled(Grid)`
  position: relative !important;

  & label {
    position: absolute;
    top: 50%;
    margin-top: -12.5px;
    width: fit-content;
  }
`;
export const EmptyFilePreview = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  max-height: 388px;
  border: 1px solid ${variables.colorBleak};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 43px;

  & span {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: ${variables.colorBleak};
    margin-bottom: 24px;
  }
`;
export const FilePreviewWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  position: relative;
`;
export const FilePreviewTextUpperWrapper = styled.div`
  position: absolute;
  top: -33px;
  right: 0;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${variables.textGrey};
`;
export const FilePreviewImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 314px;
  margin-bottom: 14px;
`;
export const TextAlignRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
