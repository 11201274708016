import React, {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { isImage } from '../../../../utils';
import {
  GalleryFile,
  GalleryIdea,
} from '../../../shared/types';
import { FileOrCoverWrapper } from '../styled';
import { ElementsOverImage } from './ElementsOverImage';
import { ImageOrVideoCardWrapper } from './styled';
import { VideoContainer } from '../../../shared/components/VideoContainer/VideoContainer';

type ImageCardProps = {
  fileOrIdea: GalleryFile | GalleryIdea;
  indexSection: number;
};

export const ImageOrVideoCard: React.FC<ImageCardProps> = ({
  fileOrIdea,
  indexSection,
}) => {
  const [spans, setSpans] = useState(0);
  const fileRef = useRef<any>();

  const file = (fileOrIdea as GalleryFile).file || (fileOrIdea as GalleryIdea).cover;

  const handleSetSpans = (ref: MutableRefObject<any>) => {
    const height = ref?.current?.clientHeight || 0;
    const newSpans = Math.ceil((height) / 10);
    setSpans(newSpans);
  };

  useEffect(() => {
    fileRef?.current?.addEventListener('load', () => handleSetSpans(fileRef)); // for image
    return () => {
      fileRef?.current?.removeEventListener('load', () => handleSetSpans(fileRef));
    };
  }, []);

  useEffect(() => {
    if (fileRef.current) {
      handleSetSpans(fileRef);
    }
  }, [fileOrIdea.fullWidth]);

  return (
    <ImageOrVideoCardWrapper
      spans={spans}
      fullWidth={!!fileOrIdea.fullWidth}
    >
      <FileOrCoverWrapper fullWidth={!!fileOrIdea.fullWidth}>
        {isImage(file) ? (
          <img
            ref={fileRef}
            src={file?.url || ''}
            alt={fileOrIdea.name}
            loading="lazy"
          />
        ) : (
          <VideoContainer videoUrl={file?.url || ''} setSpans={handleSetSpans} widthStretch={!!fileOrIdea.fullWidth} />
        )}
        <ElementsOverImage
          instantlyDownloadable={!!(fileOrIdea as GalleryFile).instantlyDownloadable}
          indexSection={indexSection}
          id={fileOrIdea.id}
          fileType={'cover' in fileOrIdea ? 'ideas' : 'files'}
          fullWidth={false}
        />
      </FileOrCoverWrapper>
    </ImageOrVideoCardWrapper>
  );
};
