import React, { useContext } from 'react';
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import * as urls from '../../../constants';
import { PrivateRoute } from './PrivateRoute';
import { NewGalleryCreator } from '../../CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreator';
import { SignUpSteps } from '../../SignUp/SignUpSteps/SignUpSteps';
import { SignInCard } from '../../SignIn/SignInCard';
import { ResetPassword } from '../../SignIn/ResetPassword';
import { ResetSuccess } from '../../SignIn/ResetSuccess';
import { UserStateContext } from '../../../context/UserStateContext';
import { ActiveGalleries } from '../../CreatorAccount/Tabs/ActiveGalleries/ActiveGalleries';
import { DraftGalleries } from '../../CreatorAccount/Tabs/DraftGalleries/DraftGalleries';
import { Notifications } from '../../CreatorAccount/Tabs/Notifications/Notifications';
import { Support } from '../../CreatorAccount/Tabs/Support/Support';
import { Sales } from '../../CreatorAccount/Tabs/Sales/Sales';
import { MainTab } from '../../CreatorAccount/Tabs/MainTab/MainTab';
import { EmailWasConfirmedCard } from '../../SignUp/EmailWasConfirmedCard';
import { SignUpEmailConfirmation } from '../../SignUp/SignUpEmailConfirmation';
import { Settings } from '../../CreatorAccount/Tabs/Settings/Settings';

export const Routes: React.FC = () => {
  const location = useLocation();
  const { isSignIn } = useContext(UserStateContext);

  if (
    isSignIn
    && (location.pathname.includes(urls.signUpUrl)
      || location.pathname.includes(urls.signInUrl))
  ) {
    return <Redirect to={urls.rootUrl} />;
  }
  return (
    <Switch>
      <PrivateRoute exact path={urls.salesUrl} component={Sales} />
      <PrivateRoute exact path={urls.supportUrl} component={Support} />
      <PrivateRoute path={urls.settingsUrl} component={Settings} />
      <PrivateRoute exact path={urls.activeGalleriesUrl} component={ActiveGalleries} />
      <PrivateRoute exact path={urls.draftGalleriesUrl} component={DraftGalleries} />
      <PrivateRoute exact path={urls.rootUrl} component={MainTab} />
      <PrivateRoute exact path={urls.notificationsUrl} component={Notifications} />
      <PrivateRoute exact path={urls.accountUrl} component={MainTab} />
      <PrivateRoute path={`${urls.newGalleryUrl}/:id`} component={NewGalleryCreator} />
      <Route exact path={urls.signInUrl}>
        <SignInCard />
      </Route>
      <Route exact path={urls.resetUrl}>
        <ResetPassword />
      </Route>
      <Route exact path={urls.signUpConfirmWasSentUrl}>
        <EmailWasConfirmedCard />
      </Route>
      <Route exact path={urls.resetSuccessUrl}>
        <ResetSuccess />
      </Route>
      <Route exact path={urls.signUpInitialUrl}>
        <SignUpEmailConfirmation />
      </Route>
      <Route exact path={urls.signUpConfirmUrl}>
        <SignUpSteps />
      </Route>
    </Switch>
  );
};
