import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActionButton,
  PinkActionButton,
} from '../styled';
import { CloseIcon, TickIcon } from '../../../../../shared/icons';

type ApplyAndChosenButtonsType = {
  handleApplySettings: (e: MouseEvent<HTMLButtonElement>) => void;
  settingsApplied: boolean;
  numberChosenFiles: number;
  isFormHidden: boolean;
  handleUncheckedChosen: () => void;
};

export const ApplyAndChosenButtons: React.FC<ApplyAndChosenButtonsType> = ({
  handleApplySettings,
  settingsApplied,
  numberChosenFiles,
  isFormHidden,
  handleUncheckedChosen,
}) => {
  const { t } = useTranslation('createNewGallery');

  return (
    <>
      <ActionButton
        type="button"
        onClick={handleUncheckedChosen}
        disabled={isFormHidden}
      >
        <Trans t={t} i18nKey="thirdStep.chosenFiles">
          Chosen:
          {' '}
          {{ numberChosenFiles }}
          {' '}
          files
        </Trans>
        <CloseIcon />
      </ActionButton>
      <PinkActionButton
        type="submit"
        onClick={handleApplySettings}
        disabled={isFormHidden}
      >
        {settingsApplied ? (
          <span>
            <TickIcon />
            {t('thirdStep.settingsApplied')}
          </span>
        ) : (
          t('thirdStep.applySettings')
        )}
      </PinkActionButton>
    </>
  );
};
