import React, { ReactNode } from 'react';
import { Container, Grid } from '@material-ui/core';

type GalleryGridContainerType = {
  children: ReactNode;
}

export const GalleryGridContainer: React.FC<GalleryGridContainerType> = ({ children }) => (
  <Container maxWidth="xl">
    <Grid
      container
      item
      sm={12}
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={{ marginBottom: '40px' }}
    >
      {children}
    </Grid>
  </Container>
);
