import { GalleryCoverImages, ImageSize, InitialCroppedArea } from '../../containers/shared/types';

export const getInitialCroppedAreaPixels = (
  size: ImageSize,
  coverFile: any,
): InitialCroppedArea => {
  try {
    const initialCroppedAreaPixels = {
      width: coverFile[size]?.width,
      height: coverFile[size]?.height,
      x: coverFile[size]?.x,
      y: coverFile[size]?.y,
    };
    if (Object.values(initialCroppedAreaPixels).includes(undefined)) {
      return undefined;
    }
    return initialCroppedAreaPixels;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
