import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as urls from '../../constants/urls';
import { TickIcon } from '../shared/icons';
import {
  PrimaryWhiteButtonBlueText,
  TextWithIcon,
  TitleSimple,
  SignInUpCardCenter,
} from '../shared/styles';

export const ResetSuccess: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['signIn', 'basicComponents']);
  const onBack = () => {
    history.push(urls.signInUrl);
  };
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCardCenter theme="">
          <TitleSimple>{t('resetPassword')}</TitleSimple>
          <TextWithIcon>
            <TickIcon />
            <span>{t('passwordSent')}</span>
          </TextWithIcon>
          <PrimaryWhiteButtonBlueText type="button" theme="213" onClick={onBack}>
            {t('basicComponents:buttons.back')}
          </PrimaryWhiteButtonBlueText>
        </SignInUpCardCenter>
      </Grid>
    </Grid>
  );
};
