import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';
import { FileInputBase } from '../styles';

export const CommonInputWrapper = styled.div<{
  fullHeight?: boolean;
  withSvg?: boolean;
  width?: number;
  withoutMargins?: boolean;
}>`
  position: relative;
  margin: ${(props) => (props.withoutMargins ? '0' : '10px 0')};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  ${(props) => props.fullHeight && 'height: 100%;'}
  ${(props) => props.withSvg
    && `& svg {
      position: absolute;
      right: 20px;
      bottom: 11.5px;
    }`}
`;
export const AdornmentItem = styled.div`
  position: absolute;
  left: 15px;
  bottom: 11.3px;
  color: ${variables.textGrey};
  font-size: 15px;
`;
export const InputBase = styled.div<{
  direction?: string;
  width?: string;
  spaceBetween?: boolean;
  littleText?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  ${(props) => props.direction === 'row' && 'align-items: center;'}
  ${(props) => props.spaceBetween && 'justify-content: space-between;'}
  & label {
    font-size: ${(props) => (props.littleText ? '14px' : '16px')};
    line-height: 24px;
    display: inline-block;
    white-space: nowrap;
  }

  & > input,
  textarea,
  > div > input {
    font-size: 15px;
    width: ${(props) => props.width || '100%'};
    height: 44px;
    padding: 12px 24px;
    background: ${variables.colorGreyLight};
    border: 0.5px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    border-radius: 163px;
    display: flex;
    margin-right: 0;
    align-items: flex-start;

    &:read-only {
      color: ${variables.colorBleak};
    }

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: ${variables.colorBleak};
      line-height: 140%;
    }

    &:hover,
    &:active {
      box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
      cursor: auto;
    }
    ${({ color }) => (color === 'error'
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
    `
    : '')}

    &:disabled {
      border: 0.5px solid #e2e2e2;
      box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;

      &::placeholder {
        color: #d0d6db;
      }

      &:hover,
      &:active {
        box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #ededed;
      }
    }
  }

  & > span {
    position: absolute;
    top: 100%;
    ${(props) => (props.direction === 'row' ? 'left: 94px;' : 'left: 24px;')}

    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  & > span {
    position: absolute;
    top: 100%;
    left: 24px;
    color: ${variables.colorError};
    line-height: 18px;
    font-size: 12px;
  }
`;

export const CustomCheckbox = styled.label`
  display: flex;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 24px;
  width: 100%;
  height: 25px;
  align-items: center;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span::after {
      display: block;
    }
  }

  & span {
    background: ${variables.colorGreyLight};
    box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;

    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      display: none;
    }
  }

  & > span {
    &::after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid ${variables.colorPink};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  & div {
    font-size: 14px;
    line-height: 150%;
    margin-left: 30px;
    color: rgba(5, 27, 44, 0.5);
  }
`;
export const TextInput = styled.div`
  position: relative;
  border-radius: 16px;
  box-sizing: border-box;
  background: ${variables.colorGreyLight};
  border: 0.5px solid #e0e0e0;
  box-shadow: inset -5px -5px 10px #fff, inset 2px 2px 5px #e7e7e7;
  padding: 12px 10px 12px 24px;
  height: 100%;
  width: 100%;
  ${({ color }) => (color === 'error'
    ? `
    box-shadow: none;
    border: 0.5px solid ${variables.colorError};
  `
    : '')}

  &:hover,
  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 3px 3px 6px #e7e7e7;
  }

  & > textarea {
    background: transparent;
    display: flex;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    align-items: flex-start;
    box-sizing: border-box;
    resize: none;
    font-size: 15px;
    border: none;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${variables.colorBleak};
      border-radius: 100px !important;
    }

    &::placeholder {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: ${variables.colorBleak};
      line-height: 140%;
    }
  }
`;
export const MaxLengthText = styled.div`
  position: absolute;
  bottom: -24px;
  right: 0;

  & span {
    font-size: 13px;
    line-height: 120%;
    color: ${variables.textGrey};
  }
`;
export const AddFileLabel = styled(FileInputBase)<{
  hasError: boolean;
  hasFile: boolean;
  imageUrl: string;
}>`
  height: 268px !important;
  border-radius: 16px;
  position: relative;

  ${(props) => (props.hasError
    ? `
    border: 0.5px solid ${variables.colorError};
  `
    : '')}

  background: ${(props) => (props.hasFile ? `url(${String(props.imageUrl)})` : '#F0F1F2')};
  /* stylelint-disable selector-max-empty-lines */
  ${(props) => (props.hasFile
    ? 'background-size: cover;'
    : `
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 36px;
      height: 4px;
      background: black;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      height: 36px;
      background: black;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
    }
    `)}
  /* stylelint-enable selector-max-empty-lines */

  & input {
    top: 0;
    left: 0;
    position: absolute;
    width: 307px !important;
    height: 268px !important;
    z-index: 10;
  }
`;

export const TextLabel = styled.label<{ short?: boolean; disabled?: boolean }>`
  margin-top: 12px !important;
  margin-right: 30px;
  margin-bottom: 12px !important;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  position: relative;
  ${(props) => props.disabled && `color: ${variables.textGrey};`}
  ${(props) => props.short && 'white-space: pre-wrap !important; max-width: 203px;'}
`;
export const ErrorMessage = styled.span<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : '-20')}px;
  left: ${(props) => (props.left || props.left === 0 ? props.left : '24')}px;
  color: ${variables.colorError};
  line-height: 18px;
  font-size: 12px;
  max-width: 300px;
`;
export const CopyInputWrapper = styled(InputBase)`
  position: relative;

  & > input,
  textarea,
  > div > input {
    padding: 12px 54px 12px 24px;

    &:read-only {
      color: ${variables.textGrey};
    }
  }

  & button {
    position: absolute;
    right: 14px;
    bottom: 3px;
  }
`;

export const TextCopied = styled.div<{ isVisible: boolean }>`
  transition: all 0.4s ease-in-out;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  flex-direction: row;
  align-items: center;
  margin-left: 35px;
  left: 0;

  & svg {
    margin-right: 10px;
  }
`;

export const CheckboxInput = styled.label`
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span:after {
      display: block;
    }
  }

  & div {
    margin-left: 28px;
    font-size: 16px;
    line-height: 24px;
    color: ${variables.textGrey};
  }

  & span {
    z-index: 10;
    border: 0.5px solid ${variables.textGrey};
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 2.36364px;

    &::after {
      z-index: 10;
      content: "";
      position: absolute;
      display: none;
    }
  }

  & > span {
    &::after {
      left: 5px;
      top: 3px;
      width: 4px;
      height: 7px;
      border: solid ${variables.textGrey};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
export const CustomPositionCheckbox = styled.div`
  & > label {
    & > span {
      top: 8px;
      left: 6px;
      margin-top: 0;
    }
  }
`;
export const HelpWrapper = styled.span`
  position: absolute;
  left: 103% !important;
  top: 50% !important;
  margin-top: -12px !important;
`;

const getDeg = (direction: string) => {
  switch (direction) {
    case 'right':
      return '225';
    case 'left':
      return '45';
    case 'up':
      return '135';
    case 'down':
      return '315';
    default:
      return '225';
  }
};

export const ArrowWrapper = styled.span<{large?: boolean, direction: 'left' | 'right' | 'up' | 'down'}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    width: fit-content;
    height: fit-content;
  }

  & svg {
    width: ${(props) => (props.large ? '19px' : '14px')};
    height: ${(props) => (props.large ? '27px' : '19px')};
    margin-top: 4px;
  }
`;

export const CSSArrowIcon = styled.span<{
  large?: boolean;
  theme: 'left' | 'right' | 'up' | 'down';
}>`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: ${(props) => (props.large ? '12px' : '8px')};
    height: ${(props) => (props.large ? '12px' : '8px')};
    margin-top: ${(props) => (props.large ? '-6px' : '-4px')};
    margin-left: ${(props) => (props.large ? '-6px' : '-3.5px')};
    border-bottom: 1px solid ${variables.colorBleak};
    border-left: 1px solid ${variables.colorBleak};
    font-size: 14px;
    background: #e0e0e000 !important;
    transform: rotate(${({ theme }) => getDeg(theme)}deg);
  }
`;

export const TextCard = styled.div<{
  withChildren: boolean;
  inheritSize?: boolean;
}>`
  margin-right: 30px;
  width: ${(props) => (props.inheritSize ? '100%' : '82px')};
  height: ${(props) => (props.inheritSize ? '100%' : '82px')};
  font-size: 6.73684px;
  line-height: 140%;
  padding: 10px;
  border: 0.5px solid ${variables.colorBleak};
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: ${variables.textBlack};
  margin-bottom: 25px;
  position: relative;
  background: ${variables.colorGreyLight};

  & span {
    word-wrap: break-word;
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: flex;
    display: -moz-box;
    display: -webkit-box;
    text-overflow: ellipsis;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
  }

  & button {
    position: absolute;
    cursor: pointer;
    display: none;
    color: ${variables.textBlack};
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: inherit;
    background: ${variables.colorGreyLight};
    justify-content: center;
    align-items: center;

    & > svg {
      color: black;
      z-index: 9999;
    }
  }
  ${(props) => props.withChildren
    && `&:hover {
    color: transparent;

    & button {
      display: flex;
    }
  }`}
`;
