import React, { ReactElement } from 'react';
import {
  CustomSelect,
  PlaceholderText,
  PlaceholderWrapper,
  SelectWithErrorMessage,
} from './styled';
import {
  ErrorMessage,
  TextLabel,
} from '../styled';
import { getStyle } from './getStyleForSelect';

type CommonSelectProps = {
  error?: string;
  value: string;
  name: string;
  handleChange: ((event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
}>, child: React.ReactNode) => void);
  touched?: boolean;
  width?: number;
  label?: string;
  placeholder?: string;
  menuItems: ReactElement[];
};

export const CommonSelect: React.FC<CommonSelectProps> = ({
  error,
  value,
  name,
  handleChange,
  touched,
  width,
  label,
  placeholder,
  menuItems,
}) => (
  <SelectWithErrorMessage>
    {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
    <CustomSelect
      id={name}
      name={name}
      theme={`${width || '336'}`}
      value={value}
      onChange={handleChange}
      defaultValue={value}
      error={Boolean(error && touched)}
      MenuProps={getStyle(width || 336)}
    >
      <PlaceholderWrapper value={placeholder} disabled>
        <PlaceholderText>{placeholder}</PlaceholderText>
      </PlaceholderWrapper>
      {menuItems}
    </CustomSelect>
    {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
  </SelectWithErrorMessage>
);
