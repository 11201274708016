import React, { ReactNode } from 'react';
import { ImageSizes } from '../../../constants';
import { getFileInContainer } from '../../../utils/galleryCreator';
import { GalleryFileCropper } from '../../shared/components/Croppers/GalleryFileCropper';
import { ImageSize, CustomFileType } from '../../shared/types';

type ExtraFileCropperOrContainer = {
  size: ImageSize;
  withCropper: boolean;
  fileName: 'extraCover1' | 'extraCover2';
  file: CustomFileType | null;
};

export const getExtraFileInContainerOrCropper = ({
  size,
  withCropper,
  fileName,
  file,
}: ExtraFileCropperOrContainer): ReactNode => (withCropper ? (
  <GalleryFileCropper
    height={ImageSizes.extraCover[size || 'desktop'].height}
    width={ImageSizes.extraCover[size || 'desktop'].width}
    size={size || 'desktop'}
    fileName={fileName}
  />
) : (
  getFileInContainer(file)
));
