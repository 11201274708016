import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { isVideo } from '../../../../utils';
import { getInitialCroppedAreaPixels } from '../../../../utils/galleryCreator/getInitialCroppedAreaPixels';
import { ExtraImageSize, GalleryData, ImageSize } from '../../types';
import { BaseCropper } from './BaseCropper/BaseCropper';

type GalleryFileCropperType = {
  width: number;
  height: number;
  size: ImageSize;
  fileName?: 'coverFile' | 'extraCover1' | 'extraCover2';
};

export const GalleryFileCropper: React.FC<GalleryFileCropperType> = ({
  width,
  height,
  size,
  fileName = 'coverFile',
}) => {
  const { values } = useFormikContext<GalleryData>();

  const fileUrl = values[fileName]?.original?.file?.url;

  const initialCroppedAreaPixels = useMemo(
    () => getInitialCroppedAreaPixels(size, values[fileName]),
    [values[fileName], size],
  );

  const videoOrImage = isVideo(values[fileName]?.original?.file || null)
    ? { video: fileUrl }
    : { image: fileUrl };

  return (
    <BaseCropper
      initialCroppedAreaPixels={initialCroppedAreaPixels}
      size={fileName !== 'coverFile' ? `${fileName}${size}` : size}
      width={width}
      height={height}
      videoOrImage={videoOrImage}
    />
  );
};
