import { Support } from '../../containers/shared/types';

const support: Support = {
  questions: [
    {
      id: 'asd1',
      question: 'How can I share a gallery with my client or another long long long long long long question?',
      answer: 'You can share the gallery with your client by sending them an email invitation or by sharing a link in Share and send section of the gallery Settings. You can share the gallery with your client by sending them an email invitation or by sharing a link in Share and send section of the gallery Settings.',
    },
    {
      id: 'asd2',
      question: 'How can I share a gallery with my client2?',
      answer: 'Answer 2.',
    },
    {
      id: 'asd3',
      question: 'How can I share a gallery with my client3?',
      answer: 'Answer 3',
    },
    {
      id: 'asd4',
      question: 'How can I share a gallery with my client4?',
      answer: 'Answer 4',
    },
    {
      id: 'asd5',
      question: 'How can I share a gallery with my client5?',
      answer: 'Answer 4',
    },
  ],
};

export const fakeSupportRequests = {
  getSupport: (): Promise<{data: Support}> => Promise.resolve({
    data: support,
  }),
};
