import styled from '@emotion/styled';

export const ChangePlanButton = styled.button`
  padding: 0px;
  margin-bottom: 40px;
`;

export const TextWithIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }
`;
