import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import {
  TabWrapper,
  TitleText,
  TabContentWrapper,
  PrimaryWhiteButton,
  SimpleBlackText,
} from '../../../shared/styles';
import * as urls from '../../../../constants/urls';
import { SettingsTabs } from './SettingsTabs/SettingsTabs';
import { SettingsWrapper } from './styled';
import { TabPanel } from '../ActiveGalleries/Dashboard/GalleryDetails/TabPanel';
import { ProfileTab } from './ProfileTab/ProfileTab';
import { useGetTabsSettings } from './ProfileTab/useGetTabsSettings';
import { UsageAndBilling } from './UsageAndBilling/UsageAndBilling';
import { AdvancedSettings } from './AdvancedSettings/AdvancedSettings';
import { ReferFriendTab } from './ReferFriendTab/ReferFriendTab';
import { ChangePlanPage } from './ChangePlanPage/ChangePlanPage';
import { SuccessNotification } from '../../../shared/components/SuccessNotification/SuccessNotification';
import { PaymentDetails } from './PaymentDetailsPage/PaymentDetails';

export const Settings: React.FC = () => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const { pathname } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);

  const settingsTabs = useGetTabsSettings();

  const handleSaveChanges = () => {
    setIsNotificationOpened(true);
    setTimeout(() => history.push(urls.rootUrl), 705);
  };

  let settingsContent = null;

  if (pathname.includes(urls.changePlanUrl)) {
    settingsContent = <ChangePlanPage />;
  }
  if (pathname.includes(urls.paymentMethodUrl)) {
    settingsContent = <PaymentDetails />;
  }
  if (!pathname.includes(urls.paymentMethodUrl) && !pathname.includes(urls.changePlanUrl)) {
    settingsContent = (
      <>
        <TabContentWrapper style={{ marginBottom: '50px' }}>
          <SettingsTabs
            tabs={settingsTabs}
            value={value}
            setValue={setValue}
          />
          <SettingsWrapper>
            <TabPanel value={value} index={0} noOverflowY>
              <ProfileTab />
            </TabPanel>
            <TabPanel value={value} index={1} noOverflowY>
              <UsageAndBilling />
            </TabPanel>
            <TabPanel value={value} index={2} noOverflowY>
              <AdvancedSettings />
            </TabPanel>
            <TabPanel value={value} index={3} noOverflowY>
              <ReferFriendTab />
            </TabPanel>
          </SettingsWrapper>
        </TabContentWrapper>
        <PrimaryWhiteButton type="button" onClick={handleSaveChanges}>
          <SimpleBlackText fontWeight={600}>
            {t('basicComponents:buttons.saveChanges')}
          </SimpleBlackText>
        </PrimaryWhiteButton>

      </>
    );
  }

  return (
    <>
      <TabWrapper>
        <TitleText>{t('title')}</TitleText>
        {settingsContent}
      </TabWrapper>
      <SuccessNotification
        isOpen={isNotificationOpened}
        text={t('basicComponents:changesSaved')}
        setIsOpen={setIsNotificationOpened}
        longTime
      />
    </>
  );
};
