import * as variables from '../../constants/styleVariables';

export const getColorOfText = (status: string) => {
  switch (status) {
    case 'error':
      return variables.colorError;
    case 'success':
      return '#76EE58';
    default:
      return '#C4C4C4';
  }
};
