import { Creator, SignIn, SignUp } from '../containers/shared/types';

const mockDB: {data: { status: boolean, user: Creator, error: string }} = {
  data: {
    status: false,
    user: {
      id: 'asd-we-qeewe-22',
      firstName: 'Sally',
      lastName: 'Smith',
      businessName: 'businessName',
      address: 'https://businessName.delivrable.com',
      country: 'AU',
      premium: false,
    },
    error: 'Message',
  },
};

export const fakeCreatorAuth = {
  signUp: (data: SignUp) => {
    mockDB.data.status = true;
    return Promise.resolve(mockDB);
  },
  signIn: (data: SignIn) => {
    mockDB.data.status = true;
    return Promise.resolve(mockDB);
  },
  getCurrentUserCreator: () => Promise.resolve(mockDB),
  logout: () => {
    mockDB.data.status = false;
    return Promise.resolve(mockDB);
  },
};

export const fakeCheckDuplication = {
  check: (data: { field: string; value: string }) => Promise.resolve({
    data: {
      field: data.field,
      isUnique: true,
      message: 'It already uses',
    },
  }),
};
