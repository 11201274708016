import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';

export const TooltipBody = styled.div<{tooltipPosition: string}>`
  background-color: ${variables.colorGreyLight};
  z-index: 100000;
  border-radius: 10px;
  padding: 12px 20px;
  position: relative;
  box-sizing: border-box;
  width: 260px;
  box-shadow: none;
  filter: none;

  ${(props) => {
    if (props.tooltipPosition === 'top' || props.tooltipPosition === 'bottom') {
      return `
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-left: -10px;
      `;
    }
    if (props.tooltipPosition === 'right' || props.tooltipPosition === 'left') {
      return `
      margin-right: 0px;
      margin-left: 0px;
      margin-top: -10px;
      margin-bottom: 0px;
      `;
    }
    return 'margin: 0;';
  }}
`;

// arrow
// &::after {
//   content: '';
//   position: absolute;
//   border-radius: 0px;
//   z-index: 100000;
//   ${(props) => {
//   if (props.tooltipPosition === 'top') {
//     return `
//     border: 14px solid transparent;
//   border-top: 14px solid ${variables.colorGreyLight};
//   left: 10px;
//   top: 100%;
//     `;
//   }
//   if (props.tooltipPosition === 'bottom') {
//     return `
//     border: 14px solid transparent;
//   border-bottom: 14px solid ${variables.colorGreyLight};
//   left: 20px;
//   top: -28px;
//     `;
//   }
//   if (props.tooltipPosition === 'right') {
//     return `
//     border: 14px solid transparent;
//   border-right: 14px solid ${variables.colorGreyLight};
//   left: 0;
//   top: 10px;
//     `;
//   }
//   return `
//     border: 14px solid transparent;
//   border-left: 14px solid ${variables.colorGreyLight};
//   left: 100%;
//   top: 10px;
//     `;
// }}
// }

export const TooltipContent = styled.div`
  max-width: 200px;
`;

export const TooltipFooter = styled.div<{isFirstCard: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isFirstCard ? 'flex-end' : 'space-between')};
  margin-top: 20px;
`;

export const TooltipButton = styled.button`

`;

export const TooltipCloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 8px;

  & > svg {
    color: ${variables.textBlack};
  }
`;

export const FormattedMessage = styled.div`
  
`;

export const TooltipNextButton = styled.button`
  padding: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 145%;
  text-align: right;
  color: ${variables.colorBlue};
`;

export const TooltipBackButton = styled(TooltipNextButton)`
  text-align: left;
  color: ${variables.textGrey};
`;
