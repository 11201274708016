import React, { ReactElement } from 'react';
import { DetailsContentWrapper } from './styled';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  noOverflowY?: boolean;
};

export const TabPanel = (props: TabPanelProps): ReactElement => {
  const {
    children, value, index, noOverflowY, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
      <DetailsContentWrapper noOverflowY={noOverflowY}>{children}</DetailsContentWrapper>
      )}
    </div>
  );
};
