export const permuteArrValues = <T, >(arr: T[], index: number, direction: 'down' | 'up'): T[] => {
  const numberPlusOrMinus = direction === 'down' ? 1 : -1;
  [arr[index + numberPlusOrMinus], arr[index]] = [arr[index], arr[index + numberPlusOrMinus]];
  return arr;
};

export const setFirstItemOfList = <T, >(arr: T[], indexItem: number): T[] => {
  [arr[0], arr[indexItem]] = [arr[indexItem], arr[0]];
  return arr;
};
