import styled from '@emotion/styled';
import * as variables from '../../../../../../constants/styleVariables';

export const DashboardHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  & svg {
    color: ${variables.colorBlack};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: -25px;
  top: -35px;
`;
