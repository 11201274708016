import React, { useState, MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import mainImage from '../../../assets/image/walkThroughCards/main.png';
import { OnboardingContext } from '../../../context/OnboardingContext';
import { ModalWindowContainer } from '../../shared/components/ModalWindow/ModalWindowContainer';
import { useTypedSelector } from '../../shared/hooks/useTypedSelector';
import { CloseIcon } from '../../shared/icons';
import {
  BaseWithoutShadowButton,
  CloseButtonPositioned,
  ColorfulButtonWhiteBack,
  TitleText,
} from '../../shared/styles';
import { WalkThroughButtons, WalkThroughStartWrapper } from './styled';

type WalkThroughStartType = {
  handleClickStart: (e: MouseEvent<HTMLElement>) => void;
}

export const WalkThroughStart: React.FC<WalkThroughStartType> = ({ handleClickStart }) => {
  const { t } = useTranslation('walkThroughCards');
  const { setIsStudy } = useContext(OnboardingContext);

  const isSignInAfterSigningUp = useTypedSelector(
    (state) => state.signUpCreator.isSignUp,
  );

  const [isOpen, setIsOpen] = useState(isSignInAfterSigningUp);

  const handleClose = () => {
    setIsStudy(false);
    setIsOpen(false);
  };

  const handleStartTraining = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleClose();
    setIsStudy(true);
    // handleClickStart(e);
  };

  return (
    <ModalWindowContainer isOpen={isOpen} handleClose={handleClose}>
      <WalkThroughStartWrapper>
        <CloseButtonPositioned
          color="black"
          type="button"
          onClick={handleClose}
        >
          <CloseIcon />
        </CloseButtonPositioned>
        <div>
          <TitleText>{t('welcome')}</TitleText>
        </div>
        <div>
          <img src={mainImage} alt="Welcome" />
        </div>
        <WalkThroughButtons>
          <ColorfulButtonWhiteBack
            type="button"
            onClick={handleStartTraining}
          >
            {t('startTraining')}
          </ColorfulButtonWhiteBack>
          <BaseWithoutShadowButton
            type="button"
            onClick={handleClose}
          >
            {t('skip')}
          </BaseWithoutShadowButton>
        </WalkThroughButtons>
      </WalkThroughStartWrapper>
    </ModalWindowContainer>
  );
};
