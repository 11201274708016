import { CustomFileType } from '../containers/shared/types';

export const isArrayIncludeValue = <A>(arr: A[], value: A): boolean => arr.includes(value);

export const isVideo = (file: CustomFileType | File | null): boolean => (file ? file.type.includes('video') : false);

export const isImage = (file: CustomFileType | File | null): boolean => (file ? file.type.includes('image') : false);

export const isDocument = (file: CustomFileType | File | null): boolean => (
  file ? !isImage(file) && !isVideo(file) : false);

export const getURLByFile = (file: File): string => URL.createObjectURL(file);

export const isFirstItemInArr = (index: number): boolean => index === 0;

export const isLastItemInArr = <T>(arr: T[], index: number): boolean => index === arr.length - 1;

export const getStrIfNumberBigger99 = (num: number): string | number => {
  if (num > 99) return '+99';

  return num;
};
