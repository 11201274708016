/* eslint-disable implicit-arrow-linebreak */
import { countries } from 'i18n-iso-countries/langs/en.json';
import { SelectorMenuItem } from '../containers/shared/types';
import { initData } from './initialValues/creator';
import { timezones } from './timezones';

export const dateFormats: SelectorMenuItem<string>[] = [
  { id: 'mm/dd/yyyy', value: 'mm/dd/yyyy' },
  { id: 'yyyy/dd/mm', value: 'yyyy/dd/mm' },
  { id: 'yyyy/mm/dd', value: 'yyyy/mm/dd' },
  { id: 'dd/mm/yyyy', value: 'dd/mm/yyyy' },
];

export const getTimezonesList = (): SelectorMenuItem<string>[] => timezones.map((item) => ({
  id: item,
  value: item,
}));

export const paymentCurrencyData: SelectorMenuItem<string>[] = [
  {
    id: 'Australian / New Zeland dollar',
    value: 'Australian / New Zeland dollar',
  },
  { id: 'American dollar', value: 'American dollar' },
  { id: 'Euro', value: 'Euro' },
];

export const storeCurrencyData: SelectorMenuItem<string>[] = [
  { id: 'US', value: initData.storeCurrency },
  { id: 'EURO', value: 'EURO' },
];

export const collectedByData: SelectorMenuItem<string>[] = [
  { id: 'Delivrable', value: initData.collectedBy },
  { id: 'Me', value: 'Me (Photographer)' },
];

export const createCountriesList = (): SelectorMenuItem<string>[] =>
  Object.entries(countries).map((item) => ({
    id: item[1] as string, value: item[1] as string,
  }));
