import { v4 as uuidv4 } from 'uuid';
import gallery7 from '../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../assets/image/walkThroughCards/gallery8.jpg';
import gallery9 from '../../assets/image/walkThroughCards/gallery9.jpg';
import gallery10 from '../../assets/image/walkThroughCards/gallery10.jpg';
import gallery11 from '../../assets/image/walkThroughCards/gallery11.jpg';
import activeGallery from '../../assets/image/walkThroughCards/activeGallery.png';
import { Gallery, GalleryDataFormType } from '../../containers/shared/types';

export const fakeGallery: GalleryDataFormType = {
  id: 'study',
  galleryName: 'Your gallery name',
  galleryUrl: 'timbremediahouse.delivrable.com/yourgalleryname',
  step: 1,
  coverFile: {
    original: { file: { url: gallery7, type: 'image', name: 'gallery7' } },
    mobile: { file: null },
    tablet: { file: null },
    desktop: { file: null },
  },
  date: new Date(),
  message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy.',
  galleryDesign: 1,
  sections: [
    {
      id: uuidv4(),
      title: 'Section 1',
      files: [
        {
          id: uuidv4(),
          file: { url: gallery7, type: 'image', name: 'gallery7' },
          name: 'gallery7',
          instantlyDownloadable: true,
          price: '30',
          description: 'Anna',
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          file: { url: gallery8, type: 'image', name: 'gallery8' },
          name: 'gallery8',
        },
        {
          id: uuidv4(),
          file: { url: gallery9, type: 'image', name: 'gallery9' },
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          file: { url: gallery10, type: 'image', name: 'gallery10' },
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          file: { url: gallery11, type: 'image', name: 'gallery11' },
          name: 'gallery11',
        },
      ],
      ideas: [],
      hasFreeFiles: true,
      numberFreeFiles: 15,
    },
    {
      id: uuidv4(),
      title: 'Section 2',
      files: [
        {
          id: uuidv4(),
          file: { url: gallery7, type: 'image', name: 'gallery7' },
          name: 'gallery7',
          instantlyDownloadable: true,
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          file: { url: gallery11, type: 'image', name: 'gallery11' },
          name: 'gallery11',
        },
        {
          id: uuidv4(),
          file: { url: gallery10, type: 'image', name: 'gallery10' },
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          file: { url: gallery9, type: 'image', name: 'gallery9' },
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          file: { url: gallery8, type: 'image', name: 'gallery8' },
          name: 'gallery8',
        },
      ],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    },
  ],
  hasRegistrationEmail: false,
  registrationEmail: [],
  password: 'newcontent2021',
  sharing: true,
  assist: true,
  hasExpiryDate: false,
  expiryDate: null,
  autoAlert: false,
  branding: false,
  testimonialCall: false,
};

export const fakeGalleries: Gallery[] = [{
  id: '1d1d11-safe21',
  galleryName: 'Gallery name 1',
  galleryUrl: 'businessName.delivrable.com/Galleryname1',
  likes: { numberLikes: 0, likes: [] },
  views: 20,
  shares: 10,
  sales: 350,
  salesPerWeek: 95,
  comments: [{ text: 'hello' }],
  downloads: { numberDownloads: 0, downloads: [] },
  ideas: [],
  coverFile: activeGallery,
  wasWatched: true,
  type: 'active',
},
{
  id: 'wewq1qe-wq564q',
  galleryName: 'Gallery name 2',
  galleryUrl: 'businessName.delivrable.com/Galleryname2',
  likes: { numberLikes: 0, likes: [] },
  views: 2,
  shares: 1,
  sales: 35,
  salesPerWeek: 9,
  comments: [{ text: 'hello 2' }],
  downloads: { numberDownloads: 0, downloads: [] },
  ideas: [],
  coverFile: gallery7,
  wasWatched: false,
  type: 'draft',
}];
