import { Statistics } from '../../../containers/shared/types';

const statistics: Statistics = {
  views: 12,
  likes: 10,
  downloads: 2,
  shares: 2,
  sales: 856,
  ideas: 2,
};

export const fakeStatisticsRequests = {
  getStatistics: (id: string): Promise<{data: Statistics}> => Promise.resolve({
    data: statistics,
  }),
};
