import React from 'react';

export const DiamondIcon: React.FC = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.8094 11.077L20.2586 4.13984C20.242 4.1195 20.2212 4.10306 20.1976 4.09172C20.1739 4.08037 20.1481 4.0744 20.1219 4.07422H8.87812C8.82616 4.07422 8.77421 4.09883 8.7414 4.13984L3.19062 11.077C3.16492 11.1095 3.1514 11.15 3.1524 11.1914C3.15339 11.2329 3.16885 11.2727 3.19609 11.3039L14.3687 23.8684C14.4016 23.9066 14.4508 23.9258 14.5 23.9258C14.5492 23.9258 14.5957 23.9039 14.6312 23.8684L25.8039 11.3039C25.8311 11.2727 25.8466 11.2329 25.8476 11.1914C25.8486 11.15 25.8351 11.1095 25.8094 11.077ZM18.1422 5.64375L19.0828 9.30508L15.4598 5.64375H18.1422ZM18.3664 10.5246H10.6336L14.5 6.61719L18.3664 10.5246ZM10.8578 5.64375H13.5402L9.91444 9.30508L10.8578 5.64375ZM9.39765 5.83516L8.19179 10.5246H5.64609L9.39765 5.83516ZM5.82109 11.8918H8.40234L11.8422 18.6648L5.82109 11.8918ZM14.5 20.8742L9.93632 11.8918H19.0609L14.5 20.8742ZM17.1551 18.6648L20.5976 11.8918H23.1789L17.1551 18.6648ZM20.8082 10.5246L19.6023 5.83516L23.3539 10.5246H20.8082Z"
      fill="url(#paint0_linear_3264_38118)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3264_38118"
        x1="25.8476"
        y1="14"
        x2="3.15234"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6934F" />
        <stop offset="0.498191" stopColor="#E186B0" />
        <stop offset="1" stopColor="#78C0EA" />
      </linearGradient>
    </defs>
  </svg>
);

export const StarIcon: React.FC = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3303 9.6547L18.3877 8.64571L15.2842 2.35392C15.1994 2.18165 15.06 2.0422 14.8877 1.95743C14.4557 1.74415 13.9307 1.92189 13.7146 2.35392L10.6111 8.64571L3.66855 9.6547C3.47714 9.68204 3.30214 9.77228 3.16816 9.90899C3.00618 10.0755 2.91692 10.2995 2.92 10.5317C2.92308 10.764 3.01823 10.9855 3.18457 11.1477L8.20761 16.0449L7.02089 22.9602C6.99307 23.121 7.01087 23.2865 7.07228 23.4377C7.13369 23.589 7.23626 23.72 7.36835 23.816C7.50044 23.9119 7.65676 23.9689 7.8196 23.9805C7.98244 23.9921 8.14528 23.9579 8.28964 23.8817L14.4994 20.6168L20.7092 23.8817C20.8787 23.9719 21.0756 24.002 21.2643 23.9692C21.74 23.8871 22.06 23.4359 21.9779 22.9602L20.7912 16.0449L25.8143 11.1477C25.951 11.0137 26.0412 10.8387 26.0686 10.6473C26.1424 10.1688 25.8088 9.72579 25.3303 9.6547ZM18.6775 15.3559L19.6646 21.1063L14.4994 18.3938L9.33418 21.109L10.3213 15.3586L6.14316 11.2844L11.9182 10.4449L14.4994 5.21407L17.0807 10.4449L22.8557 11.2844L18.6775 15.3559Z"
      fill="#F180B2"
    />
  </svg>
);
