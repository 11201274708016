import React from 'react';
import NumberFormat from 'react-number-format';
import { ReadOnlyPriceInput } from './styled';

type CommonPriceInputProps = {
  value: string | number;
};

export const CommonPriceInputReadOnly: React.FC<CommonPriceInputProps> = ({
  value,
}) => (
  <ReadOnlyPriceInput>
    <NumberFormat
      prefix="$"
      placeholder="$0.00"
      thousandSeparator=" "
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      autoComplete="off"
      value={value}
      disabled
    />
  </ReadOnlyPriceInput>
);
