import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  PlanCardWrapper,
  PlanCardContainer,
  PlanCardContent,
  DescriptionWrapper,
} from './styled';
import { ChosenPlan, Plan } from '../../../../../shared/types';
import {
  LittlePinkText,
  MediumBlackText,
  PrimaryWhiteButton,
} from '../../../../../shared/styles';
import { ButtonMgT } from '../../UsageAndBilling/styled';
import { PlanCardDetails } from './PlanCardDetails';
import { PlanCardSwitch } from './PlanCardSwitch';
import { PlanCardTitleAndPrice } from './PlanCardTitleAndPrice';

type PlanCardType = {
  handleChoosePlan: (plan: ChosenPlan) => void;
  chosen: boolean;
  planData: Plan;
};

export const PlanCard: React.FC<PlanCardType> = ({
  handleChoosePlan,
  chosen,
  planData,
}) => {
  const { t } = useTranslation('settings');
  const [isAnnually, setIsAnnually] = useState(false);

  const handleSwitch = () => {
    setIsAnnually((prev) => !prev);
  };

  const handleChoose = () => {
    const price = isAnnually ? planData.priceAnnually : planData.priceMonthly;
    handleChoosePlan({
      planType: planData.planType,
      cost: price,
      isAnnually,
      monthlyCost: planData.priceMonthly,
    });
  };

  useEffect(() => {
    if (chosen) {
      handleChoose();
    }
  }, [isAnnually]);

  return (
    <Grid item sm={11} key={planData.planType}>
      <PlanCardContainer>
        <PlanCardWrapper chosen={chosen} border={planData.planType}>
          <PlanCardContent chosen={chosen}>
            <PlanCardSwitch
              handleSwitch={handleSwitch}
              planType={planData.planType}
              isAnnually={isAnnually}
            />
            <PlanCardTitleAndPrice
              isAnnually={isAnnually}
              planType={planData.planType}
              priceMonthly={planData.priceMonthly}
              priceAnnually={planData.priceAnnually}
            />
            {planData.current && (
              <div>
                <LittlePinkText>{t('currentPlan')}</LittlePinkText>
              </div>
            )}
            <DescriptionWrapper>
              <MediumBlackText>{planData.description}</MediumBlackText>
            </DescriptionWrapper>
            <PlanCardDetails details={planData.details} />
            {!planData.current && !chosen && (
              <ButtonMgT>
                <PrimaryWhiteButton type="button" onClick={handleChoose}>
                  {t('choosePlan')}
                </PrimaryWhiteButton>
              </ButtonMgT>
            )}
          </PlanCardContent>
        </PlanCardWrapper>
      </PlanCardContainer>
    </Grid>
  );
};
