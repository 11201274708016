import React from 'react';
import { ShapeFirst, ShapeSecond } from '../../svgs/backgroundShapes';
import { BackgroundWithShapesWrapper } from './styled';

export const BackgroundWithShapes: React.FC = () => (
  <BackgroundWithShapesWrapper>
    <BackgroundWithShapesWrapper>
      <ShapeFirst />
    </BackgroundWithShapesWrapper>
    <BackgroundWithShapesWrapper>
      <ShapeSecond />
    </BackgroundWithShapesWrapper>
  </BackgroundWithShapesWrapper>
);
