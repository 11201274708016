import React, { ChangeEvent } from 'react';
import { CheckboxInput, CustomCheckbox } from './styled';

type CommonCheckboxProps = {
  id?: string | number;
  isChecked: boolean;
  name: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  text?: string;
  simple?: boolean;
};

export const CommonCheckbox: React.FC<CommonCheckboxProps> = ({
  isChecked,
  name,
  handleChange,
  text,
  simple,
  id,
}) => {
  const content = (
    <>
      <input
        id={`${id}`}
        onChange={handleChange}
        name={name}
        type="checkbox"
        checked={isChecked}
      />
      <span />
      {text && <div>{text}</div>}
    </>
  );
  return simple ? (
    <CheckboxInput>{content}</CheckboxInput>
  ) : (
    <CustomCheckbox>{content}</CustomCheckbox>
  );
};
