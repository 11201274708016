import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBlackTextPinkHover } from '../../shared/styles';
import { GalleryData } from '../../shared/types';
import {
  NavigationTitle,
  NavigationWrapper,
  SectionNavigationList,
} from './styled';

export const aboveSectionTitle = 100;

export const Navigation: React.FC = () => {
  const { t } = useTranslation('gallery');
  const { values } = useFormikContext<GalleryData>();

  const handleClick = (id: string) => {
    const sectionItem = document.getElementById(id);
    const mainElement = document.querySelector('#scrolled-container') || window;
    const sectionElY = (sectionItem?.offsetTop || aboveSectionTitle) - aboveSectionTitle;
    mainElement?.scrollTo({
      top: sectionElY,
      behavior: 'smooth',
    });
  };

  return (
    <NavigationWrapper>
      <NavigationTitle>{t('navigation')}</NavigationTitle>
      <SectionNavigationList>
        {values.sections.map((item) => (
          <ButtonBlackTextPinkHover
            type="button"
            onClick={() => handleClick(item.id)}
            key={item.id}
          >
            {item.title}
          </ButtonBlackTextPinkHover>
        ))}
      </SectionNavigationList>
    </NavigationWrapper>
  );
};
