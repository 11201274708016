import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommonInput } from '../../shared/components/CommonInput';
import { FormikCustomProps, SignUpInProcess } from '../../shared/types';
import { CustomGrid, PrimaryWhiteButton } from '../../shared/styles';
import { ButtonWrapper } from '../styled';

type SignUpFirstStepType = FormikCustomProps<SignUpInProcess>;

export const SignUpFirstStep: React.FC<SignUpFirstStepType> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
}) => {
  const { t } = useTranslation(['basicComponents', 'signUp']);

  return (
    <>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.firstName}
          value={values.firstName}
          name="firstName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Your first name"
          touched={touched.firstName}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.lastName}
          value={values.lastName}
          name="lastName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Your second name"
          touched={touched.lastName}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.businessName}
          value={values.businessName}
          name="businessName"
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          placeholder="Business name"
          touched={touched.businessName}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.password}
          value={values.password}
          name="password"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          placeholder="Password"
          touched={touched.password}
        />
      </CustomGrid>
      <CustomGrid item sm={9}>
        <CommonInput
          error={errors.confirmPassword}
          value={values.confirmPassword}
          name="confirmPassword"
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          placeholder={t('signUp:confirmPasswordPlaceholder')}
          touched={touched.confirmPassword}
        />
      </CustomGrid>
      <CustomGrid item sm={8}>
        <ButtonWrapper>
          <PrimaryWhiteButton type="submit" fullWidth>
            {t('basicComponents:buttons.next')}
          </PrimaryWhiteButton>
        </ButtonWrapper>
      </CustomGrid>
    </>
  );
};
