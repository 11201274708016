import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import {
  GalleryData,
  GalleryFile,
  GalleryIdea,
  GallerySection,
} from '../../../shared/types';
import { getIndex } from '../../../../utils/galleryCreator';
import { ImagesList } from './styled';
import { ImageOrVideoCard } from './ImageOrVideoCard';

type ImagesAndVideosListType = {
  files: (GalleryFile | GalleryIdea)[];
  section: GallerySection;
};

export const ImagesAndVideosList: React.FC<ImagesAndVideosListType> = ({
  files,
  section,
}) => {
  const { values } = useFormikContext<GalleryData>();

  const indexSection = useMemo(
    () => getIndex(values.sections, section.id),
    [section, values.sections],
  );

  return (
    <Grid container item sm={12}>
      <ImagesList>
        {files.map((item) => (
          <ImageOrVideoCard
            key={item.id}
            fileOrIdea={item}
            indexSection={indexSection}
          />
        ))}
      </ImagesList>
    </Grid>
  );
};
