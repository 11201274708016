import styled from '@emotion/styled';

export const FormBase = styled.div`
  margin-top: 18px;
  width: 100%;

  & form {
    display: flex;
    flex-direction: ${({ theme }: { theme: 'row' | 'column' }) => theme};
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;
