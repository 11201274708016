import { Grid } from '@material-ui/core';
import { FormikErrors, useFormikContext, FormikTouched } from 'formik';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFileType, GalleryData, GalleryIdea } from '../../../../../shared/types';
import {
  CoverWrapper,
  MiniFileCardsContainer,
} from './styled';
import { PlusIcon } from '../styled';
import { MiniFileCard } from '../../../../../shared/components/GalleryCreator/MiniFileCard';
import { ErrorMessage } from '../../../../../shared/components/styled';
import { getFileInContainer } from '../../../../../../utils/galleryCreator';
import { getURLByFile } from '../../../../../../utils';
import { MiniChooseFileCard } from '../../../../../shared/components/GalleryCreator/styled';

type AttachedFilesType = {
  idea: GalleryIdea;
  ideaField: string;
  errors: FormikErrors<GalleryIdea> | null;
  touched: FormikTouched<GalleryIdea> | null;
};
const arr = [null, null, null];

export const AttachedFiles: React.FC<AttachedFilesType> = ({
  idea,
  ideaField,
  errors,
  touched,
}) => {
  const { t } = useTranslation('createNewGallery');
  const [isChecked, setIsChecked] = useState<string | null>(null);
  const { setFieldValue } = useFormikContext<GalleryData>();

  const arrFiles = useMemo(
    () => {
      const endIndex = idea.files.length > 3 ? 0 : 3 - idea.files.length;
      return [...idea?.files, ...arr.slice(0, endIndex)];
    },
    [idea?.files],
  );

  const handleClick = (files: File[]) => {
    const newFiles = files.map((item) => ({
      url: getURLByFile(item),
      type: item.type,
      name: item.name,
    }));
    const allFiles = [...idea?.files, ...newFiles];
    setFieldValue(`${ideaField}.files`, Array.from(new Set(allFiles)));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, file: CustomFileType) => {
    setIsChecked(e.target.id);
    if (typeof file !== 'number') setFieldValue(`${ideaField}.cover`, file);
  };

  const coverFile = useMemo(
    () => getFileInContainer(idea?.cover),
    [idea?.cover],
  );

  return (
    <Grid container item sm={4} justifyContent="flex-end">
      <Grid item sm={10} style={{ position: 'relative' }}>
        <CoverWrapper>
          {idea.cover ? coverFile : <p>{t('secondStep.chooseIdeaCover')}</p>}
        </CoverWrapper>
        {errors?.cover && touched?.cover && (
          <ErrorMessage bottom={15} left={0}>
            {errors?.cover}
          </ErrorMessage>
        )}
      </Grid>
      <Grid
        container
        item
        sm={10}
        direction="row"
        justifyContent="space-between"
      >
        <MiniFileCardsContainer width={((arrFiles?.length + 1) * 122) / 2}>
          <div>
            <MiniChooseFileCard>
              <input
                name={`${ideaField}.cover`}
                type="file"
                multiple
                onChange={(e) => handleClick(Array.from(e.target.files || []))}
              />
              <PlusIcon theme="small" />
            </MiniChooseFileCard>
            {arrFiles.map((item) => (
              <MiniFileCard
                key={typeof item === 'number' ? item : item?.name}
                file={item}
                handleCheck={handleCheck}
                isChecked={typeof item !== 'number' && isChecked === item?.name}
                id={item?.name}
              />
            ))}
          </div>
        </MiniFileCardsContainer>
      </Grid>
    </Grid>
  );
};
