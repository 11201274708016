import React, { ChangeEvent } from 'react';
import NumberFormat from 'react-number-format';
import {
  CommonInputWrapper, TextLabel, InputWrapper, InputBase, ErrorMessage,
} from '../styled';

type CommonPriceInputProps = {
  error?: string | any;
  value: string | any;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean | any;
  label?: string;
  direction?: 'row' | 'column';
  disabled?: boolean;
};

export const CommonPriceInput: React.FC<CommonPriceInputProps> = ({
  error,
  value,
  name,
  handleBlur,
  handleChange,
  touched,
  direction,
  disabled,
  label,
}) => (
  <CommonInputWrapper>
    <InputBase
      color={`${error && touched ? 'error' : 'success'}`}
      direction={direction || 'column'}
    >
      {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
      <InputWrapper>
        <NumberFormat
          prefix="$"
          placeholder="$0.00"
          thousandSeparator=" "
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          autoComplete="off"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          disabled={disabled}
          onValueChange={({ floatValue }) => floatValue}
        />
        {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
      </InputWrapper>
    </InputBase>
  </CommonInputWrapper>
);
