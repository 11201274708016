import React, {
  SyntheticEvent, useState,
} from 'react';
import { ImageElement, ImageWrapper } from './styled';

type ImageContainerType = {
  imageUrl: string;
  widthStretch?: boolean;
};

export const ImageContainer: React.FC<ImageContainerType> = ({
  imageUrl,
  widthStretch,
}) => {
  const [isWidthBigger, setIsWidthBigger] = useState(false);
  const handleOnLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    setIsWidthBigger(e.currentTarget.height < e.currentTarget.width);
  };

  return (
    <ImageWrapper widthStretch={!!widthStretch}>
      {imageUrl && (
        <ImageElement
          isWidthBigger={isWidthBigger}
          onLoad={handleOnLoad}
          src={imageUrl}
          alt={imageUrl}
          loading="lazy"
        />
      )}
    </ImageWrapper>
  );
};
