import i18next from 'i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getConfig as getI18nextConfig } from '../../translations';
import { MainContainer } from '../MainContainer';
import store from '../../Redux/configureStore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const stripePromise = loadStripe('pk_test_51K7dsSIet3CO46kQ2f7drCvnX7Mz2BnzkuAaQDV1cCuTopuznkhKkaKgpBzqMDFgSSxSSwohKsZGWjTpq8uCAuO300eigBq8Dk');

i18next.init(getI18nextConfig());

export const App: React.FC = () => (
  <Provider store={store}>
    <Router basename="/">
      <I18nextProvider i18n={i18next}>
        <Elements stripe={stripePromise}>
          <MainContainer />
        </Elements>
      </I18nextProvider>
    </Router>
  </Provider>
);
