import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MainLogo } from '../../../../shared/icons';
import { MediumBlackText } from '../../../../shared/styles';
import { GalleryPreviewHeaderWrapper, HeaderTextWrapper } from './styled';

type GalleryPreviewHeaderType = {
  children: ReactNode;
}

export const GalleryPreviewHeader: React.FC<GalleryPreviewHeaderType> = ({ children }) => {
  const { t } = useTranslation('createNewGallery');

  return (
    <GalleryPreviewHeaderWrapper id="moveToTheNext">
      <div><MainLogo /></div>
      <HeaderTextWrapper>
        <MediumBlackText>{t('fifthStep.alert')}</MediumBlackText>
      </HeaderTextWrapper>
      {children}
    </GalleryPreviewHeaderWrapper>
  );
};
