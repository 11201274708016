export const rootUrl = '/';

export const signInUrl = '/signin';

export const signUpUrl = '/signup';
export const signUpInitialUrl = `${signUpUrl}/initial`;
export const signUpConfirmWasSentUrl = `${signUpInitialUrl}/thanks`;
export const signUpConfirmUrl = `${signUpUrl}/confirm`;

export const resetUrl = '/reset';
export const resetSuccessUrl = `${resetUrl}/success`;

export const accountUrl = '/creator-account';

export const changePlanUrl = '/changeplan';
export const paymentMethodUrl = '/payment';

export const newGalleryUrl = `${accountUrl}/new-gallery`;
export const salesUrl = `${accountUrl}/sales`;
export const activeGalleriesUrl = `${accountUrl}/active-gallery`;
export const draftGalleriesUrl = `${accountUrl}/draft-gallery`;
export const settingsUrl = `${accountUrl}/settings`;
export const supportUrl = `${accountUrl}/support`;
export const notificationsUrl = `${accountUrl}/notifications`;

export const youTubeEmbed = 'https://www.youtube.com/embed/';
export const dilivrableGalleryURL = 'delivrable.io/';
export const httpUrl = 'http://';
export const delivrableUrl = 'delivrable.com';

export const youtubeChannel = 'https://www.youtube.com/channel/UCVhi0lEdjVBc3_UWrGhN8hA';
