import styled from '@emotion/styled';
import * as variables from '../../constants/styleVariables';

export const TitleText = styled.h1`
  color: ${variables.colorGreyLight};
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  line-height: 110%;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const LittleText = styled.span`
  color: ${variables.colorGreyLight};
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
  margin-bottom: 10px;

  & > a {
    margin-left: 5px;
    margin-right: 5px;
    color: ${variables.colorGreyLight};
    border-bottom: 1px solid ${variables.colorGreyLight};
  }
`;

export const ButtonSubmitSignUp = styled.button`
  box-shadow: -2px -2px 5px #eea2af, 2px 2px 5px #e17e94;
  border-radius: 51px;
  background: ${variables.colorGreyLight};
  text-transform: uppercase;
  font-weight: 600;
  line-height: 150%;
  font-size: 16px;
  height: 44px;
  width: 120px;
  color: ${variables.colorBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: -3px -3px 8px #eea2af, 7px 7px 14px #e17e94;
  }

  &:active {
    box-shadow: inset -5px -5px 10px #fff, inset 5px 5px 10px #e8e8e8;
  }

  &: disabled {
    cursor: auto;
    color: ${variables.colorBlueLight};
  }
`;

export const TextNote = styled.span`
  margin-top: 24px;
  font-size: 12px;
  line-height: 18px;
  color: ${variables.textBlack};
`;

export const TextInstructions = styled.span`
  font-size: 14px;
  line-height: 150%;
  color: ${variables.textGrey};
  text-align: center;
  margin-top: 14px;
  max-width: 348px;
`;
export const WidthWrapper = styled.div`
  width: 338px;
  margin-top: 10px;
`;

export const ProgressBar = styled.div`
  width: 336px;
  height: 5px;
  background: #f8f8f8;
  box-shadow:
    2px 2px 5px #d8d8d8,
    inset -5px -5px 10px #fff,
    inset 2px 2px 5px #e7e7e7;
  margin-bottom: 36px;
  border-radius: 43px;
  box-sizing: border-box;
  padding: 1px;

  & div {
    border-radius: 43px;
    transition: width .3s ease-in-out;
    width: ${({ theme }: { theme: number }) => `${theme}%`};
    height: 3px;
    background: ${variables.colorPink};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 16px;
`;

export const TextPolicyWrapper = styled.div``;
