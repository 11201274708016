import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useFormikContext } from 'formik';
import { TextLabel } from '../../../../shared/components/styled';
import {
  ErrorMessageCustom,
  SwiperHeaderContainer,
} from './styled';
import { GalleryData } from '../../../../shared/types';
import { ArrowButtons } from '../../../../shared/components/ArrowButtons/ArrowButtons';

type SwiperHeaderProps = {
  customSlider: MutableRefObject<Slider | null | undefined>;
};

export const SwiperHeader: React.FC<SwiperHeaderProps> = ({ customSlider }) => {
  const { t } = useTranslation('createNewGallery');
  const { errors, touched } = useFormikContext<GalleryData>();

  return (
    <SwiperHeaderContainer>
      <div>
        <TextLabel>{t('firstStep.chooseGalleryDesign')}</TextLabel>
        {errors?.galleryDesign && touched?.galleryDesign && (
          <ErrorMessageCustom>{errors.galleryDesign}</ErrorMessageCustom>
        )}
      </div>
      <ArrowButtons
        handleLeftClick={() => customSlider.current?.slickPrev()}
        handleRightClick={() => customSlider.current?.slickNext()}
      />
    </SwiperHeaderContainer>
  );
};
