import React, { ChangeEvent, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { OnChangeValue } from 'react-select';
import { SetFieldValue, Option } from '../../types';
import {
  CommonInputWrapper,
  ErrorMessage,
} from '../styled';
import { getInputChipsStyles, InputChipsWrapper } from './styled';
import { MultiValueRemove } from './MultiValueRemove';

type InputChipsProps = {
  error?: string | any;
  values: string | any;
  name: string;
  placeholder?: string;
  touched?: boolean | any;
  setFieldValue: SetFieldValue;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export const InputChips: React.FC<InputChipsProps> = ({
  values,
  name,
  placeholder,
  error,
  setFieldValue,
  touched,
  handleBlur,
}) => {
  const [value, setValue] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const handleChange = (newValue: OnChangeValue<Option, true>) => {
    setValue(newValue);
    const valuesArray = newValue.map((item) => item.value) || [];
    setFieldValue(name, valuesArray);
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Tab') {
      setInputValue('');
      setValue([...value, createOption(inputValue)]);
      setFieldValue(name, [...values, createOption(inputValue).value]);
      event.preventDefault();
    }
  };
  const styles = useMemo(() => getInputChipsStyles(error, touched), [error, touched]);
  return (
    <CommonInputWrapper>
      <InputChipsWrapper>
        <CreatableSelect
          id={name}
          styles={styles}
          inputValue={inputValue}
          isClearable
          isMulti
          components={{ MultiValueRemove, DropdownIndicator: null }}
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={value}
          name={name}
          onBlur={handleBlur}
        />
      </InputChipsWrapper>
      {error && touched ? <ErrorMessage>{typeof error === 'string' ? error : error[0]}</ErrorMessage> : null}
    </CommonInputWrapper>
  );
};
