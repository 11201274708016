import { useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GalleryDataFormType } from '../../../../shared/types';
import { FormGalleryWrapper } from '../styled';
import { Section } from './Section';
import { ButtonAdd } from './ButtonAdd';
import { OnboardingContext } from '../../../../../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../../../../../context/OnboardingStepIndexContext';

export const NewGalleryCreatorSecondStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { values, setValues } = useFormikContext<GalleryDataFormType>();
  const { isStudy } = useContext(OnboardingContext);
  const { setStepIndex } = useContext(OnboardingStepIndexContext);

  useEffect(() => {
    if (isStudy) {
      setStepIndex((prev: number) => prev + 1);
    }
  }, []);

  const handleClick = () => {
    const data = values;
    const sectionNumber = values?.sections?.length + 1;
    data?.sections?.push({
      id: uuidv4(),
      title: `Section ${sectionNumber}`,
      files: [],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    });
    setValues(data);
  };
  return (
    <>
      <FormGalleryWrapper>
        {values?.sections?.map((item) => (
          <Section key={item.id} section={item} />
        ))}
      </FormGalleryWrapper>
      <ButtonAdd
        id="addSectionSecondStep"
        handleClick={handleClick}
        size="large"
        text={t('secondStep.addSection')}
      />
    </>
  );
};
