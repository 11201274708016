import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignUpErrors } from '../../../containers/shared/types';
import { fetchSignUpCreator, fetchSignUpInitialCreator } from './signUpCreatorThunk';

type initialState = {
  isSignUp: boolean;
  wasSent: boolean;
  loading: boolean;
  error: SignUpErrors | null;
};

export const initialState: initialState = {
  isSignUp: false,
  loading: false,
  error: null,
  wasSent: false,
};

const signUpCreatorSlice = createSlice({
  name: 'signUpCreator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignUpInitialCreator.fulfilled, (state, action: PayloadAction<any>) => {
      state.isSignUp = false;
      state.error = null;
      state.loading = false;
      state.wasSent = true;
    });
    builder.addCase(
      fetchSignUpInitialCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isSignUp = false;
        state.loading = false;
        state.wasSent = false;
      },
    );
    builder.addCase(fetchSignUpInitialCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSignUpCreator.fulfilled, (state) => {
      state.isSignUp = true;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchSignUpCreator.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.isSignUp = false;
      },
    );
    builder.addCase(fetchSignUpCreator.pending, (state) => {
      state.loading = true;
    });
  },
});

export default signUpCreatorSlice.reducer;
