import { Grid } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { GalleryData } from '../../../../shared/types';
import {
  FormGalleryWrapper,
  GridFitByContent,
  GridWrapper,
} from '../styled';
import { CustomDatePicker } from '../../../../shared/components/CustomDatePicker/CustomDatePicker';
import { CommonSwitch } from '../../../../shared/components/CommonSwitch/CommonSwitch';
import { InputChips } from '../../../../shared/components/InputChips/InputChips';
import { GridFitContent } from './styled';
import { FourthStagePremiumContent } from './FourthStagePremiumContent';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { OnboardingContext } from '../../../../../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../../../../../context/OnboardingStepIndexContext';

export const NewGalleryCreatorFourthStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { isStudy } = useContext(OnboardingContext);
  const { setStepIndex } = useContext(OnboardingStepIndexContext);

  useEffect(() => {
    if (isStudy) {
      setStepIndex((prev: number) => prev + 1);
    }
  }, []);

  const {
    values, errors, handleChange, handleBlur, touched, setFieldValue,
  } = useFormikContext<GalleryData>();

  return (
    <FormGalleryWrapper>
      <GridWrapper container spacing={4} direction="row" justifyContent="space-between">
        <Grid container item sm={5} spacing={1} alignContent="flex-start">
          <div className="access-to-gallery-fourth-step" style={{ marginTop: '15px' }}>
            <GridFitContent item sm={12} style={{ marginTop: '-15px' }}>
              <CommonSwitch
                isChecked={values.hasRegistrationEmail}
                name="hasRegistrationEmail"
                label={t('fourthStep.emailRegistration')}
                error={errors.hasRegistrationEmail}
                touched={touched.hasRegistrationEmail}
                shortLabel
                helpText={t('fourthStep.helpTexts.email')}
                positionHelpText="top"
                handleChange={handleChange}
              />
            </GridFitContent>
            {values.hasRegistrationEmail && (
            <GridFitContent item sm={12}>
              <InputChips
                values={values.registrationEmail}
                name="registrationEmail"
                placeholder={t('fourthStep.emailPlaceholder')}
                error={errors.registrationEmail}
                touched={touched.registrationEmail}
                setFieldValue={setFieldValue}
              />
            </GridFitContent>
            )}
            <GridFitContent item sm={12}>
              <CommonInput
                label={t('fourthStep.setPassword')}
                value={values.password}
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="row"
                error={errors?.password}
                touched={touched?.password}
                type="text"
              />
            </GridFitContent>
          </div>
          <GridFitContent item sm={10}>
            <CommonSwitch
              isChecked={values.sharing}
              handleChange={handleChange}
              name="sharing"
              label={t('fourthStep.enableSharing')}
              shortLabel
            />
          </GridFitContent>
          <GridFitContent item sm={8}>
            <CommonSwitch
              isChecked={values.assist}
              handleChange={handleChange}
              name="assist"
              label={t('fourthStep.galleryAssist')}
              shortLabel
              helpText={t('fourthStep.helpTexts.assist')}
              positionHelpText="top"
              id="gallery-assist-fourth-step"
              spaceBetween
            />
          </GridFitContent>
          <GridFitContent item sm={10}>
            <CommonSwitch
              isChecked={!!values.hasExpiryDate}
              handleChange={handleChange}
              name="hasExpiryDate"
              label={t('fourthStep.setDate')}
              shortLabel
            />
          </GridFitContent>
          {values.hasExpiryDate && (
          <Grid item sm={8}>
            <CustomDatePicker
              name="expiryDate"
              data={values.expiryDate}
              setFieldValue={setFieldValue}
              touched={touched.expiryDate}
              error={errors.expiryDate}
            />
          </Grid>
          )}
        </Grid>
        <FourthStagePremiumContent />
      </GridWrapper>
    </FormGalleryWrapper>
  );
};
