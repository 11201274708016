import React from 'react';
import { EmailNotificationsAndBranding } from './EmailNotificationsAndBranding';
import { PaymentCurrency } from './PaymentCurrency';
import { Watermark } from './Watermark';

export const AdvancedSettings: React.FC = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Watermark />
    <PaymentCurrency />
    <EmailNotificationsAndBranding />
  </div>
);
