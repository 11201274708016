import React from 'react';
import { SetFieldValue } from '../../types';
import { ErrorMessage } from '../styled';
import {
  RadioWrapper,
  RadioLabel,
  CustomMarginTextLabel,
  RadioCustomErrorMessage,
} from './styled';

type CommonRadioButton = {
  value: string;
  label: string;
  error?: string;
  nameGroup?: string;
  nameRadio: string;
  touched?: boolean;
  setFieldValue: SetFieldValue;
  likeCheckbox?: boolean;
};

export const CommonRadioButton: React.FC<CommonRadioButton> = ({
  label,
  nameRadio,
  error,
  touched,
  value,
  setFieldValue,
  nameGroup,
  likeCheckbox,
}) => {
  const handleClick = () => {
    const nextValue = likeCheckbox && value === nameRadio ? '' : nameRadio;
    setFieldValue(nameGroup || nameRadio, nextValue);
  };

  return (
    <>
      <RadioWrapper>
        <RadioLabel>
          <input
            type="radio"
            name={nameRadio}
            value={value}
            onClick={handleClick}
            checked={value === nameRadio}
          />
          <div />
        </RadioLabel>
        {label && (
          <CustomMarginTextLabel onClick={handleClick} htmlFor={nameRadio}>
            {label}
          </CustomMarginTextLabel>
        )}
        <RadioCustomErrorMessage>
          {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
        </RadioCustomErrorMessage>
      </RadioWrapper>
    </>
  );
};
