import { SignUpFields } from '../../containers/shared/types';

export const getSignUpFieldFullName = (field: SignUpFields): string => {
  switch (field) {
    case 'businessName':
      return 'business name';
    case 'promoCode':
      return 'promo code';
    case 'email':
      return 'email';
    default:
      return '';
  }
};
