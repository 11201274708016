import * as React from 'react';
import { SupportQuestions } from '../../../../shared/types';
import { TextBlackLarge } from '../../../../shared/styles';
import { AccordionsWrapper, SimpleGreyTextCustomColor } from './styled';
import { CustomAccordion } from './CustomAccordion';
import { CustomAccordionSummary } from './CustomAccordionSummary';
import { CustomAccordionDetails } from './CustomAccordionDetails';

type FAQAccordionType = {
  questions: SupportQuestions[];
};

export const FAQAccordion: React.FC<FAQAccordionType> = ({ questions }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <AccordionsWrapper>
      {questions?.map((item) => (
        <CustomAccordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <CustomAccordionSummary
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <TextBlackLarge>{item.question}</TextBlackLarge>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <SimpleGreyTextCustomColor>{item.answer}</SimpleGreyTextCustomColor>
          </CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </AccordionsWrapper>
  );
};
