import { useFormik } from 'formik';
import React, {
  Dispatch, FormEvent, SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWindowContainer } from '../../../../../shared/components/ModalWindow/ModalWindowContainer';
import { CloseModalWindowButton } from '../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import {
  PrimaryWhiteButton,
  ButtonWithoutShadow,
  TitleText,
} from '../../../../../shared/styles';
import { ButtonsWrapper } from '../../../NewGalleryCreator/styled';
import { ConfirmCurrentPasswordWindowContainer } from './styled';
import { CommonInput } from '../../../../../shared/components/CommonInput';
import { TitleMgB } from '../../styled';
import { confirmPassword } from '../../../../../shared/validation/settingsSchemas';

type ConfirmCurrentPasswordWindowType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsConfirmed: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmCurrentPasswordWindow: React.FC<ConfirmCurrentPasswordWindowType> = ({
  isOpen,
  setIsOpen,
  setIsConfirmed,
}) => {
  const { t } = useTranslation(['settings', 'basicComponents']);

  const onSubmit = (data: any) => {
    console.log('submit');
    setIsConfirmed(true);
    setIsOpen(false);
  };

  const {
    values, handleChange, handleBlur, handleSubmit, errors, touched, resetForm,
  } = useFormik<{ currentPassword: string }>({
    validationSchema: confirmPassword,
    initialValues: { currentPassword: '' },
    onSubmit: (data) => onSubmit(data),
  });

  const handleClose = () => {
    setIsOpen(false);
    resetForm();
  };

  const handleConfirm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(e);
  };

  return (
    <ModalWindowContainer
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
    >
      <form onSubmit={handleConfirm}>
        <ConfirmCurrentPasswordWindowContainer>
          <TitleMgB>
            <TitleText>{t('settings:changePassword')}</TitleText>
          </TitleMgB>
          <CommonInput
            value={values.currentPassword}
            name="currentPassword"
            handleChange={handleChange}
            handleBlur={handleBlur}
            direction="column"
            placeholder={t('placeholders.currentPassword')}
            error={errors?.currentPassword}
            touched={touched?.currentPassword}
            type="password"
          />
          <ButtonsWrapper>
            <PrimaryWhiteButton
              type="submit"
              // onClick={handleConfirm}
              style={{ marginRight: '40px' }}
            >
              {t('settings:confirmButton')}
            </PrimaryWhiteButton>
            <ButtonWithoutShadow type="button" onClick={handleClose}>
              {t('basicComponents:buttons.cancel')}
            </ButtonWithoutShadow>
          </ButtonsWrapper>
        </ConfirmCurrentPasswordWindowContainer>
      </form>
      <CloseModalWindowButton
        handleClick={handleClose}
        color="black"
      />
    </ModalWindowContainer>
  );
};
