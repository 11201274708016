import styled from '@emotion/styled';
import * as variables from '../../../../../constants/styleVariables';

export const PreviewWrapper = styled.div`
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > button,
  > u {
    font-size: 14px;
    line-height: 150%;
    color: ${variables.textGrey};
    visibility: hidden;
    width: 307px;
    text-align: center;
  }

  &:hover {
    & > button,
    > u {
      visibility: visible;
    }
  }
`;
export const PreviewCard = styled.div`
  width: 307px;
  height: 200px;
  min-height: 200px;
  background: #f0f1f2;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 19px 13px;
  box-sizing: border-box;
  border:
    2px solid
    ${({ theme }) => (theme ? variables.colorPink : variables.colorGreyLight)};
`;
export const TextGallerySecond = styled.div`
  font-weight: normal;
  font-size: 4.74902px;
  line-height: 140%;
  text-align: start;
  max-width: 90px;

  & h3 {
    margin-top: 0;
    font-weight: normal;
    font-size: 9.49804px;
    line-height: 13px;
  }
`;
export const ImageContainerSecond = styled.div`
  width: 184px;
  height: 160px;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;

  & span {
    width: 40px;
    position: absolute;
    font-weight: normal;
    font-size: 4.74902px;
    line-height: 6px;
    transform: rotate(-90deg);
    left: -26px;
    bottom: 10px;
  }
`;

export const PreviewCardFirst = styled(PreviewCard)`
  align-items: center;
  padding-left: 9px;
`;
export const PreviewCardThird = styled(PreviewCard)`
  padding: 13px;
  position: relative;
`;
export const ImageContainerThird = styled.div`
  position: absolute;
  top: 13;
  left: 13;
  width: 278px;
  height: 170px;
  overflow: hidden;
  object-fit: cover;
  background: ${variables.colorBleak};
  z-index: 1;
`;
export const TextContainerThird = styled.div`
  height: 100%;
  z-index: 10;
  width: 96px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2.87169px);
  -webkit-backdrop-filter: blur(2.87169px);
  padding: 28px 11px 28px 9px;
  text-align: start;
  font-weight: 300;
  font-size: 4.02037px;
  line-height: 145%;

  & div:last-child {
    margin-top: 5px;
    text-align: end;
  }

  & h3 {
    font-weight: 400;
    font-size: 9.4868px;
    line-height: 14px;
    margin-bottom: 0;
  }
`;
export const PreviewCardFourth = styled(PreviewCard)`
  padding: 53px 12px;
`;
export const TextContainerFourth = styled(TextGallerySecond)`
  font-size: 4.59314px;
  line-height: 140%;
  max-width: 88px;

  & h3 {
    font-size: 8.03799px;
    line-height: 10px;
    width: 88px;
  }
`;
export const ImageContainerFourth = styled.div`
  width: 88px;
  height: 92px;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  margin-right: 9px;
`;

export const SquareBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 39px);
  gap: 14px 9px;
  grid-template-rows: repeat(2, 39px);
  font-size: 4.59314px;
  line-height: 140%;
`;
export const DateCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  text-align: start;
`;
export const CreatorCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 1;
  text-align: start;
`;
export const ImageFirstCell = styled.div`
  grid-column: 2 / 2;
  grid-row: 2 / 1;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;
export const ImageSecondCell = styled.div`
  grid-column: 2 / 1;
  grid-row: 2 / 2;
  background: ${variables.colorBleak};
  overflow: hidden;
  object-fit: cover;
  position: relative;
`;

export const ClosePreviewButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;

  & button {
    margin-right: 40px !important;
  }
`;
