import styled from '@emotion/styled';
import { SimpleBlackText, TextBlackLarge, WhiteCardWithShadows } from '../../../../shared/styles';

export const SalesCardWrapper = styled(WhiteCardWithShadows)`
  flex-direction: column;
  width: 85%;
  padding: 36px 31px;
`;
export const SalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  text-align: center;
`;

export const SimpleBlackTextLittleLineHeight = styled(SimpleBlackText)`
  line-height: 15px !important;
`;

export const GalleryInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextBlackLargeBold = styled(TextBlackLarge)`
  font-weight: 600;
`;
