import React, { useMemo, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { today } from '../../constants';
import { ImageSizes } from '../../constants/galleryPreviewsAndCovers/previewSizes';
import { getFormatDate } from '../../utils/dateOperations';
import { getFileInContainer } from '../../utils/galleryCreator';
import { GalleryFileCropper } from '../shared/components/Croppers/GalleryFileCropper';
import { GalleryCoverType, ImageSize, NumberOfGallery } from '../shared/types';

type ReturnType = {
  file: ReactNode | null;
  name: ReactNode;
  date: ReactNode;
  userName: ReactNode;
  message: ReactNode;
  extraFile: ReactNode | null;
};

type GalleryCoverTypeWithSize = GalleryCoverType & {
  extraFile?: ReactNode;
  size?: ImageSize;
  galleryNumber?: NumberOfGallery;
};

const getFileInContainerOrCropper = (
  data: GalleryCoverTypeWithSize,
): ReactNode => (data.withCropper ? (
  <GalleryFileCropper
    height={
        ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].height
      }
    width={
        ImageSizes[data.galleryNumber || 'first'][data.size || 'desktop'].width
      }
    size={data.size || 'desktop'}
  />
) : (
  getFileInContainer(data.file)
));

export const useGalleryContent = (
  data: GalleryCoverTypeWithSize,
): ReturnType => {
  const { t } = useTranslation('createNewGallery');
  const { userName } = data;
  const fileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data.galleryNumber, data.file],
  );
  const extraFileContent = useMemo(
    () => (data.file ? getFileInContainerOrCropper(data) : null),
    [data.galleryNumber, data.file],
  );
  return {
    extraFile: extraFileContent,
    file: fileContent,
    name: <h3>{data.name || 'Gallery name'}</h3>,
    date: (
      <span>{data.date ? getFormatDate(data.date) : getFormatDate(today)}</span>
    ),
    userName: (
      <p>
        <Trans t={t} i18nKey="by">
          by
          {' '}
          {{ userName }}
        </Trans>
      </p>
    ),
    message: <p>{data.message || ''}</p>,
  };
};
