import styled from '@emotion/styled';

export const VideoWrapper = styled.div<{widthStretch: boolean}>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
`;

export const VideoElement = styled.video<{
  widthStretch: boolean;
  isWidthBigger?: boolean;
}>`
  object-fit: cover;

  ${(props) => (props.widthStretch
    ? `height: auto;
    width: 100%;`
    : `height: 100%;
    width: auto;`)}
  ${(props) => (props.isWidthBigger
    ? `height: 100%;
      width: auto;`
    : `height: auto;
      width: 100%;`)}
`;
