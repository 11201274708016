import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorType,
  ResultCheckDuplication,
} from '../../containers/shared/types';
import { getNewCheckingResult } from '../../utils/getNewCheckingResult';
import { fetchCheckDuplication } from './checkDuplicationThunk';

type initialState = {
  result: ResultCheckDuplication[];
  loading: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  result: [],
  loading: true,
  error: null,
};

const checkDuplicationSlice = createSlice({
  name: 'checkDuplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCheckDuplication.fulfilled, (state, action) => {
      state.result = getNewCheckingResult(state.result, action.payload);
      state.error = null;
      state.loading = false;
    });
    builder.addCase(
      fetchCheckDuplication.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
        state.loading = false;
      },
    );
    builder.addCase(fetchCheckDuplication.pending, (state) => {
      state.loading = true;
    });
  },
});

export default checkDuplicationSlice.reducer;
