import React, {
  ReactElement,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import {
  CalendarContainerCustom,
  CustomDay,
} from './styled';

export const CalendarContainer = ({
  children,
  className,
}: { children: any, className: string }): ReactElement => (
  <CalendarContainerCustom className={className}>
    <div style={{ position: 'relative' }}>{children}</div>
  </CalendarContainerCustom>
);
export const renderDayContents = (day: number): ReactElement => (
  <CustomDay>{day}</CustomDay>
);
