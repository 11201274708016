import styled from '@emotion/styled';
import { createTheme, Grid } from '@material-ui/core';
import * as variables from '../constants/styleVariables';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 250,
      sm: 320,
      md: 786,
      lg: 1000,
      xl: 1368,
    },
  },
});

export const Header = styled.div`
  padding-top: 20px;
  margin-bottom: 50px;
  display: inline-block;
`;

export const GlobalStyle = `
  html {
    margin: 0;
    padding: 0;
    height: 100%;
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
  body {
    background: ${variables.colorGreyLight};
    background-color: ${variables.colorGreyLight};
    margin: 0;
    padding: 0;
    font-family: ${variables.mainFont};
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-weight: normal;
    font-style: normal;
    & #root {
      background-color: ${variables.colorGreyLight};
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;  
      & > div {
        height: 100%;  
      }
    }
    & p {
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    & #modal-root {
      position: relative;
      z-index: 999;
      & > div {
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    & a {
      outline: none;
      transition: 0.3s;
      text-decoration: none;
      cursor: pointer;
      color: ${variables.textBlack};
    }
    & button {
      outline: none;
      cursor: pointer;
      border: none;
      background: transparent;
      font-family: ${variables.mainFont};
    }
    & input, & textarea {
      outline: none;
      font-family: ${variables.mainFont};
      display: flex;
      align-items: center;
      font-size: 15px;
      margin: 0;
      color: ${variables.textGrey};
    }
    & svg {
      color: ${variables.textGrey};
    }
}
`;

export const GridFullHeight = styled(Grid)`
  height: 100% !important;
  min-height: 100% !important;
  min-height: 100hv !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
export const GridItemFullHeight = styled(Grid)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;
