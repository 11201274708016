import React, { useMemo, useState, useEffect } from 'react';
import {
  useStripe,
  PaymentRequestButtonElement,
  PaymentRequestButtonElementProps,
} from '@stripe/react-stripe-js';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        paymentRequestButton: {
          theme: 'dark',
          height: '48px',
          type: 'donate',
        },
      },
    }),
    [],
  );

  return options;
};

const usePaymentRequest = ({ options, onPaymentMethod }: any) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState<any>(true);

  // useEffect(() => {
  //   if (stripe && paymentRequest === null) {
  //     const pr = stripe.paymentRequest(options);
  //     console.log(options, pr);
  //     setPaymentRequest(pr);
  //     console.log(paymentRequest);
  //   }
  // }, [stripe, options, paymentRequest]);

  // useEffect(() => {
  //   let subscribed = true;
  //   if (paymentRequest) {
  //     paymentRequest.canMakePayment().then((res: any) => {
  //       if (res && subscribed) {
  //         setCanMakePayment(true);
  //       }
  //       setCanMakePayment(true);
  //     });
  //   }

  //   return () => {
  //     subscribed = false;
  //   };
  // }, [paymentRequest]);

  // useEffect(() => {
  //   if (paymentRequest) {
  //     paymentRequest.on('paymentmethod', onPaymentMethod);
  //   }
  //   return () => {
  //     if (paymentRequest) {
  //       paymentRequest.off('paymentmethod', onPaymentMethod);
  //     }
  //   };
  // }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const PaymentRequestForm: React.FC = () => {
  const paymentRequest = usePaymentRequest({
    options: {
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 1000,
      },
    },
    onPaymentMethod: ({ complete, paymentMethod, ...data }: any) => {
      console.log('[PaymentMethod]', paymentMethod);
      console.log('[Customer Data]', data);
      complete('success');
    },
  });

  if (!paymentRequest) {
    return null;
  }

  return (
    <div>
      <PaymentRequestButtonElement
        className="PaymentRequestButton"
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              theme: 'dark',
              height: '48px',
              type: 'donate',
            },
          },
        }}
        // onReady={() => {
        //   console.log('PaymentRequestButton [ready]');
        // }}
        // onClick={(event) => {
        //   console.log('PaymentRequestButton [click]', event);
        // }}
        // onBlur={() => {
        //   console.log('PaymentRequestButton [blur]');
        // }}
        // onFocus={() => {
        //   console.log('PaymentRequestButton [focus]');
        // }}
      />
    </div>
  );
};

export default PaymentRequestForm;
