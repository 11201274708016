import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';
import { RoundButton } from '../../styles';

export const CommonCopyInputWrapper = styled.div<{direction: 'row' | 'column'}>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => props.direction};
  width: 100%;
`;

export const CopyInput = styled.input`
  border: none;
  background: ${variables.colorGreyLight};
  color: ${variables.textBlack};
  width: ${(props) => props.width}px;
  max-width: 340px;
  padding-right: 20px;
  display: flex;
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
`;

export const CopyInputAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;

  & > div {
    position: absolute;
    top: 30%;
    left: 95%;
  }
`;

export const CustomRoundButton = styled(RoundButton)`
  padding: 11px;
  width: fit-content;
  height: fit-content;

  & svg {
    width: 22px;
    height: 22px;
  }
`;
