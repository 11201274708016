import { styled, Tabs } from '@mui/material';
import React, {
  ChangeEvent, SetStateAction, Dispatch, ReactNode,
} from 'react';
import { TabType } from '../../../../shared/types';
import { StyledSettingsTab } from './StyledSettingsTab';

const StyledTabs = styled((props: any) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  width: 'fit-content',
  '& .MuiTab-wrapper': {
    width: 'fit-content',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F180B2',
    width: 'fit-content',
    minWidth: '30px',
    marginRight: '-30px',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 40,
    width: 'fit-content',
    minWidth: '30px',
    backgroundColor: '#F180B2',
  },
});

type SettingsTabsType = {
  tabs: ({ label: ReactNode, id: string })[];
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
};

export const SettingsTabs: React.FC<SettingsTabsType> = ({ tabs, value, setValue }) => {
  const handleChange = (event: ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
      TabIndicatorProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      {tabs.map((item, index) => (
        <StyledSettingsTab
          tabsnumber={tabs.length}
          key={item.id}
          label={item.label}
          id={item.id}
          disabled={index === tabs.length - 1}
        />
      ))}
    </StyledTabs>
  );
};
