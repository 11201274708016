import { MenuProps } from '@material-ui/core';
import * as variables from '../../../../constants/styleVariables';

export const getStyle = (width: number): Partial<MenuProps> => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  PaperProps: {
    style: {
      fontFamily: variables.mainFont,
      maxHeight: 250,
      maxWidth: width || 336,
      padding: '0px 25px 0px 10px',
      boxSizing: 'border-box',
      boxShadow: '-5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8',
      borderRadius: '4px',
      background: '#F8F8F8',
    },
  },
  getContentAnchorEl: null,
});
