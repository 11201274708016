import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../../shared/icons';
import { useDialog } from '../../../../shared/hooks/useDialog';
import { BasicDocumentCard } from '../../../../shared/components/BasicDocumentCard';

type DocumentCardType = {
  file: string;
  handleDelete: (name: string) => void;
};

export const DocumentCard: React.FC<DocumentCardType> = ({
  file,
  handleDelete,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { setIsOpen, dialogWindow } = useDialog(
    file,
    handleDelete,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  return (
    <>
      <BasicDocumentCard file={file}>
        <button type="button" onClick={() => setIsOpen(true)}>
          <DeleteIcon />
        </button>
      </BasicDocumentCard>
      {dialogWindow}
    </>
  );
};
