import React, { useState } from 'react';
import { ButtonSelectArrow, CustomSelectYearContainer, YearButton } from './styled';
import { years, months } from '../../../../constants';
import 'react-datepicker/dist/react-datepicker.css';

type CustomSelectYearType = {
  date: Date;
  changeYear: any;
};

export const CustomSelectYear: React.FC<CustomSelectYearType> = ({
  date,
  changeYear,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <span>{months[date.getMonth()]}</span>
      <ButtonSelectArrow
        theme={expanded ? 'expanded' : ''}
        type="button"
        onClick={() => setExpanded((prev) => !prev)}
      >
        {date.getFullYear()}
      </ButtonSelectArrow>
      {expanded && (
        <CustomSelectYearContainer theme={expanded ? 'expanded' : ''}>
          <div>
            {years.map((option) => (
              <YearButton
                type="button"
                key={option}
                theme={option === +date.getFullYear() ? 'selected' : ''}
                onClick={() => {
                  changeYear(Number(option));
                  setExpanded((prev: any) => !prev);
                }}
              >
                {option}
              </YearButton>
            ))}
          </div>
        </CustomSelectYearContainer>
      )}
    </div>
  );
};
