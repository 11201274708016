import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import * as variables from '../../../../constants/styleVariables';
import { getProgressBarStyle } from '../../../../utils/style/getProgressBarStyle';

export const ProgressBarWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 86px;
  width: 95%;
`;
export const ProgressBarContainer = styled.div`
  box-shadow:
    2px 2px 5px #d8d8d8,
    inset -5px -5px 10px #fff,
    inset 2px 2px 5px #e7e7e7;
  border-radius: 43px;
  width: 100%;
  height: 13px;
  position: relative;
  background: ${variables.colorGreyLight};
`;

export const ProgressLineInside = styled.div<{stepNumber: number}>`
  position: absolute;
  width: ${(props) => getProgressBarStyle(props.stepNumber).width};
  background: ${(props) => getProgressBarStyle(props.stepNumber).background};
  height: 7px;
  border-radius: 43px;
  left: 3px;
  top: 2.5px;
`;

export const ProgressBarDot = styled.div<{stageNumber: number, status: string}>`
  position: absolute;
  height: 20px;
  width: 20px;
  margin-left: -10px;
  top: -3.5px;
  /* stylelint-disable indentation */
  background:
    ${(props) => getProgressBarStyle(
    props.stageNumber,
    props.status.includes('active') || props.status.includes('done'),
  ).backgroundDot};
  /* stylelint-enable indentation */
  left: ${(props) => getProgressBarStyle(props.stageNumber).left};
  color:
    ${(props) => (props.status.includes('active') || props.status.includes('done')
    ? variables.colorGreyLight
    : variables.colorBleak)};
  font-size: 14px;
  line-height: 150%;
  border-radius: 50%;
  padding-right: 5px;
  padding-left: 5px;
  box-sizing: border-box;
  text-align: center;
  border:
    ${(props) => (props.status.includes('active') || props.status.includes('done')
    ? 'none'
    : '0.5px solid #e7e7e7')};

  & svg {
    margin-bottom: 2px;
  }

  & span {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    top: -0.5px;
    left: 0;
  }
`;
export const ProgressBarText = styled.span`
  color: ${variables.colorBlack};
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  bottom: -27px;
  width: 112px;
  text-align: center;
  left:
    calc(
      ${({ theme }: { theme: string }) => getProgressBarStyle(Number(theme)).left} -
      56px
    );
  white-space: nowrap;
`;

export const FormGalleryWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
export const GridWrapper = styled(Grid)`
  margin-bottom: 10px;
`;
export const GridFitByContent = styled(Grid)`
  display: inline-block !important;
  height: 50% !important;
  margin-top: -50px !important;
`;

export const GridTopMargin = styled(Grid)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;

  & > div:first-of-type {
    margin-right: 40px;
  }
`;

export const ProgressBarDotWrapper = styled.button`
  height: 50px;
  width: 100px;
  position: absolute;
  left: -40px;
  top: -10%;
  z-index: 10;
`;
