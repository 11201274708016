import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '../../../shared/types';
import {
  MediumGreyTextWithMargin,
  NotificationCardGrid,
  NotificationsCardContent,
  NotificationsCardImage,
  NotificationsCardWrapper,
  NotificationTextBold,
  NotReadMarker,
} from './styled';
import delivrableImage from '../../../../assets/image/delivrable.png';
import { BlackTextWhiteButton, SimpleBlackText } from '../../../shared/styles';
import { getFormatDate, getTimeWithAMAndPM } from '../../../../utils';

export const NotificationsCard: React.FC<Notification> = ({
  from,
  item,
  message,
  title,
  date,
  isRead,
  id,
}) => {
  const { t } = useTranslation('notifications');

  const time = useMemo(() => getTimeWithAMAndPM(new Date(date)), [date]);

  return (
    <>
      <NotificationsCardWrapper key={id}>
        <Grid container item sm={12} justifyContent="space-between">
          <NotificationCardGrid item sm={3}>
            <NotificationsCardContent>
              <NotificationTextBold>{title}</NotificationTextBold>
              <MediumGreyTextWithMargin>{getFormatDate(new Date(date))}</MediumGreyTextWithMargin>
              <MediumGreyTextWithMargin>{time}</MediumGreyTextWithMargin>
            </NotificationsCardContent>
          </NotificationCardGrid>
          <NotificationCardGrid item sm={7}>
            <NotificationsCardContent>
              <NotificationTextBold>{from}</NotificationTextBold>
              <SimpleBlackText>{message}</SimpleBlackText>
            </NotificationsCardContent>
            {!from.includes('Delivrable')
            && (
            <BlackTextWhiteButton type="button">
              {t('reply')}
            </BlackTextWhiteButton>
            )}
          </NotificationCardGrid>
          <Grid item sm={1}>
            <NotificationsCardContent>
              <NotificationsCardImage>
                {item ? (
                  <img src={item} alt={item} />
                ) : (
                  <img src={delivrableImage} alt="desirable" />
                )}
              </NotificationsCardImage>
            </NotificationsCardContent>
          </Grid>
        </Grid>
        {!isRead && <NotReadMarker />}
      </NotificationsCardWrapper>
    </>
  );
};
