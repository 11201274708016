import { dataWeek, getDataDay, getDataMonth } from '../../containers/CreatorAccount/Tabs/Sales/SalesChart/data';

export const getDataByScale = (scale: number) => {
  const dataDay = getDataDay();
  const dataMonth = getDataMonth();

  switch (scale) {
    case 0:
      return dataDay;
    case 1:
      return dataWeek;
    case 2:
      return [];
    default:
      return [];
  }
};
