import styled from '@emotion/styled';
import { galleryGap } from '../../../../constants';

export const PositionedElementWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;

  & svg {
    z-index: 1;
    color: white;
  }
`;

export const ExpandAndShrinkWrapper = styled.button`
  background: white;
  padding: 10px 17px;
  border-radius: 53px;
  z-index: 10;
`;

export const MasonryContainer = styled.div`
  margin: auto;
  margin-top: 10px;
  width: calc(100% + 30px);
  margin-right: -30px;

  & img {
    width: calc(100% - 30px);
    height: auto;
    margin-right: 30px;
    margin-bottom: 30px;
  }

  & p {
    text-align: center;
    padding: 4px;
    font-family: monospace;
    background: #222;
    color: #efefef;
  }
`;

export const MasonryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
`;

export const ColumnMasonry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  width: 100%;
  flex-grow: 1;
`;

export const ImagesList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 ${galleryGap}px;
  grid-auto-rows: 10px;

  & div {
    margin-bottom: ${galleryGap}px;
  }

  & img {
    width: 100%;
  }
  & video {
    width: 100%;
    height: auto;
  }
`;

export const ImageOrVideoCardWrapper = styled.div<{spans: number, fullWidth: boolean}>`
  grid-row-end: span ${(props) => props.spans};
  grid-column-end: ${(props) => (props.fullWidth ? 'span 3' : 'auto')};
`;
