import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  ModalCloseButtons,
  PrimaryWhiteButton,
  RoundButton,
} from '../../../../shared/styles';
import { GalleryDataFormType } from '../../../../shared/types';
import { ArrowWrapper } from '../../../../shared/components/styled';
import { SaveAndClose } from '../SaveAndClose/SaveAndClose';
import { ButtonsGroupWrapper } from './styled';
import { OnboardingContext } from '../../../../../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../../../../../context/OnboardingStepIndexContext';
import { ArrowSimpleIcon } from '../../../../shared/icons';

type GalleryPreviewButtonsGroupType = {
  handleClose: () => void;
};

export const GalleryPreviewButtonsGroup: React.FC<GalleryPreviewButtonsGroupType> = ({
  handleClose,
}) => {
  const { isStudy } = useContext(OnboardingContext);
  const { setStepIndex } = useContext(OnboardingStepIndexContext);
  const { t } = useTranslation('createNewGallery');
  const {
    setFieldValue, isValid, handleSubmit,
  } = useFormikContext<GalleryDataFormType>();

  useEffect(() => {
    if (isStudy) {
      setStepIndex((prev: number) => prev + 1);
    }
  }, []);

  const handleCloseAndMoveFurther = () => {
    if (isValid) {
      setFieldValue('step', 5);
      handleSubmit();
    }
    handleClose();
    if (isStudy) {
      setStepIndex((prev: number) => prev + 1);
    }
  };
  return (
    <ModalCloseButtons>
      <ButtonsGroupWrapper>
        <RoundButton large type="button" onClick={handleClose}>
          <ArrowWrapper large direction="left">
            <ArrowSimpleIcon direction="left" />
          </ArrowWrapper>
        </RoundButton>
        <SaveAndClose />
      </ButtonsGroupWrapper>
      <PrimaryWhiteButton
        type="submit"
        onClick={handleCloseAndMoveFurther}
      >
        {t('fifthStep.title')}
      </PrimaryWhiteButton>
    </ModalCloseButtons>
  );
};
