import styled from '@emotion/styled';
import * as variables from '../../../constants/styleVariables';
import { ImageSizes } from '../../../constants/galleryPreviewsAndCovers/previewSizes';

export const FirstTitle = styled.div`
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: ${variables.textBlack};

  & h3 {
    margin: 0;
  }
`;

export const FirstMediumText = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: ${variables.textBlack};
  text-align: start;
`;

export const FirstImageContainer = styled.div`
  width: ${ImageSizes.first.desktop.width}px;
  height: ${ImageSizes.first.desktop.height}px;
  box-sizing: border-box;
  position: relative;
  background: ${variables.colorBleak};
  background-size: cover;
  object-fit: cover;
  display: flex;

  & span {
    width: 40px;
    position: absolute;
    font-weight: normal;
    transform: rotate(-90deg);
    font-size: 16px;
    line-height: 21px;
    left: -56px;
    bottom: 13px;
  }
`;

export const ImageContainerFirstTablet = styled(FirstImageContainer)`
  width: ${ImageSizes.first.tablet.width}px;
  height: ${ImageSizes.first.tablet.height}px;

  & span {
    left: -36px;
    bottom: 15px;
    font-size: 11px;
    line-height: 16px;
  }
`;

export const FirstTitleTablet = styled(FirstTitle)`
  font-size: 33px;
  line-height: 50px;
`;

export const FirstMediumTextTablet = styled(FirstMediumText)`
  font-size: 9.6px;

  & p {
    margin: 0;
  }
`;

export const ImageContainerFirstMobile = styled(FirstImageContainer)`
  width: ${ImageSizes.first.mobile.width}px;
  height: ${ImageSizes.first.mobile.height}px;
`;

export const FirstTitleMobile = styled(FirstTitle)`
  font-size: 20px;
  line-height: 130%;
`;

export const FirstMediumTextMobile = styled(FirstMediumText)`
  font-size: 8.5px;

  & p {
    margin: 0;
  }
`;

export const MobileWrapperFirst = styled.div`
  margin: 30px 10px 0 10px;
`;
