import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TabContentWrapper,
  TabWrapper,
  TitleTextPink,
} from '../../../shared/styles';
import { NotificationsList } from './NotificationsList';
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector';
import { fetchGetNotifications, setEmptyArrNotifications } from '../../../../Redux/notifications/notificationsThunk';
import { TitleTextCustom } from './styled';

export const Notifications: React.FC = () => {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  const numberNotReadNotifications = useTypedSelector(
    (state) => state.notifications.numberNotRead,
  );
  const isLoading = useTypedSelector((state) => state.notifications.loading);

  useEffect(() => {
    dispatch(fetchGetNotifications({ page: 1 }));

    return () => { dispatch(setEmptyArrNotifications()); };
  }, []);

  return (
    <TabWrapper>
      <TitleTextCustom>
        <Trans t={t} i18nKey="title">
          Notifications
          {' '}
          <TitleTextPink>
            (
            {{ notReadNotificationsNumber: numberNotReadNotifications }}
            )
          </TitleTextPink>
        </Trans>
      </TitleTextCustom>
      <TabContentWrapper>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <NotificationsList />
        )}
      </TabContentWrapper>
    </TabWrapper>
  );
};
