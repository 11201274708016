import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { CloseButton, GreyLightWrapper, TextBlackLarge } from '../../shared/styles';

export const PopupContainer = styled.div`
  margin: 60px 60px 40px;
  position: relative;
`;

export const PopupInfoContainerLarge = styled.div`
  max-width: 525px;
  position: relative;
  padding: 32px 32px;
  box-sizing: border-box;
`;

export const PopupInfoContainer = styled.div`
  padding: 24px 30px 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`;

export const GalleriesActivityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const GridContainerPopupCustom = styled(Grid)`
  max-width: 550px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
`;

export const CloseButtonWithoutMgPg = styled(CloseButton)`
  margin-top: 15px;
  margin-left: 0;
  padding: 0;
`;

export const GreyLightWrapperFullHeight = styled(GreyLightWrapper)`
  height: 100%;
`;

export const TextBlackLargeMgBt = styled(TextBlackLarge)`
  margin-bottom: 16px;
`;
