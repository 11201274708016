import React, { ReactNode } from 'react';
import { isDocument, isImage, isVideo } from '..';
import { ImageContainer } from '../../containers/shared/components/ImageContainer/ImageContainer';
import { VideoContainer } from '../../containers/shared/components/VideoContainer/VideoContainer';
import { BasicDocumentCard } from '../../containers/shared/components/BasicDocumentCard';
import { CustomFileType } from '../../containers/shared/types';

type ImageAndVideoProps = {
  autoPlay?: boolean;
  widthStretch?: boolean;
};

export const getFileInContainer = (
  file: CustomFileType | null,
  imageAndVideoProps?: ImageAndVideoProps,
): ReactNode | null => {
  if (isDocument(file)) {
    return (
      <BasicDocumentCard isSmall file={file?.name || ''} />
    );
  }
  if (isImage(file)) {
    return (
      <ImageContainer
        imageUrl={file?.url || ''}
        widthStretch={imageAndVideoProps?.widthStretch}
      />
    );
  }
  if (isVideo(file)) {
    return (
      <VideoContainer
        videoUrl={file?.url || ''}
        widthStretch={imageAndVideoProps?.widthStretch}
        autoPlay={imageAndVideoProps?.autoPlay}
      />
    );
  }
  return null;
};
