import React from 'react';
import { Tab, styled } from '@material-ui/core';
import * as variables from '../../../../../constants/styleVariables';
import { TabWrapper, ExtraTextWrapper } from './styled';

export const StyledSettingsTab = styled((props: any) => (
  <TabWrapper>
    {props.disabled && <ExtraTextWrapper>Coming soon</ExtraTextWrapper>}
    <Tab disableRipple {...props} />
  </TabWrapper>
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: variables.mainFont,
  marginRight: '0px',
  marginLeft: '0px',
  width: 'fit-content',
  height: '100%',
  minWidth: '30px',
  fontSize: '16px',
  lineHeight: '140%',
  paddingLeft: '0px',
  paddingRight: '0px',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  color: variables.textGrey,
  background: variables.colorGreyLight,
  '&.MuiTab-wrapper': {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: 'fit-content',
    minWidth: '30px',
  },
  '&.Mui-selected': {
    color: variables.colorPink,
  },
  '&.Mui-focusVisible': {
    backgroundColor: variables.colorPink,
  },
  '&.MuiTabs-indicator': {
    backgroundColor: variables.colorPink,
  },
  indicator: {
    backgroundColor: variables.colorPink,
  },
}));
