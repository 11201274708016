import React, { ChangeEventHandler } from 'react';
import {
  CommonInputWrapper,
  ErrorMessage, MaxLengthText, TextInput, TextLabel,
} from './styled';

type CommonInputProps = {
  error: string | any;
  value: string | any;
  name: string;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  touched?: boolean | any;
  label?: string;
  isFullHeight?: boolean;
};

const maxTextLength = 220;

export const CommonTextarea: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  placeholder,
  touched,
  label,
  isFullHeight,
}) => (
  <CommonInputWrapper fullHeight={isFullHeight}>
    {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
    <TextInput color={`${error ? 'error' : 'success'}`} theme="420">
      <textarea
        id={name}
        // maxLength={maxTextLength}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        rows={3}
      />
      <MaxLengthText color={`${error && touched ? 'error' : 'success'}`}>
        <span>
          {value?.length || 0}
          /
          {maxTextLength}
        </span>
      </MaxLengthText>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </TextInput>
  </CommonInputWrapper>
);
