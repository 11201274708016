import React, { Dispatch, SetStateAction } from 'react';
import { TickIcon } from '../../icons';
import { SimpleWhiteText } from '../../styles';
import { ModalWindowContainer } from '../ModalWindow/ModalWindowContainer';
import { NotificationWrapper } from './styled';

type SuccessNotificationType = {
  isOpen: boolean;
  text: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  longTime?: boolean;
};

export const SuccessNotification: React.FC<SuccessNotificationType> = ({
  isOpen,
  text,
  setIsOpen,
  longTime,
}) => {
  setTimeout(() => {
    setIsOpen(false);
  }, longTime ? 700 : 515);
  return (
    <ModalWindowContainer isOpen={isOpen} handleClose={() => setIsOpen(false)}>
      <NotificationWrapper>
        <TickIcon isGradient={false} />
        <SimpleWhiteText>
          {text}
        </SimpleWhiteText>
      </NotificationWrapper>
    </ModalWindowContainer>
  );
};
