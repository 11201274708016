import React, {
  Dispatch, SetStateAction, ReactNode,
} from 'react';
import { ModalWindowContainer } from '../../../../shared/components/ModalWindow/ModalWindowContainer';
import { ButtonMoveToTop } from '../../../../shared/components/ButtonMoveToTop/ButtonMoveToTop';
import { useGetGalleryCover } from '../../../../shared/hooks/useGetGalleryCover';
import { GalleryGridContainer } from '../../../../shared/components/GalleryGridContainer';
import { GalleryPreviewButtonsGroup } from './GalleryPreviewButtonsGroup';
import { GalleryPreviewHeader } from './GalleryPreviewHeader';
import { GalleryContent } from '../../../../GalleryView/GalleryContent/GalleryContent';

type ModalWindowType = {
  isOpen?: boolean;
  button?: ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const ModalWindowGalleryPreview: React.FC<ModalWindowType> = ({
  isOpen = false,
  button,
  setIsOpen,
}) => {
  const galleryCover = useGetGalleryCover();

  const handleClose = () => setIsOpen(false);

  return (
    <>
      {button}
      <ModalWindowContainer
        size="full"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <GalleryPreviewHeader>
          <GalleryPreviewButtonsGroup handleClose={handleClose} />
        </GalleryPreviewHeader>
        <GalleryGridContainer>
          {galleryCover}
          <GalleryContent />
          <ButtonMoveToTop />
        </GalleryGridContainer>
      </ModalWindowContainer>
    </>
  );
};
