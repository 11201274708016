import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Placement } from 'react-joyride';
import { PinkLink } from '../../shared/styles';

const styleForTopAndBottom = { left: '0px' };
const styleForLeftAndRight = { top: '0px' };

export const useGetSteps = () => {
  const { t } = useTranslation('walkThroughCards');

  return [
    {
      target: '#createNewGallerySidebar',
      content: t('steps.createNewGallery'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      disableBeacon: true,
    },
    {
      target: '#salesSidebar',
      content: t('steps.monitorSales'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '#activeGalleriesSidebar',
      content: t('steps.activeGalleries'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '#draftGalleriesSidebar',
      content: t('steps.draftGalleries'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '#createNewGallerySidebar',
      content: t('steps.learnCreateNewGallery'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
    },
    {
      target: '#galleryNameDateFirstStep',
      content: t('steps.giveGalleryName'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#coverFileFirstStep',
      content: t('steps.uploadCoverImage'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#galleryCoverDesignFirstStep',
      content: t('steps.chooseGalleryDesign'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
      // disableBeacon: true,
    },
    {
      target: '.progress-dot-2',
      content: 'Move to the next step',
      placement: 'bottom-start' as Placement,
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 100000,
        },
      },
      // disableBeacon: true,
    },
    {
      target: '#dragAndDropFilesSecondStep',
      content: t('steps.addContent'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#addIdeaSecondStep',
      content: (
        <Trans t={t} i18nKey="steps.addIdeas">
          There’s so many wonderful ways to use Ideas to provide extra value to
          your client. Learn more
          {' '}
          <PinkLink style={{ textDecoration: 'none' }} href="/" target="_blank">in this quick video</PinkLink>
        </Trans>
      ),
      placement: 'right-start' as Placement,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '#addSectionSecondStep',
      content: t('steps.addSection'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#moveFirstSectionsSecondStep',
      content: t('steps.changeOrder'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '.progress-dot-3',
      content: 'Move to the next step',
      placement: 'bottom-start' as Placement,
      spotlightClicks: true,
      styles: {
        tooltip: styleForTopAndBottom,
      },
      // disableBeacon: true,
    },
    {
      target: '#checkboxesFilesThirdStep',
      content: t('steps.checkboxFileSettings'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#filesSettingsThirdStep',
      content: t('steps.fileSettings'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#limitFreeFilesThirdStep',
      content: t('steps.freeFiles'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
      // disableBeacon: true,
    },
    {
      target: '.progress-dot-4',
      content: 'Move to the next step',
      placement: 'bottom-end' as Placement,
      spotlightClicks: true,
      // disableBeacon: true,
    },
    {
      target: '.access-to-gallery-fourth-step',
      content: t('steps.accessToGallery'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#gallery-assist-fourth-step',
      content: t('steps.galleryAssist'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#premium-fourth-step',
      content: t('steps.premium'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '.progress-dot-5',
      content: t('steps.checkGalleryPreview'),
      placement: 'bottom-end' as Placement,
      spotlightClicks: true,
      // disableBeacon: true,
    },
    {
      target: '#moveToTheNext',
      content: t('steps.moveToThe5thStep'),
      placement: 'bottom-end' as Placement,
      spotlightClicks: true,
      // disableBeacon: true,
    },
    {
      target: '#progress-bar',
      content: t('steps.backToStages'),
      placement: 'bottom-start' as Placement,
      spotlightClicks: false,
      // disableBeacon: true,
    },
    {
      target: '#sharable-link-fifth-step',
      content: t('steps.generateLink'),
      placement: 'left-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForLeftAndRight,
      },
      // disableBeacon: true,
    },
    {
      target: '#supportSidebar',
      content: t('steps.goToSupport'),
      placement: 'right-start' as Placement,
      spotlightClicks: false,
      styles: {
        options: {
          zIndex: 1000000,
        },
      },
      // disableBeacon: true,
    },
    {
      target: '#training-course',
      content: t('steps.buttonToReturnToTraining'),
      placement: 'top-start' as Placement,
      spotlightClicks: false,
      styles: {
        tooltip: styleForTopAndBottom,
      },
      // disableBeacon: true,
    },
  ];
};
