import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { CommonInput } from '../../../../../shared/components/CommonInput';
import { GalleryData, GalleryIdea } from '../../../../../shared/types';
import { CommonTextarea } from '../../../../../shared/components/CommonTextarea';
import { IdeaFormWrapper, IdeaWrapper, TextareaHeight } from './styled';
import { AttachedFiles } from './AttachedFiles';
import { getIdeasErrorsAndTouched } from '../../../../../../utils/galleryCreator/getErrorsAndTouched';
import { CommonPriceInput } from '../../../../../shared/components/CommonPriceInput/CommonPriceInput';

type IdeaFormType = {
  idea?: GalleryIdea;
  ideaField: string;
  indexSection: number;
  indexIdea: number;
};

export const IdeaForm: React.FC<IdeaFormType> = ({
  idea,
  ideaField,
  indexSection,
  indexIdea,
}) => {
  const { t } = useTranslation('createNewGallery');
  const {
    handleChange, handleBlur, errors, touched,
  } = useFormikContext<GalleryData>();

  const errorsAndTouched = useMemo(
    () => getIdeasErrorsAndTouched(errors, touched, indexSection, indexIdea),
    [errors, touched],
  );

  return (
    <IdeaWrapper>
      <IdeaFormWrapper style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container item sm={12} spacing={1} justifyContent="space-between">
          <Grid container item sm={7}>
            <Grid item sm={11}>
              <CommonInput
                error={errorsAndTouched?.error?.name || ''}
                value={idea?.name}
                name={`${ideaField}.name`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
                touched={!!errorsAndTouched?.wasTouched?.name}
                placeholder={t('secondStep.nameIdea')}
              />
            </Grid>
            <Grid item sm={11}>
              <TextareaHeight>
                <CommonTextarea
                  name={`${ideaField}.description`}
                  value={idea?.description}
                  handleChange={handleChange}
                  isFullHeight
                  error={errorsAndTouched?.error?.description || ''}
                  touched={!!errorsAndTouched?.wasTouched?.description}
                  placeholder={t('secondStep.description')}
                />
              </TextareaHeight>
            </Grid>
            <Grid item sm={6}>
              <CommonPriceInput
                label={t('secondStep.price')}
                value={idea?.price}
                name={`${ideaField}.price`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                direction="row"
                error={errorsAndTouched?.error?.price || ''}
                touched={!!errorsAndTouched?.wasTouched?.price}
              />
            </Grid>
          </Grid>
          {idea
          && (
          <AttachedFiles
            idea={idea}
            ideaField={ideaField}
            errors={errorsAndTouched?.error}
            touched={errorsAndTouched?.wasTouched}
          />
          )}
        </Grid>
      </IdeaFormWrapper>
    </IdeaWrapper>
  );
};
