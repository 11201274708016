import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as urls from '../../constants/urls';
import { CommonInput } from '../shared/components/CommonInput';
import {
  ButtonWithoutShadow,
  FlexStartWrapper,
  FormBase,
  PrimaryWhiteButtonBlueText,
  SignInUpCard,
  TitleSimple,
} from '../shared/styles';
import { ResetSchema } from '../shared/validation/signInUpSchemas';
import { fetchResetPasswordInitialCreator } from '../../Redux/userCreator/resetPassword/resetPasswordCreatorThunk';

export const ResetPassword: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['signIn', 'basicComponents']);

  const onSubmit = (data: { email: string }) => {
    dispatch(fetchResetPasswordInitialCreator(data.email));
    history.push(urls.resetSuccessUrl);
  };

  const {
    values, handleSubmit, errors, handleChange, touched, handleBlur,
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ResetSchema,
    onSubmit: (data: { email: string }) => onSubmit(data),
  });

  const onBack = () => {
    history.push(urls.signInUrl);
  };

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={6}>
        <SignInUpCard theme="reset">
          <TitleSimple>{t('signIn:resetPassword')}</TitleSimple>
          <FormBase theme="column">
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={4} justifyContent="center">
                <Grid item sm={9}>
                  <CommonInput
                    error={errors.email}
                    value={values.email}
                    name="email"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    type="email"
                    placeholder="Your email"
                    touched={touched.email}
                  />
                </Grid>
              </Grid>
              <FlexStartWrapper>
                <ButtonWithoutShadow onClick={onBack}>{t('basicComponents:buttons.back')}</ButtonWithoutShadow>
                <PrimaryWhiteButtonBlueText type="submit">{t('signIn:resetPassword')}</PrimaryWhiteButtonBlueText>
              </FlexStartWrapper>
            </form>
          </FormBase>
        </SignInUpCard>
      </Grid>
    </Grid>
  );
};
