import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { watermark } from '../../../../../constants';
import { getURLByFile } from '../../../../../utils';
import { CommonInput } from '../../../../shared/components/CommonInput';
import { CommonRadioButton } from '../../../../shared/components/CommonRadioButton/CommonRadioButton';
import { MiniChooseFileCard } from '../../../../shared/components/GalleryCreator/styled';
import { HelpTip } from '../../../../shared/components/HelpTip/HelpTip';
import { SimpleBlackText } from '../../../../shared/styles';
import { watermarkSchema } from '../../../../shared/validation/settingsSchemas';
import { PlusIcon } from '../../NewGalleryCreator/NewGalleryCreatorSecondStep/styled';
import { RowsWrapper, TextMgB } from '../styled';
import { WatermarkFileWrapper, WatermarkInputWrapper } from './styled';

export const Watermark: React.FC = () => {
  const { t } = useTranslation('settings');

  const onSubmit = (data: any) => {
    console.log('submit');
  };

  const {
    values, handleChange, handleSubmit, errors, touched, setFieldValue,
  } = useFormik<{ watermark: string; file: string; text: string }>({
    validationSchema: watermarkSchema,
    initialValues: { watermark: '', file: '', text: '' },
    onSubmit: (data) => onSubmit(data),
  });

  const handleClick = (files: File[]) => {
    if (files.length > 0) {
      setFieldValue('file', getURLByFile(files[0]));
    }
  };

  return (
    <>
      <TextMgB>
        <RowsWrapper>
          <SimpleBlackText>{t('watermark')}</SimpleBlackText>
          <HelpTip text={t('helpTips.watermarkTip')} />
        </RowsWrapper>
      </TextMgB>
      <CommonRadioButton
        value={values.watermark}
        label={t('uploadWatermark')}
        setFieldValue={setFieldValue}
        nameGroup="watermark"
        nameRadio={watermark.file}
        likeCheckbox
        error={errors.file}
        touched={touched.file}
      />
      {values.watermark === watermark.file && (
        <WatermarkFileWrapper>
          <MiniChooseFileCard imageUrl={values.file} hasFile={!!values.file}>
            <PlusIcon theme="small" />
            <input
              name="file"
              type="file"
              accept="*/image"
              onChange={(e) => handleClick(Array.from(e.target.files || []))}
            />
          </MiniChooseFileCard>
        </WatermarkFileWrapper>
      )}
      <CommonRadioButton
        value={values.watermark}
        label={t('useTextWatermark')}
        setFieldValue={setFieldValue}
        nameGroup="watermark"
        nameRadio={watermark.text}
        likeCheckbox
      />
      {values.watermark === watermark.text && (
        <>
          <WatermarkInputWrapper>
            <CommonInput
              value={values.text}
              name="text"
              handleChange={handleChange}
              direction="column"
              placeholder={t('placeholders.watermarkText')}
              error={errors?.text}
              touched={touched?.text}
              type="text"
            />
          </WatermarkInputWrapper>
        </>
      )}
    </>
  );
};
