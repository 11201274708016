import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../shared/hooks/useDialog';
import { fetchDeleteGallery } from '../../../../../../Redux/gallery/galleryThunk';
import { TitleBlack } from '../../../../../shared/styles';
import * as urls from '../../../../../../constants';
import { CloseButton, DashboardHeaderWrapper } from './styled';
import { CloseIcon, DeleteIcon, EditIcon } from '../../../../../shared/icons';

type DashboardHeaderType = {
  title: string;
  idGallery: string;
  handleCloseDashboard: () => void;
};

export const DashboardHeader: React.FC<DashboardHeaderType> = ({
  title,
  idGallery,
  handleCloseDashboard,
}) => {
  const { t } = useTranslation('gallery');
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDelete = (id: string) => {
    dispatch(fetchDeleteGallery(id));
  };

  const { setIsOpen: setIsOpenDialogWindow, dialogWindow } = useDialog<string>(
    idGallery,
    handleDelete,
    t('deleteGallery.question'),
    t('deleteGallery.title'),
  );

  const handleEdit = () => {
    history.push(`${urls.newGalleryUrl}/${idGallery}`);
  };

  return (
    <>
      <DashboardHeaderWrapper>
        <CloseButton type="button" onClick={handleCloseDashboard}>
          <CloseIcon />
        </CloseButton>
        <TitleBlack>{title}</TitleBlack>
        <div>
          <button type="button" onClick={handleEdit}>
            <EditIcon />
          </button>
          <button type="button" onClick={() => setIsOpenDialogWindow(true)}>
            <DeleteIcon />
          </button>
        </div>
      </DashboardHeaderWrapper>
      {dialogWindow}
    </>
  );
};
