import React, { forwardRef, MouseEventHandler } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { today } from '../../../../constants';
import { CalendarIcon } from '../../icons';
import { SetFieldValue } from '../../types';
import { CommonInputWrapper, ErrorMessage, TextLabel } from '../styled';
import { CalendarContainer, renderDayContents } from './CustomDatePickerParts';
import { DatePickerHeader } from './DatePickerHeader';
import { DatePickerCustom } from './styled';

type CustomDatePickerProps = {
  error?: string | any;
  data: string | any;
  name: string;
  touched?: boolean | any;
  label?: string;
  setFieldValue: SetFieldValue;
  placeholder?: string;
  limits?: boolean;
};

type ForwardRefType = {
  value: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  data,
  error,
  name,
  setFieldValue,
  touched,
  label,
  placeholder,
  limits = true,
}) => {
  const CustomInputDate = forwardRef(
    ({ value, onClick }: ForwardRefType, ref: any) => (
      <DatePickerCustom
        withPlaceholder={!!placeholder}
        className="example-custom-input"
        ref={ref}
        onClick={onClick}
        theme={error && touched ? 'error' : 'success'}
      >
        {value || placeholder}
        <CalendarIcon />
      </DatePickerCustom>
    ),
  );
  return (
    <CommonInputWrapper withSvg>
      {label && <TextLabel htmlFor={name}>{label}</TextLabel>}
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selected={data}
        onChange={(val) => setFieldValue(name, val)}
        calendarContainer={CalendarContainer}
        renderDayContents={renderDayContents}
        name={name}
        minDate={limits ? today : null}
        customInput={React.createElement(CustomInputDate)}
        useWeekdaysShort={false}
        calendarClassName="calendar"
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <DatePickerHeader
            date={date}
            changeYear={changeYear}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
      />
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
    </CommonInputWrapper>
  );
};
