import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { UserStateContext } from '../../../context/UserStateContext';
import * as urls from '../../../constants/index';

type PrivateRouteProps = {
  path: string;
  exact?: boolean | undefined;
  component: React.FC;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  exact,
  component: RouteComponent,
}) => {
  const location = useLocation();
  const { isSignIn } = useContext(UserStateContext);
  if (!isSignIn) {
    return <Redirect from={path} to={urls.signUpInitialUrl} />;
  }
  if (
    isSignIn
    && (location.pathname.includes(urls.signUpUrl)
      || location.pathname.includes(urls.signInUrl))
  ) {
    return <Redirect from={path} to={urls.rootUrl} />;
  }
  return (
    <Route exact={exact} path={path}>
      <RouteComponent />
    </Route>
  );
};
