import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSelectionItems } from '../../shared/hooks/useGetSelectionItems';
import { Occupation } from '../../shared/types';

type SelectOccupation = {
  id: Occupation;
  value: string;
};

export const useGetWhatDoYouDoMenuItems = (): ReactElement[] => {
  const { t } = useTranslation('signUp');
  const getSelectionItems = useGetSelectionItems();

  const items: SelectOccupation[] = [
    { id: 'photographer', value: t('whatDoYouDo.photographer') },
    { id: 'videographer', value: t('whatDoYouDo.videographer') },
    { id: 'editor', value: t('whatDoYouDo.editor') },
    { id: 'influencer', value: t('whatDoYouDo.influencer') },
    { id: 'marketing', value: t('whatDoYouDo.marketing') },
    { id: 'designer', value: t('whatDoYouDo.designer') },
    { id: 'other', value: t('whatDoYouDo.other') },
  ];

  return getSelectionItems(items);
};
