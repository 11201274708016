import { css, Global } from '@emotion/react';
import { Container, Grid, ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { UserStateContext } from '../context/UserStateContext';
import { Routes } from './App/routes/Routes';
import { MainLogo } from './shared/icons/MainLogo';
import {
  GlobalStyle,
  GridFullHeight,
  GridItemFullHeight,
  Header,
  theme,
} from './styled';
import { Sidebar } from './CreatorAccount/Sidebar/Sidebar';
import { useTypedSelector } from './shared/hooks/useTypedSelector';
import { NewsPopupWindow } from './CreatorAccount/NewsPopupWindow/NewsPopupWindow';
import { fetchCurrentUserCreator } from '../Redux/userCreator/signIn/signInCreatorThunk';
import { WalkThroughCards } from './CreatorAccount/WalkThroughCards/WalkThroughCards';
import { OnboardingContext } from '../context/OnboardingContext';
import { OnboardingStepIndexContext } from '../context/OnboardingStepIndexContext';

export const MainContainer: React.FC = () => {
  const dispatch = useDispatch();

  const currentUserIsSignIn = useTypedSelector(
    (state) => state.signInCreator.isSignIn,
  );
  const isSignInAfterSigningUp = useTypedSelector(
    (state) => state.signUpCreator.isSignUp,
  );
  const currentUser = useTypedSelector(
    (state) => state.signInCreator.currentUser,
  );

  const isLoading = useTypedSelector((state) => state.signInCreator.loading);

  const [isSignIn, setIsSignIn] = useState<boolean>(currentUserIsSignIn);
  const [isStudy, setIsStudy] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    setIsSignIn(currentUserIsSignIn);
    dispatch(fetchCurrentUserCreator());
  }, [currentUserIsSignIn, isSignInAfterSigningUp]);

  const renderContent = isLoading || currentUserIsSignIn !== isSignIn ? <span>...Loading</span> : (
    <Container maxWidth="xl">
      {!isSignIn ? (
        <>
          <Header>
            <MainLogo large={!isSignIn} />
          </Header>
          <Routes />
        </>
      ) : (
        <>
          <GridFullHeight container spacing={4}>
            <GridItemFullHeight item sm={3}>
              <Sidebar />
            </GridItemFullHeight>
            <Grid item sm={9}>
              <Grid container spacing={4} justifyContent="flex-end">
                <Grid item sm={11}>
                  <Routes />
                </Grid>
              </Grid>
            </Grid>
          </GridFullHeight>
          {currentUser && <NewsPopupWindow id={currentUser?.id} />}
          <WalkThroughCards />
        </>
      )}
    </Container>
  );
  return (
    <UserStateContext.Provider value={{ isSignIn, setIsSignIn }}>
      <OnboardingContext.Provider value={{ isStudy, setIsStudy }}>
        <OnboardingStepIndexContext.Provider value={{ stepIndex, setStepIndex }}>
          <ThemeProvider theme={theme}>
            <Global
              styles={css`
                ${GlobalStyle}
              `}
            />
            {renderContent}
          </ThemeProvider>
        </OnboardingStepIndexContext.Provider>
      </OnboardingContext.Provider>
    </UserStateContext.Provider>
  );
};
