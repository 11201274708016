import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';

export const HelpTipButton = styled.button<{ position: 'top' | 'left' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  & > span {
    display: none;
    ${(props) => (props.position === 'top'
    ? 'bottom: 120%; left: -400%;'
    : 'top: -5px;')}

    position: absolute;
    text-align: ${(props) => (props.position === 'top' ? 'center' : 'start')};
    width: 300px;
    max-width: 300px;
    font-size: 12px;
    line-height: 15px;
    white-space: pre-wrap;
    color: ${variables.colorBleak};
  }

  &::after {
    content: '';
    width: 50px;
    height: 50px;
    margin: -50px;
    position: absolute;
  }

  &:hover {
    & > span {
      display: block;
    }
  }
`;
