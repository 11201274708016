import { useFormikContext } from 'formik';
import React from 'react';
import { GalleryData } from '../../shared/types';
import { SectionItem } from './SectionItem';

export const SectionsList: React.FC = () => {
  const { values } = useFormikContext<GalleryData>();

  return (
    <>
      {values.sections.map((item) => (
        <SectionItem section={item} key={item.id} />
      ))}
    </>
  );
};
