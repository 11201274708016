import React, {
  MutableRefObject, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { VideoElement, VideoWrapper } from './styled';

type VideoContainerType = {
  videoUrl: string;
  autoPlay?: boolean;
  widthStretch?: boolean;
  setSpans?: (ref: MutableRefObject<any>) => void;
};

export const VideoContainer: React.FC<VideoContainerType> = ({
  videoUrl,
  autoPlay,
  widthStretch,
  setSpans,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isWidthBigger, setIsWidthBigger] = useState(false);

  const handleOnLoad = (e: SyntheticEvent<HTMLVideoElement>) => {
    setIsWidthBigger(e.currentTarget.videoHeight < e.currentTarget.videoWidth);
    if (setSpans) {
      setSpans(videoRef);
    }
  };

  useEffect(() => {
    if (setSpans) {
      setSpans(videoRef);
    }
  }, [widthStretch]);

  return (
    <>
      {videoUrl && (
        <VideoElement
          // autoPlay={autoPlay}
          loop
          muted
          id={videoUrl}
          preload="auto"
          ref={videoRef}
          onLoadedData={handleOnLoad}
          widthStretch={!!widthStretch}
          isWidthBigger={isWidthBigger}
        >
          <source src={videoUrl} type="video/mkv" />
          <source src={videoUrl} type="video/ogg" />
          <source src={videoUrl} type="video/avi" />
          <source src={videoUrl} type="video/mp4" />
        </VideoElement>
      )}
    </>
  );
};
