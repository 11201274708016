import styled from '@emotion/styled';
import * as variables from '../../../../constants/styleVariables';
import { BaseColors } from '../../types';

export const CloseWindowButton = styled.button<{color: BaseColors}>`
  position: absolute;
  top: 18px;
  right: 18px;

  & svg {
    color: ${(props) => {
    if (props.color === 'pink') return variables.colorPink;
    if (props.color === 'black') return variables.textBlack;
    if (props.color === 'grey') return variables.textGrey;
    if (props.color === 'white') return 'white';
    return variables.textBlack;
  }
};
  }
`;
