import React, { ReactNode } from 'react';
import { DocumentIcon } from '../icons';
import { TextCard } from './styled';

type BasicDocumentCardType = {
  file: string;
  children?: ReactNode;
  isSmall?: boolean;
};

export const BasicDocumentCard: React.FC<BasicDocumentCardType> = ({
  file,
  children,
  isSmall,
}) => (
  <>
    <TextCard inheritSize={!!isSmall} withChildren={!!children}>
      <DocumentIcon />
      <span>{file}</span>
      {children}
    </TextCard>
  </>
);
